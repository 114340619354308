export default {
  AccountCreditEarned: {
    text: "{amount}のクレジットがお客様のアカウントに適用されます。次回の@:Brand.webroot{''}または@:Brand.carbonite{''}製品のご購入時にご利用いただけます。",
  },
  AccountLockedOutNotification: {
    AccountLockedOutLink: "パスワードをお忘れの場合",
  },
  AccountSecurityComponent: {
    accountSecurity: "アカウントのセキュリティ",
    descriptionHeading: "推奨とオプション: ",
    description:
      "アカウントに2要素認証を追加すると、ログインするたびに、ご利用のスマートフォンに送信されたコードの入力が求められるため、アカウントにアクセスする際の保護が一層強化されます。",
    btnSetup2fa: "2要素認証を設定する",
    questionsHeading: "セキュリティの質問。",
    questions: "アカウントの設定を完了するため、以下のセキュリティの質問にお答えください。",
    labelQ1select: "質問1",
    labelQ2select: "質問2",
    labelQ3select: "質問3",
    answer: "回答",
    btnSave: "保存して続行",
    btnSkip: "今はスキップする",
    heading2fa: "2要素認証(推奨)",
    description2fa1:
      "アカウントに2要素認証を追加すると、ログインするたびに、ご利用のスマートフォンに送信されたコードの入力が求められるため、アカウントにアクセスする際の保護が一層強化されます。",
    description2fa2:
      "これらの設定は、[個人情報]タブでいつでも変更または更新できます。2要素認証の詳細については、{here}を参照してください。",
  },
  AccountSetup: {
    headerStep0: "セキュリティの質問",
    headerStep1: "2要素認証(推奨)",
    headerStep2: "2要素認証",
    headerStep3: "セキュリティコードの確認",
    headerLanguagePreference: "メール言語設定",
    footerButtonLanguagePreference: "次へ",
    stepLanguagePreferenceText:
      "ウェブルートとCarboniteからアカウントの状態、ニュース、プロモーションに関して電子メールでご連絡します。その際のメールの言語設定を選択してください。",
    step0Text: "アカウントの設定を完了するため、以下のセキュリティの質問にお答えください。",
    step1TextA:
      "2要素認証を追加すると、アカウントにアクセスする際のセキュリティが強化されます。一意のコードがユーザーのスマートフォンに送信され、ユーザーがログイン時にそれを入力することで認証が成立します。",
    step1TextB:
      "これらの設定は、[個人情報]タブでいつでも変更または更新できます。2要素認証の詳細については、{here}を参照してください。",
    footerButtonStep0: "保存して続行",
    footerButtonStep1: "2要素認証を設定する",
    footerButtonStep1Skip: "今はスキップする",
  },
  AccountSummaryComponent: {
    accountSummary: "アカウントの概要",
    apply: "適用",
    trialExpired: "体験版が期限切れになりました。",
    buyNow: "今すぐ購入",
    activationCodeP: "ライセンスキーコードをお持ちの場合は、以下に入力してサブスクリプションを有効化してください。",
    activationCode: "ライセンスキーコード",
  },
  AddCreditCardComponent: {
    cancel: "キャンセル",
    next: "次へ",
  },
  AddEncryptionKeyComponent: {
    headerH1: "暗号化キーの入力",
    p1: "コンピューターに@:Brand.carboniteSafe{''}をインストールする際、ご自身の暗号化キーを管理することを選択しました。このコンピューターに@:Brand.carboniteSafe{''}をインストールするには、ここでキーをアップロードしてください。",
    selectEncryptionFile: "暗号化キーファイルの選択",
    selectEncryptionFileHint:
      "[{browse}]ボタンをクリックして(通常、Carbonite-Encryption-Key.pemという名前の)暗号化キーファイルを探し、選択してください",
    encryptionPassword: "暗号化キーのパスワード(セットアップ中に追加した場合のみ)",
    passwordHint: "パスワードのヒント: <strong>{hint}</strong>",
    note: "注意: 暗号化キーをアップロードした場合、そのキーは以後、@:Brand.carbonite{''}によって管理されます。",
    browse: "参照...",
    footerButtonCancel: "キャンセル",
    footerButtonSave: "保存",
    HintNotSet: "ヒントは設定されていません。",
  },
  AddKeycodeComponent: {
    headerH1: "キーコードを追加する",
    keycodeLabel: "まずキーコードを入力してください",
    lineItem1: "キーコードを確認するには: ",
    lineItem2Heading: "オンラインでご購入の場合: ",
    lineItem2:
      " 電子メールに@:Brand.webroot{''}または@:Brand.carbonite{''}からの指示が記載されているかどうか確認します。",
    lineItem3Heading: "小売店でご購入の場合: ",
    lineItem3: "購入製品のボックスまたはカードを確認してください。",
    footerButtonAdd: "追加",
    footerButtonLogout: "ログアウト",
    footerButtonCancel: "キャンセル",
    linkFooterNeedHelp: "ヘルプが必要な場合",
    nextSteps: "次のステップ: ",
    stepOne: "このアカウントからログアウトし、別の電子メールアドレスを使用して{createNewAccount}。",
    stepTwo: "新しい@:Brand.WSAP{''}のキーコードを入力し、新しいアカウントに割り当てます。",
    createNewAccount: "新規アカウントを作成します",
  },
  AddPaymentMethodComponent: {
    secureCheckout: "セキュアチェックアウト",
    next: "次へ",
    allStateAvailabilityMessage: "注意: Allstate Identity Protectionは米国でのみ利用可能です。",
  },
  AddVatNumberComponent: {
    headerH1: "VAT ID番号を追加する",
    headerBuyflow: "VAT ID番号を追加する",
    vatId: "VAT ID番号",
    countryPlaceholder: "--国を選択してください--",
    country: "国",
    ok: "OK",
    add: "追加",
    cancel: "キャンセル",
    invalidVatIdFormat: "無効なVAT ID形式" /* this string is properly translated */,
  },
  Adjustments: {
    BonusMonthsPromo: "ボーナス {months}ヵ月無料",
    TrialDaysRemaining: "残り{replacementText} 日のトライアル期間",
    PromoCodeAmt: "プロモーション割引",
    PromoCodePct: "Promo Code 割引" /* this string is properly translated */,
    PriorSubscriptionCredit: "以前のサブスクリプションクレジット",
    UsedCreditBalance: "アカウントクレジット",
    OneTimeAmt: "初回購入割引",
    Tax: "消費税",
    Discounts: "割引",
    LicenseDaysRemaining: "現在のサブスクリプションから{replacementText}日が含まれます",
  },
  AllstateAlreadySignedUpComponent: {
    h1Heading: "@:Brand.allstate{''} @:Brand.identityProtection{''}",
    summaryText:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} ダッシュボードに移動して機能のアクティブ化や、個人情報の追加および監視、アカウントの警告の管理ができます。",
    identityProtection: "@:Brand.identityProtection{''}",
    goToIDP: "@:Brand.idProtection{''}のアカウントに行く",
    subIncludesIdentityProtection:
      "あなたの定期購読には@:Brand.identityProtection{''}が含まれています。 当社のプレミアムプランには、最大で10人の家族メンバーに対する、最大100万ドルの盗難および詐欺の補償が含まれます。",
    subtitle: "次の手順: @:Brand.idProtection{''}機能のアクティブ化",
    upgradeText: "@:Brand.idProtection{''}を拡張し、$500k 費用およびクレジットモニタリング に拡張しますか？",
    viewUpgradeOptions: "@:Brand.idProtection{''}アカウント",
  },
  AllstateCanSignUpComponent: {
    h1Heading: "@:Brand.allstate{''} @:Brand.identityProtection{''}",
    summaryText:
      "この{subscription}アカウントには、@:Brand.allstate{''}が管理する@:Brand.identityProtection{''}が含まれています。",
    identityProtection: "@:Brand.identityProtection{''}",
    managedId: "@:Brand.idProtection{''}の設定",
    inactiveDisclaimer:
      "@:Brand.idProtection{''}の設定は、@:Brand.webrootProtection{''}のインストールから24時間以内に準備されます。",
    pleaseChoose: "@:Brand.identityProtection{':'}で使用するサブスクリプションを選択してください。",
    expires: "有効期限 ",
    keycode: "キーコード: ",
  },
  AllstateFormErrors: {
    firstNameInvalidCharacter: "{0}文字は使用できません。",
    lastNameInvalidCharacter: "{0}文字は使用できません。",
    addressInvalidCharacter: "{0}文字は使用できません。",
    cityInvalidCharacter: "{0}文字は使用できません。",
    nameLengthMin: "名は入力必須です",
    invalidPhoneFormat: "これは米国の有効な電話番号ではありません。",
    phoneLength: "これは米国の有効な電話番号ではありません。",
    addressLength: "住所は、3文字以上60文字以下で入力してください",
    cityLength: "市区町村は、2文字以上50文字以下で入力してください",
    stateLength: "米国の州は入力必須です。",
    zipLength: "郵便番号は5桁とする必要があります。",
    zipNumbersOnly: "郵便番号は5桁の数字とする必要があります。",
    firstNameLengthMax: "名は50文字未満とする必要があります。",
    lastNameLengthMax: "姓は50文字未満とする必要があります。",
    firstNameLengthMin: "名は入力必須です。",
    lastNameLengthMin: "姓は入力必須です。",
  },
  AllstateNotPurchasedComponent: {
    h1Heading: "@:Brand.webroot{''}セキュリティと@:Brand.identityProtection{''}",
    notification: "表示するアクティブな@:Brand.identityProtection{''}サブスクリプションはありません。",
    action: "@:Brand.webroot{''}セキュリティと@:Brand.identityProtection{''}を今すぐ追加。",
    summaryText:
      "受賞歴のある@:Brand.webroot{''}アンチウイルスに、@:Brand.allstate{''}@:Brand.identityProtection{''}が加わりました。下記のボタンをクリックして、デバイス、ID、プライバシーの保護を開始する方法を今すぐご確認ください。",
    summaryTextFamily:
      "@:Brand.webroot{''}のウィルス対策ソフトは@:Brand.allstate{''} @:Brand.identityProtection{'.'}を含むプランもリリースしました(米国のみ)。デバイス、個人情報とプライバシーを保護する方法について確認しましょう。",
    learnMore: "詳細",
    notes:
      "小売店や同様の購入手段でキーコードを入手した場合、{downloads}ページにそのキーコードを入力することでサブスクリプションを追加できます。",
    downloads: "ダウンロード",
  },
  AllStateOverviewComponent: {
    allStateOverview: "@:Brand.identityProtection{''}",
    zeroStateP1:
      "@:Brand.identityProtection{''}は個人情報への脅威をプロアクティブな警告および米国の24/7サポートエキスパートにより、個人情報を保護します。(米国のみ販売)",
    zeroStateBestBuyTTSB: "個人用の1人分か、家族全員用(10人分)のプランを選択してください。",
    zeroStateP2: "一部の機能は米国でのみ提供されます。",
    compareOptionsBtn: "詳細と価格を確認",
    premiumCreateAccountP1:
      "この@:Brand.WSAP{''}サブスクリプションには、@:Brand.allstate{''}が管理する@:Brand.identityProtection{''}が含まれています。",
    createAccountBtn: "@:Brand.idProtection{''} アカウントの作成",
    manageAccountP1:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} ダッシュボードに移動してアカウントの機能や警告をセットアップおよび管理できます。(米国でのみ利用可能).",
    premiumManageAccountP1:
      "この@:Brand.WSAP{''}サブスクリプションには、@:Brand.allstate{''}が管理する@:Brand.identityProtection{''}が含まれています。",
    basicManageAccountP1:
      "この@:Brand.wsaISP{''}プランには、@:Brand.allstate{''}が管理する@:Brand.identityProtection{''}が含まれています。",
    manageAccountBtn: "@:Brand.idProtection{''}の管理",
    basicCreateAccountP1: "プランには、SSN追跡とダークWeb監視が含まれます。",
    premiumExpired:
      "お客様の@:Brand.allstate{''} @:Brand.identityProtection{''}のサブスクリプションは有効期限が切れている@:Brand.WebrootPremium プランの一部です。",
    addOnExpired: "お客様の@:Brand.allstate{''} @:Brand.identityProtection{''} プランの有効期限が切れています。",
    inactiveDisclaimer:
      "@:Brand.idProtection{''}の設定は、@:Brand.webrootProtection{''}のインストールから24時間以内に準備されます。",
    attentionOnCreate:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} サービスをアクティブ化してアクセスするためにアカウントを作成してください。",
    goToIDPBtn: "@:Brand.idProtection{''}のアカウントに行く",
  },
  AllstateSignUpFormComponent: {
    h1Heading: "手順1: @:Brand.allstate{''} @:Brand.identityProtection{''} アカウントを作成する",
    summaryText:
      "@:Brand.allstate{'’s'}@:Brand.identityProtection{''}のサイトとサービスにアクセスするためのアカウントを作成します。提供された電子メールは、@:Brand.identityProtection{''}を管理するために使用されます。",
    email: "電子メール",
    firstName: "名",
    lastName: "姓",
    phone: "米国電話番号",
    address1: "米国番地",
    address2: "アパート、スイート、建物番号(省略可)",
    city: "市区町村",
    state: "州",
    zip: "郵便番号",
    country: "国",
    privacyAgreement:
      "このデータを@:Brand.allstate{','}に送信することに同意します。このデータは、@:Brand.allstate{''}ダッシュボードの@:Brand.identityProtection{''}アカウントを作成する際に使用されます。すべての個人情報は、@:Brand.allstate{''}のプライバシーポリシーの対象となります。",
    privacyPolicyLink: "@:Brand.allstate{''}プライバシーポリシー",
    createAllstate: "@:Brand.idProtection{''}アカウントの作成",
    dateExample: "例: MM/DD/YYYY",
    us: "米国",
    selectOption: "オプションを選択してください",
  },
  AllstateSupportSectionComponent: {
    allstateIdentityProtection: "@:Brand.allstate{''}@:Brand.identityProtection{''}",
    findHelpText: "@:Brand.allstateIdentityProtection{''}のヘルプを参照する",
    goAllstateFAQText: "@:Brand.allstate{''}に関するよくあるご質問",
    contactAllState: "@:Brand.allstate{''}へのお問い合わせ",
  },
  AllstateUpgradeComponent: {
    /* existing pre-family plan comparison table */
    notes: "一部の機能は米国でのみ提供されます。",
    buy: "@:Brand.identityProtection{''}の購入",
    featureComparison: "機能の比較",
    premiumOption: "@:Brand.allstate{''} @:Brand.idProtection{''}を含む",
    addOnOption: "既存の@:Brand.webroot{''} アカウントを追加",
    financialMonitoring: "財務の監視",
    advancedDarkWebMonitoring: "高度なダークWeb監視",
    identityMonitoring: "個人情報の監視",
    lostWalletProtection: "紛失したウォレットの保護",
    reimbursement: "償還",
    expenseReimbursementWebrootPremium: "$50万",
    expenseReimbursementWebrootPremiumFullFeature:
      "@:AllstateUpgradeComponent.expenseReimbursementWebrootPremiumの費用償還",
    expenseReimbursementAddOn: "$2万5000",
    expenseReimbursementAddOnFullFeature: "@:AllstateUpgradeComponent.expenseReimbursementAddOnの費用償還",
    stolenFundsReimbursement: "$50万までの盗難資金の償還",
    oneBureauCreditMonitoring: "1局によるクレジット監視",
    allstateIdProtectionAddOn: "@:Brand.AllstateIdentityProtectionBasic{''}",
    /* new family plan included comparison table */
    expires: "間もなく期限切れ",
    expired: "期限切れ",
    chooseKeycodeLine1: "@:Brand.identityProtection{''}は、アカウントの{onlyOne}に適用されます。",
    onlyOne: "1つのサブスクリプションのみ",
    chooseKeycodeLine2:
      "@:Brand.identityProtection{''}に関するオプションを表示するには、サブスクリプションを選択してください",
    summaryText:
      "@:Brand.identityProtection{''}は、個人情報、共有データ、および大切な信頼関係を保護するのに役立ちます。(日本では提供しておりません)",
    summaryTextFamily:
      "@:Brand.identityProtection{''}はお客様の個人情報とアカウントを監視し、米国での24/7のリミディエーションエキスパートへのアクセスにより詐欺や情報盗難の検知が可能です。",
    summaryTextFamilyP2: "一個人の個人情報を保護するプランと、家族全体(10人までの個人)を保護するプランがあります。",
    h1Heading: "@:Brand.identityProtection{''}のアップグレード",
    btnDetailsAndPricing: "詳細と価格を表示",
    featuresComparison: "機能比較",
    devicesProtected: "# 台の保護されたデバイス",
    devicesProtectedVary: "@:Brand.webroot{''} のプランによって異なります。",
    identitiesProtected: "# のアイデンティティを保護",
    option1description: "@:Brand.idProtection{''}を現在の@:Brand.webroot{''}プランに追加(米国でのみ販売)",
    option2description:
      "@:Brand.webroot{''} デバイスセキュリティ + @:Brand.allstate{''} @:Brand.idProtection{''}(米国でのみ販売)",
    allstateAddOn: "@:Brand.AllstateIdentityProtectionBasic{''}",
    financialMonitor: "金融モニタリング",
    creditAndDebitCards: "クレジットカード、デビットカード",
    bankAccountTransactions: "銀行口座の取引",
    investmentAccounts: "401(k)投資口座",
    accountTakeoverMonitoring: "アカウント乗っ取り保護",
    darkWebMonitor: "高度ダークウェブモニタリング",
    humanSourcedIntelligence: "人間ベースの知能",
    ssnMonitoring: "社会保障番号の監視",
    idMonitoring: "IDの監視",
    creditDebitScan: "クレジット/デビットカードのスキャン",
    breachNotifications: "侵入の通知",
    identityMonitor: "アイデンティティモニタリング",
    healthStatus: "個人情報の健全性の状態",
    emergingThreatAndScamAlerts: "新たな脅威と詐欺の警告",
    restoration: "復元",
    customerCare: "24時間365日対応のサポート",
    remediationSupport: "フルサービスの修復サポート",
    lostWallet: "お財布紛失保護",
    expenseReimbursement: "経費償還",
    stolenFunds: "$50K 盗難償還",
    creditMonitor: "One Bureau クレジットモニタリング" /* this string is properly translated as is */,
    rapidAlertDetection: "迅速なアラート検出",
    autoOnCapability: "オートオン機能",
    transUnion: "TransUnion",
  },
  ApplyActivationCodeComponent: {
    headerH1: "@:Brand.carbonite{''}のアクティベーションコードを適用",
    footerButtonCancel: "キャンセル",
    footerButtonNext: "次へ",
    footerButtonApply: "適用",
    label1: "@:Brand.carbonite{''}のアクティベーションコードを入力してください",
    invalidAC: "アクティベーションコードが無効 - 一時テキスト",
    paragraph1: "このアクティベーションコードはどのように使用しますか?",
    label2: "既存のサブスクリプションに適用します",
    label3: "このコンピューターに適用してインストールします",
    footerNeedHelp: "ヘルプが必要な場合",
  },
  AutoLogoutComponent: {
    headerH1: "セッションタイムアウト",
    sessionExpiry: "セッションの有効期限が近づいています。ログアウトまで{timer}秒です。",
    ctnSession: "セッションを続行しますか?",
    yes: "はい",
    no: "いいえ",
  },
  AutoRenewOffSurveyComponent: {
    autoRenewOffSurveyH2: "自動更新を無効にする理由を教えてください。",
    reasonTurnOff: "その理由について最も当てはまる回答を選択してください。",
    r101: "更新する予定だが手動で行いたい。",
    r102: "更新する予定だがクレジットカードを変更したい。",
    r103: "別のベンダーに切り替えるので更新する予定はない。",
    r104: "@:Brand.carbonite{''}は高すぎるので更新する予定はない。",
    r105: "無料のバックアップを利用するか、自分でバックアップを行っているため、更新する予定はない。",
    r106: "その他(具体的にご記入ください): ",
  },
  AutoRenewOptionComponent: {
    AutoRenewTurnOnHeader: "自動更新を有効にする",
    AutoRenewTurnOffHeader: "自動更新を無効にする",
    AutoRenewOffSurveyComponentHeader: "アンケート",
    WebrootARTurnOnHeader: "自動更新を有効にする",
    WebrootARTurnOffHeader: "サブスクリプションの更新を管理する",
    footerButtonCancel: "自動更新無効化",
    footerButtonClose: "閉じる",
    footerButtonTurnOnAutoRenewal: "自動更新を有効にする",
    footerButtonTurnOffAutoRenewal: "自動更新を無効にする",
    footerKeepAutoRenewalOn: "自動更新を有効のままにします",
    skipSurvey: "アンケートを省略",
    submit: "送信",
  },
  AutoRenewTurnOffComponent: {
    autoRenewOffLine1: "このサブスクリプションの自動更新を無効にしますか?",
    subscription: "サブスクリプション: ",
    computer: "コンピュータ名: ",
    expires: "有効期限: ",
    notifyProtection:
      "サブスクリプションの期限切れが近づくと、お客様を保護するために通知が送信されます。ただしアカウントを手動で更新するのを忘れると、ランサムウェアやスパイウェアなどに対してデバイスが脆弱になります。自動更新を無効にしますか?",
    oneTimeDiscount:
      "特別割引: こちらのサブスクリプションで今回自動更新を有効のままにして、次回の更新で{renewalDiscount}％の割引が適用されます。",
  },
  AutoRenewTurnOnComponent: {
    autoRenewForLine1: "自動更新を有効にする対象: ",
    subscription: "サブスクリプション: ",
    computer: "コンピュータ名: ",
    expires: "期限切れ: ",
    willRenewOn: "自動更新日",
    paymentChoice: "次の画面でお支払い方法を選択ください。",
    creditCardInfo:
      "このサブスクリプションは、登録されているデフォルトのクレジットカードを使用して自動的に更新されます。",
    changePaymentLink: "支払い方法の変更",
  },
  BackupComponent: {
    addComputer: "新しいコンピューターを追加する",
    backupH1: "バックアップ",
    readyToInstall:
      "@:Brand.carboniteSafe{''}をインストールして、新しいコンピューターをバックアップする準備が整いました。",
    linkFooterNeedHelp: "詳しい手順を表示",
    downloadsH1: "ダウンロード",
    noneComputer: "@:Brand.carboniteSafeCloudBackup{''}を使用してセットアップされたコンピューターはまだありません。",
    noneCdlp: "どのコンピューターにもまだ @:Brand.WebrootTotalProtection{''} が設定されていません。",
    getStarted: "開始方法: ",
    stepOne: "{computer}でこのページにアクセスします。",
    stepTwo: "[{button}]をクリックしてインストールファイルをダウンロードして実行します。",
    stepThree: "画面の指示に従ってファイルをバックアップします。",
    computerBackup: "バックアップするコンピューター",
    downloadNow: "今すぐダウンロードする",
    installNow: "今すぐインストール",
    note: "インストール後、保護の対象として特に重要と思われるファイルとフォルダを@:Brand.carboniteSafe{''}ソフトウェアが識別します。それらをご自身で選択することもできます。ソフトウェアが実行されると、ファイルがクラウドに自動的かつ安全にコピーされます。",
    upgradeOrExtend: "バックアップをアップグレードまたは延長する",
    BackupAdditional: "バックアップ対象デバイスを追加する",
    youHaveUninstalledSubs:
      "1個以上の@:Brand.carboniteSafe{''}サブスクリプションをご利用になれます。バックアップするコンピューターを追加するには、次の手順に従います。",
    upgradeIntro:
      "現在ご利用中のいずれかのバックアップサブスクリプションをアップグレードするか、新たなデバイスをバックアップするためのサブスクリプションを別途追加します。",
    upgradeAdd: "アップグレード/追加",
    noteOnlyAvailableFor: "注意: @:Brand.carboniteSafe{''}は、WindowsおよびmacOSでのみご利用いただけます。",
    startATrial: "体験版を使ってみる",
    bestBuyOnly: "@:Brand.bestBuy{''}サブスクリプションにはバックアップが含まれません。",
  },
  BestBuySupportSectionComponent: {
    line1: "@:Brand.webrootProtection{''}プロテクションに関するヘルプはこちら。",
    line2a: "担当者とチャットする(英語のみ)",
    line2b: "または電話(1-888-BEST-BUY)で問い合わせる",
  },
  BillingInformationComponent: {
    billingInformation: "請求先情報",
    billingOwner: "課金の所有者",
    accountCredit: "アカウントのクレジット",
    accountCreditDetails:
      "ご利用のアカウントには{amount}のクレジットがあります。このクレジットは今後のお支払いにご利用いただけます。",
    billingHistory: "請求履歴",
    billingHistoryDetails: "このアカウントに請求履歴はありません。",
  },
  Brand: {
    /* In the Japanese language, there are no spaces between words.  If you use @Brand, you need to add {''} at the end
    to allow it to render without a space "@:Brand.carboniteSafe{''}のナレッジベースの検索"
    To add a possessive, {'’s'} - NOTE the apostrophe for the possessive is not the same as the standard keyboard apostrophe '
    To add a period, use {'.'}
    */
    // partial names used in other strings
    allstate: "Allstate",
    idProtection: "ID Protection",
    identityProtection: "Identity Protection",
    amazon: "Amazon",
    prime: "Prime",
    plus: "Plus",
    bestBuy: "Best Buy",
    lastPass: "LastPass",
    lastPassFullName: "LastPass® Password Manager",
    opentext: "Open Text",
    carbonite: "Carbonite",
    carboniteCustomerCare: "Carbonite Customer Care",
    courierRecovery: "Courier Recovery",
    courierRecoveryService: "Courier Recovery Service",
    myAccount: "My Account",
    remoteFileAccess: "Remote File Access",
    anytimeAnywhereAccess: "Anytime Anywhere Access",
    ServiceFee: "Service Fee",
    webroot: "Webroot",
    webrootSecureAnywhere: "Webroot SecureAnywhere",
    wsaAntivirus: "AntiVirus",
    wsaISP: "Internet Security Plus",
    webrootMobileLogin: "Webroot Mobile Login",
    webrootCloudBackup: "@:Brand.webroot{''} クラウドバックアップ",
    // Carbonite products not in our productList - products that are not sold on VD but may be mentioned on VD
    carboniteSafe: "Carbonite Safe",
    carboniteSafePro: "Carbonite Safe Pro",
    carboniteOffice: "Carbonite Office",
    carboniteSafeUltimate: "Carbonite Safe Ultimate",
    carboniteSafeLite: "Carbonite Safe Lite",
    carboniteSafePlus: "Carbonite Safe Plus",
    CarboniteHome: "Carbonite Home",
    CarboniteHomePlus: "Carbonite Home Plus",
    CarboniteHomePremier: "Carbonite Home Premier",
    carboniteMobile: "Carbonite Mobile",
    carboniteSafeCloudBackup: "Carbonite Safe Cloud Backup",
    PersonalLite: "Carbonite Safe Lite",
    // Webroot products - These need to keep the casing
    webrootProtection: "Webroot Protection", // this is not a product, it is a catch-all name that covers the family of webroot products
    WSAV: "@:Brand.WebrootAntiVirus{''}",
    WSAI: "@:Brand.WebrootPlus{''}",
    WSAC: "@:Brand.WebrootComplete{''}",
    WSAFMPK: "@:Brand.WebrootCompleteFamily{''}",
    WSCB: "@:Brand.WebrootChromebook{''}",
    WSAG: "@:Brand.WebrootGamer{''}",
    WIFI: "@:Brand.WebrootSecureVPN{''}",
    WSAP: "@:Brand.WebrootPremium{''}",
    WSAPF: "@:Brand.WebrootPremiumFamily{''}",
    WSID: "@:Brand.AllstateIdentityProtectionBasic{''}",
    WSIDP: "@:Brand.AllstateIdentityProtectionPremium{''}",
    WSIDPF: "@:Brand.AllstateIdentityProtectionPremiumFamily{''}",
    WTP: "@:Brand.WebrootTotalProtection{''}",
    WTPF: "@:Brand.WebrootTotalProtectionFamily{''}",
    WPCO: "@:Brand.WebrootPcOptimizer{''}",
    WSASB: "@:Brand.WebrootSmallBusinessAntiVirus{''}",
    WSAIA: "@:Brand.WebrootPlus{''} - AIP",
    WW: "@:Brand.WindowWasher{''}",
    ADP: "@:Brand.AndroidMobile{''}",
    webrootSecureAnywhereAntivirus: "@:Brand.WebrootAntiVirus{''}",
    webrootSecureAnywhereComplete: "@:Brand.WebrootComplete{''}",
    // Browser extensions for the Downloads page
    WebrootWTSBE: "Webroot Web Threat Shield Browser Extension",
    WebrootWTS: "Webroot Web Threat Shield",
    WebrootMS: "Webroot Mobile Security",
    // these keys are skus and the product names they are mapped to
    AllstateIdentityProtectionBasic: "Allstate Identity Protection Basic",
    AllstateIdentityProtectionPremium: "Allstate Identity Protection Premium",
    AllstateIdentityProtectionPremiumFamily: "Allstate Identity Protection Premium Family",
    WebrootAntiVirus: "Webroot AntiVirus",
    webrootAntivirus: "@:Brand.WebrootAntiVirus",
    WebrootPremium: "Webroot Premium",
    WebrootPremiumFamily: "Webroot Premium Family",
    WebrootComplete: "Webroot Internet Security Complete",
    WebrootCompleteFamily: "Webroot Internet Security Complete Family",
    WebrootChromebook: "Webroot Chromebook",
    WebrootGamer: "Webroot AntiVirus for PC Gamers",
    WebrootPlus: "Webroot Internet Security Plus",
    WebrootSecureVPN: "Webroot Secure VPN",
    WebrootTotalProtection: "Webroot Total Protection",
    WebrootTotalProtectionFamily: "Webroot Total Protection Family",
    WebrootPcOptimizer: "Webroot PC Optimizer",
    WebrootSoftwareInstallationCD: "Software CD",
    WebrootSmallBusinessAntiVirus: "Webroot Small Business AntiVirus",
    WindowWasher: "Window Washer",
    AndroidMobile: "Webroot Security & Antivirus Premier",
    PersonalBasic: "Carbonite Safe Basic",
    PersonalPlus: "Carbonite Safe Plus",
    PersonalPrime: "Carbonite Safe Prime",
    PayPal: "PayPal",
  },
  BuyAddCardComponent: {
    paymentInformation: "お支払い情報",
    cancel: "キャンセル",
    next: "次へ",
    reviewOrder: "ご注文内容確認",
    processingOrderMessage: "ページを更新したり、[戻る] ボタンをクリックしないでください。",
  },
  BuyAutomaticRenewDialogComponent: {
    header: "自動更新",
    p1Annual: "{expirationDate} の後にサブスクリプションを自動更新しますか？",
    p2Annual: "自動更新お支払いの1週間前にリマインダーのメールを送信します。",
    btnManageAutoRenew: "自動更新の管理",
    btnYes: "自動更新を有効化",
    btnNo: "自動更新を無効化",
  },
  BuyDisclaimersComponent: {
    payment: "お支払い",
    orderFor: "{userName}のご注文",
    priceWarning:
      "ウェブサイトに表示されている値段は消費税が含まれていません。ご購入時に地域に応じた消費税が適応されます。消費税や追加料金は州や適用される法律によって決まります。",
    autoRenewDisclaimer:
      "*購入を完了をクリックすることにより、アカウントをアクティブに保つために定期的なサブスクリプション料金が請求されることを認め、同意するものとします。これは、サブスクリプションをキャンセルするか、自動更新の設定を変更しない限り継続されます。",
    helpLinkDeactivatingAutoRenewal: "@:Brand.carboniteSafe{''}の自動更新の無効化",
    helpLinkDeactivatingAutoRenewalWebroot: "自動更新の無効化",
    byContinuing: "続行することにより、次のことに同意したことになります:",
    helpLinkTermsOfService: "@:Brand.carbonite{'’s'} Terms of Service" /* this string is properly translated as is */,
    helpLinkTermsOfServiceWebroot: "@:Brand.webroot{''} 利用規約",
    helpLinkPrivacyPolicy: "プライバシーポリシー",
  },
  BuyEmailComponent: {
    enterYourEmail: "メールアドレスを入力してください。(レシート、アカウント登録、製品アクティベーションのため)",
    alreadyAnAccount: "既にアカウントが存在しています",
    option1Heading: "プション1 -- これは私のアカウントです",
    option1Message:
      "こちらがお客様のアカウントである場合、ログインをすることにより、登録されているクレジットカードでスムーズな決済が可能です。またはログインせずにこちらのメールアドレスで進みます。",
    or: "- または -",
    option2Heading: "オプション2 -- 別のメールアドレスを入力する:",
    btnContinueWithoutLoggingIn: "ログインせずに続行",
    differentEmail: "別のメールアドレスを入力してください",
    btnContinue: "お支払いに進む",
    willSendEmails:
      "<strong>お支払いに進む</strong>をクリックすることにより、現時点では購入を完了できない場合に購入方法のヘルプに関するメールをご案内する場合がございます。",
    allstateLimitError:
      "@:Brand.allstate{''} @:Brand.identityProtection{''}は1メールアドレスにつき、 1ライセンスのみのご購入です。以下に別のメールアドレスを入力するか、買い物かごから@:Brand.allstate{''} @:Brand.identityProtection{''}を削除してください。",
    allstateAlreadyIncludedError:
      "{productSku}には@:Brand.allstate{''} @:Brand.identityProtection{''}が含まれており、1つの@:Brand.allstate{''}サブスクリプションは1メールアドレスのみ許可されています。 カートから{productSku}を削除してください。 @:Brand.allstate{''} @:Brand.identityProtection{''}をお持ちのお客様には、{webrootComplete}をお勧めします",
  },
  BuyOrTryCarboniteComponent: {
    statement1: "現在ご利用のバックアップサブスクリプションはありません。",
    statement2: "今すぐ@:Brand.carboniteSafe{''}のバックアップを追加",
    statement3:
      "@:Brand.carbonite{''}は、安全にファイルをバックアップするための最良の手段です。データの損失、盗難、ハードドライブの故障、物理的な破損など、重要なファイルやデータを保護する必要があるのなら、もう迷う必要はありません。",
    startTrialCTA: "無料体験版を使ってみる",
    buyNowCTA: "今すぐ購入",
  },
  BuyPaymentComponent: {
    payment: "お支払い",
    orderFor: "{userName}のご注文",
    priceWarning:
      "ウェブサイトに表示されている値段は消費税が含まれていません。ご購入時に地域に応じた消費税が適応されます。消費税や追加料金は州や適用される法律によって決まります。",
    autoRenewDisclaimer:
      "*セキュアチェックアウトをクリックすることにより、 アカウントをアクティブに保つために定期的なサブスクリプション料金が請求されることを認め、同意するものとします。これは、サブスクリプションをキャンセルするか、自動更新の設定を変更しない限り継続されます。自動更新の設定を変更する手順については、以下英語のナレッジ ベース記事を参照してください。:",
    helpLinkDeactivatingAutoRenewal:
      "Deactivating Automatic Renewal for @:Brand.carboniteSafe{''}" /* this string is properly translated as is */,
    byContinuing: "続行することにより、次のことに同意したことになります:",
    helpLinkTermsOfService: "@:Brand.carbonite{'’s'} Terms of Service" /* this string is properly translated as is */,
    helpLinkPrivacyPolicy: "プライバシーポリシー",
    enterEmail: "お支払いに進むためにメールアドレスを入力してください。",
    paymentTypeCC: "クレジットカード",
    paymentTypePP: "PayPal" /* this string is properly translated as is */,
  },
  BuyReceiptComponent: {
    h1Receipt: "レシート",
    print: "印刷",
    confirmationEmailNote: "ご注文の詳細を電子メールでも{email}に送信しました。",
    confirmationEmailNoteUnauthenticated: "ご注文の詳細のコピーをメールでも送信しました。",
    dateOfPurchase: "注文日:",
    subscriptionRenews: "サブスクリプションは以下に記載されている有効期限に更新されます",
    subscriptionEnds: "サブスクリプションは以下に記載されている有効期限に終了します",
    endingIn: "有効期限",
    colQty: "数",
    colSub: "プラン",
    colAmount: "値段",
    tax: "消費税",
    paid: "支払い済み",
    total: "合計",
    monthlySubscription: "月額",
    forComputer: "対象PC:",
    subscriptionExpiresOn: "サブスクリプションの有効期限は {date} です",
    paypalPayment: "@:Brand.PayPal{''} ({email})",
  },
  PayPalPaymentComponent: {
    allStateAvailabilityMessageLine1:
      "お支払い方法に米国以外の住所が指定されています。 @:Brand.allstate{''} @:Brand.identityProtection{''}は米国でのみ利用可能です。",
    allStateAvailabilityMessageLine2: "米国の住所が指定されているお支払い方法をご使用ください。",
  },
  BuySelectedCreditCardComponent: {
    endingIn: "カード番号末尾",
    exp: "有効期限",
    allStateAvailabilityMessageLine1: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine1",
    allStateAvailabilityMessageLine2: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine2",
  },
  BuySelectPaymentComponent: {
    paymentInformation: "お支払い情報",
    payWith: "お支払い方法",
    savedPaymentMethods: "保存されたお支払い方法",
    endingIn: "有効期限",
    exp: "有効期限",
    automaticRenewSubtitle: "自動更新で保護を継続できます。",
    automaticRenewP1: "有効期限の{expirationDate}に自動的にサブスクリプションを更新しますか？",
    automaticRenewP2: "自動更新お支払いの1週間前にリマインダーのメールを送信します。",
    acceptAutomaticRenew: "はい、{expirationDate}に自動的にサブスクリプションを更新することを許可します。",
    secureCheckout: "セキュアチェックアウト",
    cardName: "クレジットカード名義",
    cardNumber: "クレジットカード番号",
    cardExpiration: "有効期限",
    cardBillingAddress: "ご請求先住所",
    changePayment: "お支払い方法変更",
    addPaymentMethod: "+ 支払い方法を追加",
  },
  BundleItemsComponent: {
    addSoftwareCD: "次の項目にソフトウェアCDを追加",
  },
  CancelSubscriptionComponent: {
    modalTitle: "キャンセルしてもよろしいですか?",
    note1:
      "注意: このサブスクリプションは月間プランであり、現在は提供しておりません。キャンセルした場合、データのバックアップを継続または再開するには、新しい年間サブスクリプションの購入が必要となります。",
    subscription: "サブスクリプション: ",
    computerName: "コンピューター: ",
    footerButtonCancel: "サブスクリプションのキャンセル",
    footerButtonDoNotCancel: "キャンセルしない",
  },
  CarboniteSupportSectionComponent: {
    carboniteSafeOs: "@:Brand.carboniteSafe{''} Windows/macOS用",
    carboniteMobile: "@:Brand.carbonite{''}モバイル",
    findHelpCarbText: "@:Brand.carboniteSafe{''}のナレッジベースの検索",
    search: "検索",
    popularSearches: "人気の検索",
    carboniteSupportText: "@:Brand.carbonite{''}のカスタマーサポートにアクセス(英語のみ)",
    searchKnowledge: "ナレッジベースの記事を検索",
    carboniteCustomerSupport: "@:Brand.carbonite{''}のカスタマーサポート",
    reinstallCarbonite: "@:Brand.carbonite{''}の再インストール",
    renewSubscription: "サブスクリプションの更新",
    restoreFiles: "別のパソコンでのファイル復元",
  },
  CardComputerComponent: {
    edit: "編集",
    outOfDate: "期限切れ",
    lastBackedUp: "前回のバックアップ",
    files: "ファイル",
    computerOptions: "オプション",
    viewFiles: "ファイルの表示",
    getFilesBack: "ファイルの復元",
    this: "これ",
    subRenewsAutoOn: "自動的に更新",
    moveMySubscription: "サブスクリプションを移動",
    reinstallCarbonite: "@:Brand.carbonite{''}の再インストール",
    renewUpgrade: "更新/アップグレード",
    changeCarbonitePlan: "変更 @:Brand.carbonite{''}プラン",
    expires: "期限切れ",
    expiresOnExpirationMessage: "この{productName}バックアップ{trial}サブスクリプションは{expiration}に期限が切れます",
    validUntilExpirationMessage: "この{productName}バックアップ{trial}サブスクリプションは{expiration}まで有効です",
    evergreenExpirationMessage: "{productName} - キャンセルされるまでアクティブです",
    autoRenewExpirationMessage: "この{productName}バックアップサブスクリプションは、{expiration}に自動的に更新されます",
    expiredOnExpirationMessage:
      "この{productName}バックアップ{trial}サブスクリプションは{expiration}に期限が切れました",
    expired: "期限切れ",
    backupWillBeLost: "バックアップデータは{days}日後に失われます",
    backupDeleted: "バックアップは削除されました。",
    clickBuyNow: "このコンピューターの保護を再開するには、[{buyNow}]をクリックしてください。",
    days: "日",
    buyNow: "今すぐ購入",
    renewNow: "今すぐ更新",
    copy: "コピー",
    keycode: "キーコード: ",
    wsavIncluded: "{productName}サブスクリプションに含まれるアンチウイルス保護",
    getTheApp: "アプリを入手",
    trial: "体験版",
    followTheseSteps: "以下の手順に従います",
    refreshYourBackup: "バックアップを更新するには{steps}。",
    unityError:
      "このサブスクリプションの取得中に問題が発生しました。ページを更新するか、後ほど改めてお試しいただくか、サポートにお問い合わせください。",
    deviceStatusBackupOutOfDate: "バックアップ期限切れ",
    deviceStatusBackupOutOfDateMessage:
      "バックアップの期限が切れています。バックアップが更新されるまで、お手持ちのファイルの正確なコピーは@:Brand.carbonite{''}に反映されない可能性があります。",
    deviceStatusDisabled: "無効",
    deviceStatusDisabledMessage:
      "このコンピューターではバックアップが無効になっています。バックアップを再開するには、お使いのコンピューターで@:Brand.carbonite{''}を起動してバックアップを有効にしてください。",
    deviceStatusRecoverMode: "復元モード",
    deviceStatusRecoverModeMessage:
      "復元モードでは、ファイルの復元中、@:Brand.carbonite{''}によるバックアップファイルの追加、変更、削除が一切停止されます。システムへの大きな変更を検出すると、@:Brand.carbonite{''}は自動的に復元モードに移行します。新しいコンピューターにファイルを復元している場合などがそれに該当します。",
    deviceStatusStarting: "バックアップを開始しています...",
    deviceStatusStartingMessage: "",
    deviceStatusUninstalled: "アンインストール済み",
    deviceStatusUninstalledMessage: "",
    getMyKeycode: " キーコードを取得",
    resellerEmail: "{action}するには、{email}までご連絡ください",
    renew: "更新",
    buy: "購入",
    renewAutomatically: "このサブスクリプションは次の日付に自動的に更新されます: ",
    evergreenActiveUntilCanceled: "キャンセルされるまでアクティブです",
    purgedMessage:
      "@:Brand.carbonite{''}がインストールされていないことを検出しました。お使いのコンピューターに@:Brand.carbonite{''}をインストールするには{here}をクリックし、画面の指示に従って@:Brand.carbonite{''}をダウンロードしてインストールしてください",
    here: "こちら",
    changePlan: "プランを変更",
    on: " に",
    signUpToRenewAutomatically: "登録して自動的に更新",
    addVideo: "動画ファイルのバックアップを有効にする",
    recoveryDisk: "復元ディスク",
    addEncryptionKey: "暗号化キーをアップロード",
  },
  CardCreditComponent: {
    endsIn: "期限切れまであと",
    expires: "期限切れ",
    defaultCreditCard: "デフォルトのクレジットカード",
    makeDefaultLink: "これをデフォルトのクレジットカードにする",
  },
  CardUninstalledSubComponent: {
    installNow: "今すぐインストール",
    expires: "期限切れ",
    activeUntilCancelled: "キャンセルされるまでアクティブです",
  },
  CardWsacSafeLiteComponent: {
    installNow: "今すぐインストール",
    expires: "期限切れ",
    installWSACDisclaimer:
      "この@:Brand.carbonite{''}サブスクリプションを有効にするには、@:Brand.WebrootComplete{''}をインストールしてください。",
    goToDownloads: "ダウンロードに移動",
    notActivated: "まだアクティブ化されていません",
  },
  CartComponent: {
    subscription: "サブスクリプション",
    termDropDownYear: "年間サブスクリプション",
    termDropDownMonthly: "月額サブスクリプション",
    calcSalesTax: "消費税計算",
    total: "合計",
    promoCode: "Promo Code" /* this string should not be translated */,
    apply: "適用",
    protectionForComputer: "{quantity}台のコンピュータの保護",
    taxDisclaimer: "*消費税はお支払い情報を入力後に算出されます。",
    updating: "更新中...",
    supportBBYTitle: "@:Brand.bestBuy{''}サポートのお客様",
    itLooksLikeBBY: "@:Brand.bestBuy{''}でこのサブスクリプションを購入したようです。",
    renewYourSubscriptionBBY: "サブスクリプションを更新するには、",
  },
  CartItem: {
    perYear: "/年",
    qty: "量",
    quantity: "量",
    maxQuantityOfOne: "量: 1",
    appliesToRemainder: "現在の契約期間の残りに適用されます。",
  },
  CheckHasRecentPurchase: {
    header: "重複の購入ですか?",
    p1: "5分前以内に{computerName}のPCに対して更新またはアップグレードをご購入いただいています。",
    p2: "次のリンク{subscriptionsLinkText}を確認することにより、ご購入の状態を確認できます。",
    subscriptionsLinkText: "サブスクリプションページ" /* This goes into the p2 text for {subscriptionsLinkText} */,
    p3: "購入の重複をした場合、サブスクリプション期間に{termLength}年分が{computerName}に追加されます。",
    p4: "こちらのご購入を完了しますか？",
    yesBtn: "はい、購入を完了します",
    noBtn: "いいえ、購入をキャンセルします",
  },
  ChoosePlan: {
    choosePlan: "プランを選択",
    numberOfComputers: "コンピュータの台数:",
    additional: "追加",
    off: "オフ",
    downgradeWarningL1: "{toSku}へのプランの変更により, 以下の機能が除外されます:",
    backupExternalDrives: "外部ハードドライブのバックアップ*",
    automaticVideoBackup: "ビデオの自動バックアップ*",
    webrootAntivirus: "@:Brand.WebrootAntiVirus",
    courierRecoveryService: "@:Brand.courierRecoveryService{''}",
    courierRecoveryDisclaimer:
      "@:Brand.courierRecoveryService{''}については配送ごとのお支払いによるサービスとして引き続きご利用いただけます。",
    downgradeDisclaimer:
      "*注: これらの機能でバックアップされたデータは弊社バックアップサーバーから削除され、サービスから復元できなくなります。",
  },
  Common: {
    // when adding new strings to Common, please add them alphabetically, so it is easy to visually scan if a string is available in this block
    address1: "住所",
    address2: "住所 2 (オプション)",
    addToCart: "買い物かごに追加",
    backupTrial: "Backup trial" /* this string is properly translated as is */,
    basic: "Basic" /* this string is properly translated as is */,
    cancel: "キャンセル",
    cancelled: "キャンセルされました",
    cart: "買い物かご",
    change: "変更",
    city: "市区町村",
    close: "閉じる",
    confirmEmail: "メールアドレスの確認",
    contactSupport: "サポートまでお問い合わせください",
    copy: "コピー",
    country: "国",
    device: "デバイス",
    devices: "デバイス",
    discount: "割引",
    download: "ダウンロード",
    edit: "編集",
    email: "電子メールアドレス",
    extendYourProtection: "保護を延長する",
    family: "Family" /* this string is properly translated as is */,
    fName: "名",
    goToDownloads: "ダウンロードに移動",
    goToMyAccount: "マイアカウントに移動",
    hide: "非表示",
    identity: "個人",
    identities: "個人",
    individual: "Individual" /* this string is properly translated as is */,
    keycode: "キーコード",
    learnMore: "詳細",
    lName: "姓",
    loading: "ページを更新したり、[戻る] ボタンをクリックしないでください。",
    logIn: "ログイン",
    monthToMonth: "月額",
    next: "次へ",
    nextSteps: "Next Steps" /* h1NextSteps should not be translated */,
    of: "/",
    option1: "オプション 1:",
    option2: "オプション 2:",
    password: "パスワード",
    payment: "お支払い",
    plus: "Plus" /* this string is properly translated as is */,
    premium: "Premium" /* this string is properly translated as is */,
    prime: "Prime" /* this string is properly translated as is */,
    remove: "削除",
    renewNow: "今すぐ更新",
    reviewOrder: "ご注文確認",
    save: "保存",
    security: "セキュリティ",
    selectOne: "- 選択 -",
    selectOption: "オプションを選択してください",
    show: "表示",
    state: "州",
    submit: "送信",
    subscription: "サブスクリプション: ",
    success: "成功",
    viewDetails: "詳細表示",
    visitMyAccount: "@:Brand.myAccount{''} にアクセス",
    windows10: "Windows 10" /* should not be translated */,
    windows11: "Windows 11" /* should not be translated */,
    year: "年",
    yearCapital: "年",
    years: "年",
    zip: "郵便番号",
  },
  CommonErrors: {
    ActivationCodeInvalid: "ライセンスキーコードに含めることができるのは、ハイフンを含む文字と数字のみです。",
    keycodeInvalid: "キーコードは{length}文字(ハイフンを除く)とし、文字と数字のみが使用できます。",
    emailLengthGreaterThanMin: "電子メールアドレスは{minLength}文字以上でなければなりません。",
    emailLengthLessThanMax: "電子メールアドレスは{maxLength}文字未満でなければなりません。",
    emailMoreThanOneAt: "電子メールアドレスに含めることができる\"{'@'}\"記号は1つのみです。",
    emailFollowTLDPattern: "電子メールアドレスは次ぎのような形式である必要があります。 \"username{'@'}address.com\"。",
    lengthOutOfRange: "{fieldName}は{min}文字以上{max}文字以下で指定する必要があります",
    invalidCharacters: "{invalid}文字は使用できません",
    requiredFieldError: "必須項目が入力されていません",
  },
  ComputerNameComponent: {
    headerH1: "コンピューター名の変更",
    computerName: "コンピュータ名",
    save: "保存",
    cancel: "キャンセル",
  },
  ContactUsComponent: {
    smbSupport: "@:Brand.carbonite{''}法人向けサポート",
    technicalSupport: "テクニカルサポート(英語のみ)",
    salesSupport: "販売サポート",
    chat: "チャットする(英語のみ)",
    chatNow: "Chat now" /* should not be translated */,
    phone: "電話番号",
    callUs: "お電話でのお問い合わせ",
    email: "電子メール",
    emailUs: "電子メールでのお問い合わせ",
    techPhoneChatHoursWeekday: "月曜～金曜、午前9時～午後7時30分(米国東部標準時)",
    techPhoneChatHoursWeekend: "土曜～日曜、午前9時～午後5時(米国東部標準時)",
    techEmailDays: "月曜～日曜",
    salesPhone: "03-4588-6500",
    salesPhoneEmailHours: "月曜～金曜、午前8時30分～午後6時(米国東部標準時)",
    excludingHolidays: "(祝日を除く)",
  },
  CourierRecoveryComponent: {
    headerH1: "@:Brand.courierRecovery{''}",
    description:
      "@:Brand.carbonite{''}はお客様のファイルを暗号化されたハードドライブに転送し、ご指定の住所に配送します。セキュリティを確保するため、ファイルを復号化するための一意のパスコードを電子メールで送信します。",
    descriptionNote: "注意: @:Brand.courierRecovery{''}の配送先は、米国内に限定されます。私書箱へは配送できません。",
    step1H1: "ステップ1 - @:Brand.carboniteSafe{''}のアンインストール",
    step1Description:
      "可能であれば、@:Brand.carboniteSafe{''}をお使いのコンピューターからアンインストールして、バックアップが変更されないようにしてください。そうしておかないと、ファイルが完全に復元できなくなる損害が生じる可能性があります。",
    btnStep1UninstallMac:
      "@:Brand.carboniteSafe{''} for macOSのアンインストール" /* this string is properly translated as is */,
    btnStep1UninstallWindows:
      "@:Brand.carboniteSafe{''} for Windowsのアンインストール" /* this string is properly translated as is */,
    step1Note:
      "注意: ランサムウェアに感染した場合、@:Brand.courierRecovery{''}のリクエストが処理される前に、@:Brand.carboniteCustomerCare{''}によるバックアップの調整が必要です。",
    btnStep1Recovering: "暗号ウイルスやその他のランサムウェアからの復旧",
    step2H1: "ステップ2 - 復元コンピューターの選択",
    step2Note:
      "以下のリストにお使いのコンピューターが存在しない場合、ご自身で暗号化キーを管理することを選択した可能性があります。@:Brand.courierRecovery{''}ドライブをリクエストするには、暗号化キーをアップロードして、もう一度リクエストし直す必要があります。詳細については、次の記事を参照してください: ",
    btnStep2Note: "@:Brand.carboniteSafe{''}のプライベート暗号化キーの管理。",
    colComputer: "コンピューター",
    colUsage: "使用状況",
    colPlan: "プラン",
    next: "次へ",
    headerH1Continued: "@:Brand.courierRecovery{''}(続き)",
    btnPreviousStep: "前のステップ",
    step3Description: "お客様の連絡先、受取人の情報および住所を以下に入力してください。",
    step3H1: "ステップ3 - 連絡先情報",
    step3FName: "名",
    step3LName: "姓",
    step3Email: "電子メールアドレス",
    step3Phone: "電話番号",
    step4H1: "ステップ4 - 配送先情報",
    step4Note: "@:Brand.courierRecovery{''}の配送先は、米国内に限定されます。私書箱へは配送できません。",
    step4FName: "受取人の名",
    step4LName: "受取人の姓",
    step4Phone: "受取人の電話番号",
    step4Address: "番地・通り",
    step4Address2: "建物名・部屋番号(該当する場合)",
    step4City: "市区町村",
    step4State: "州",
    step4Zip: "郵便番号",
    orderSummaryDescription:
      "@:Brand.carbonite{''}はお客様のファイルを暗号化されたハードドライブに転送し、ご指定の住所に配送します。セキュリティを確保するため、ファイルを復号化するための一意のパスコードを電子メールで送信します。",
    step5H1: "注文の内容",
    step5ComputerBackup: "コンピューターのバックアップ: ",
    step5Contact: "連絡先: ",
    step5ShippingInformation: "配送先情報: ",
    step5ShippingStandardA: "通常配送(USPS)",
    step5ShippingStandardB: "配送までのおおよその日数: 1～3営業日",
    step5ShippingExpeditedA: "急送(FedEx)",
    step5ShippingExpeditedB: "配送までのおおよその日数: 翌営業日",
    step5ShippingError: "*配送方法を上記より選択してください。",
    CourierAddCard: "+ カード追加",
    paymentH1: "お支払い",
    total: "合計",
    step6H1: "お支払い",
    step6Note:
      "受領後21日以内に@:Brand.courierRecovery{''}ドライブが返却されない場合、いかなる場合も容量に応じて$130から$300、ドライブケーブルが未返却の場合は$10が課金されます。",
    certifications: "証明書",
    certificationsDescription:
      "私は@:Brand.carbonite{''}に対し、私が@:Brand.carbonite{''}サブスクリプションの課金の所有者であること、および私は@:Brand.carbonite{''}が私のバックアップにアクセスして復元を行い、上記の@:Brand.courierRecovery{''}を実行するために必要なすべての権利を所有していることを表明し、保証するものとします。私は、事前に同意した以下の@:Brand.carbonite{'’s'}利用規約に従うことに同意します: ",
    tos: "@:Brand.carbonite{''}の利用規約",
    permissionLabel:
      "私は上記の@:Brand.courierRecovery{''}の支払いのため、@:Brand.carbonite{''}が私のクレジットカードに請求することを許可します。",
    btnSecureCheckout: "セキュアチェックアウト",
    step7H1: "@:Brand.courierRecovery{''} - ケース送信済み",
    step7P1:
      "@:Brand.courierRecovery{''}のリクエストを送信いただきありがとうございました。間もなく弊社のデータリカバリーチームがお客様のリクエストを確認いたします。",
    step7P2: "ご注文が処理されましたら、領収書および配送に関する追跡情報を、電子メールにてお送りします。",
    step7P3a: "サポートが必要な場合は",
    step7P3b: "サポートページをご確認ください",
    step7P3c: '(検索ボックスで"courier"を検索してください)。',
    payWith: "お支払い方法:",
    Step5Note:
      "受領後21日以内に@:Brand.courierRecovery{''}ドライブが返却されない場合、いかなる場合も容量に応じて、$130から$300、ドライブケーブルが未返却の場合は$10が課金されます。",
    courierRecoveryFor: "@:Brand.courierRecovery{''} for",
    courierRecoveryPrice: "$99.99",
    StandardShippingPrice: "$9.99",
    ExpeditedShippingPrice: "$29.98",
    priceZero: "$0.00",
    caseNumberIs: "お客様のcase number:" /* this string is properly translated as is */,
    macNote: "どのタイプのmacOSコンピュータにファイルを復元しますか？",
    intelProcessor: "Intel プロセッサー",
    macProcessor: "Apple M1 または新しいプロセッサー",
    macProcessorUrlText: "私のコンピュータのプロセッサーの種類を確認する方法は？",
    errorHeader: "ケースの処理注にエラーが発生しました。",
    error1Line: "リクエストの処理中に問題が発生しました。",
    error2Line: "少し時間を置いて再度お試しください。それか、ヘルプが必要な場合や継続的に問題が発生している場合は",
    errorSupportLink: "customer support(英語のみ)にお問合せください" /* this string is properly translated as is */,
    summaryHeader:
      "ご注文を確認いただき、お支払い方法を選択します。ご注文の処理が完了したら弊社のデータリカバリーチームが注文を確認し、次のステップについてご連絡します。",
    courierRecoveryPriceIncluded: "$0.00",
    defaultCard: "(デフォルト)",
    cardEndingWith: "末尾 *",
    checkoutButton: "セキュアチェックアウト",
  },
  CourierRecoveryFormErrors: {
    firstNameInvalidCharacter: "{0}文字は使用できません。",
    lastNameInvalidCharacter: "{0}文字は使用できません。",
    emailFormat: "電子メールアドレスは、\"username{'@'}address.com\"形式になっている必要があります。",
    emailLengthMin: "電子メールアドレスは7文字以上でなければなりません。",
    emailLengthMax: "電子メールアドレスは128文字未満でなければなりません。",
    emailSymbol: "電子メールアドレスに含めることができる\"{'@'}\"記号は1つのみです。",
    addressInvalidCharacter: "{0}文字は使用できません。",
    cityInvalidCharacter: "{0}文字は使用できません。",
    nameLengthMin: "お名前は入力必須です",
    invalidPhoneFormat: "これは米国の有効な電話番号ではありません。",
    phoneLength: "これは米国の有効な電話番号ではありません。",
    addressLength: "住所は、3文字以上60文字以下で入力してください",
    cityLength: "市区町村は、2文字以上50文字以下で入力してください",
    stateSelect: "州を選択してください",
    stateLength: "米国の州は入力必須です。",
    zipLength: "郵便番号は5桁とする必要があります。",
    zipNumbersOnly: "郵便番号は5桁の数字とする必要があります。",
    firstNameLengthMax: "名は50文字未満とする必要があります。",
    lastNameLengthMax: "姓は50文字未満とする必要があります。",
    RecipientFirstNameLengthMax: "受取人の名は50文字未満とする必要があります。",
    RecipientLastNameLengthMax: "受取人の姓は50文字未満とする必要があります。",
    firstNameLengthMin: "名は入力必須です。",
    lastNameLengthMin: "姓は入力必須です。",
  },
  CreateAccount: {
    welcomeHeading: "マイアカウントにようこそ",
    welcomeMessage1:
      "高い評価を得ているセキュリティと保護の安心感をぜひご体感ください。@:Brand.webroot{''}アンチウイルスと@:Brand.carbonite{''}クラウドバックアップを1カ所で簡単にご利用いただけます。",
    welcomeMessage2: "アカウントを作成して、製品をダウンロード、インストール、管理するだけです。",
    createAccount: "アカウントの作成",
    alreadyHaveAnAccountMessage: "既にアカウントをお持ちの場合: ",
    redirectLogIn: "ログイン",
    confirmPassword: "パスワードの確認",
    createAccountError: "アカウントを作成できませんでした。インターネット接続を確認して、再度お試しください。",
    passwordRequirements: "パスワード要件: ",
    invalidPasswordCharacter: '"{char}"文字は使用できません。',
    passwordNotMatch: "パスワードが一致しません",
  },
  CreditCardsComponent: {
    cardsOnFile: "登録されているクレジットカード",
    addCardBtn: "新しいクレジットカードを追加",
    editCardBtn: "クレジットカードの編集が必要ですか?",
  },
  CrossPlatformTrialMoveSubscriptionComponent: {
    headerH1: "サブスクリプションを移動",
    trialCrossPlatformP1:
      "現在、@:Brand.carbonite{''}体験版を{agentOs}コンピューターから{browserOs}コンピューターに移動する機能は、提供しておりません。ただし、新しいコンピューターで別の体験版を開始することはできます。その場合は、ウィンドウを閉じて[{upgradeAdd}]ボタンをクリックしてください。",
    trialCrossPlatformP2: "その他のご質問については、カスタマーサポート({carboniteContactUrl})までご連絡ください",
    upgradeAdd: "アップグレード/追加",
    supportLink: "support.carbonite.com",
    ok: "OK",
  },
  CSSBCancelDownloadComponent: {
    headerH1: "ダウンロードをキャンセル",
    p1: "ダウンロードはまだ完了していません。処理をキャンセルしますか？",
    continueBtn: "ダウンロードを継続",
    closeBtn: "処理をキャンセル",
  },
  CssbDownloadWatcherComponent: {
    title: "ダウンロードの準備中",
    subtitle: "ダウンロード処理を完了するためにこのウィンドウは開いたままにしてください。",
    message:
      "{numberOfFiles}個のファイルをZIPファイルに圧縮しています。準備が完了したら自動的にダウンロードが開始します。",
  },
  DashboardTabsComponent: {
    computers: "コンピューター",
    servers: "サーバー",
    users: "ユーザー",
    groups: "グループ",
  },
  DeviceComponent: {
    protected: "保護",
    shieldsDisabled: "部分的に保護されています",
    protectionDisabled: "保護が無効",
    expired: "期限切れ",
    howToEnableBtn: "有効にする方法",
    managingShields: "シールドの管理",
    identityShield: "IDシールド: " /* this string is properly translated as is */,
    infraredShield: "Infraredシールド: " /* this string is properly translated as is */,
    offlineShield: "オフラインシールド: ",
    phishingShield: "フィッシングシールド: ",
    realtimeShield: "リアルタイムシールド: ",
    rootkitShield: "ルートキットシールド: ",
    scriptShield: "スクリプトシールド",
    usbShield: "USBシールド: " /* this string is properly translated as is */,
    webShield: "Webシールド: " /* this string is properly translated as is */,
    hideThisDevice: "このデバイスを非表示にする",
    showLessLink: "簡易表示",
    showMoreLink: "詳細表示",
    version: "バージョン: ",
    shieldOn: "オン",
    shieldOff: "オフ",
    removeDevice: "デバイスを削除",
    removeDeviceLine1: "JPN {nickname} will no longer be protected.",
    removeDeviceLine2: "JPN Confirm by clicking on the <strong>Remove Device</strong> button below.",
    removeDeviceReceivedLine1: "JPN Your request to remove {nickname} has been received.",
    removeDeviceReceivedLine2: "JPN To re-activate protection in the future, re-install the software on this device.",
  },
  DownloadProductComponent: {
    linkFooterNeedHelp: "詳しい手順を表示",
    AboutSecurityMobileLogin: "@:Brand.webrootMobileLogin{''}について",
    SAFEUnsupported:
      "Safe Cloud Backupは、WindowsおよびmacOSデバイスでのみご利用いただけます。" /* this string is properly translated as is */,
    SAFEStepOne: "必ずバックアップ対象のコンピューターをご使用ください。",
    SAFEStepTwo: "インストールファイルをダウンロードして実行します。",
    SAFEStepThree: "画面の指示に従って保護対象の重要なファイルを選択します。",
    SAFEStepThreeReinstall:
      "画面の指示に従って、新しいバックアップを作成するか、既存のバックアップからコンピューターにファイルを復元します。",
    SAFEMOBILEStepOne: "App Storeからアプリを入手",
    SAFEMOBILEStepTwo: "@:Brand.myAccount{''}のメールアドレスをパスワードでログイン",
    WSAPUnsupported: "@:Brand.webrootProtection{''}プロテクションは、WindowsおよびmacOSでのみご利用いただけます。",
    WSAPUnsupportedReference:
      "参考: お客様のキーコードは以下のとおりです。ソフトウェアのアクティブ化にご使用ください。",
    WSAPStepOne: "ダウンロードの対象: ",
    WSAPStepTwo: "プロンプトが表示されたら、必要なキーコードを入力します(以下のキーコードを利用できます)。",
    WWTSUnsupported:
      "@:Brand.webroot{''}Web脅威シールドは、Windows、macOS、Chrome OSデバイス上のブラウザでのみご利用いただけます",
    WWTSUnsupportedReference:
      "参考: お客様のキーコードは以下のとおりです。ソフトウェアのアクティブ化にご使用ください。",
    WWTSStepOne: "ブラウザの拡張機能を入手します。",
    WWTSStepTwo: "プロンプトが表示されたら、必要なキーコードを入力します(以下のキーコードを利用できます)。",
    WRMSUnsupported:
      "このダウンロードは、Android、iOS、Windows、macOS、Chrome OS、Linuxデバイスでのみご利用いただけます" /* this string is properly translated as is */,
    WRMSUnsupportedReference:
      "参考: お客様のキーコードは以下のとおりです。ソフトウェアのアクティブ化にご使用ください。",
    WRMSStepOne: "保護対象とするモバイルデバイスで、このページにアクセスしてください。{copyLink}",
    WRMSStepTwo: "アプリストアからアプリを入手します。",
    WRMSStepThree:
      "<strong>@:Brand.webrootMobileLogin{''}</strong>は、<strong>マイアカウント</strong>ポータルログインとは異なります。キーコードを使用して新しいアカウントを作成するか、すでにモバイルログインをお持ちの場合は、既存の認証情報を使用してください。",
    WSCBUnsupported:
      "このダウンロードは、Android、iOS、Windows、macOS、Chrome OS、Linuxデバイスでのみご利用いただけます" /* this string is properly translated as is */,
    WSCBUnsupportedReference:
      "参考: お客様のキーコードは以下のとおりです。ソフトウェアのアクティブ化にご使用ください。",
    WSCBStepOne: "保護対象とするモバイルデバイスで、このページにアクセスしてください。{copyLink}",
    WSCBStepTwo: "アプリストアからアプリを入手します。",
    WSCBStepThree:
      "<strong>@:Brand.webrootMobileLogin{''}</strong>は、<strong>マイアカウント</strong>ポータルログインとは異なります。キーコードを使用して新しいアカウントを作成するか、すでにモバイルログインをお持ちの場合は、既存の認証情報を使用してください。",
    WIFIUnsupported:
      "このダウンロードは、Android、iOS、Windows、macOS、Chrome OS、Linuxデバイスでのみご利用いただけます" /* this string is properly translated as is */,
    WIFIUnsupportedReference:
      "参考: お客様のキーコードは以下のとおりです。ソフトウェアのアクティブ化にご使用ください。",
    WIFIStepOne: "アプリストアからアプリを入手します。",
    WIFIStepTwo: "プロンプトが表示されたら、このアカウントで使用する電子メールアドレスとパスワードを入力します。",
    WIFIStepThree:
      "アプリを起動し、キーコードを使用して新しい<strong>@:Brand.webrootMobileLogin{''}</strong>を作成するか、既存の認証情報を使用してログインします。",
    LPPMUnsupported:
      "このダウンロードは、Android、iOS、Windows、macOS、Chrome OS、Linuxデバイスでのみご利用いただけます" /* this string is properly translated as is */,
    LPPMStepOne: "保護対象とするモバイルデバイスで、このページにアクセスしてください。{copyLink}",
    LPPMStepTwo: "モバイルアプリを入手するか、ブラウザの拡張機能をインストールします。",
    LPPMStepThree: "アプリを起動し、LastPassアカウントでログインします。",
    DownloadFor: "ダウンロードの対象: ",
    GetExtensionFor: "拡張機能の入手対象: ",
    btnDownload: "ダウンロード",
    btnClose: "閉じる",
    ReinstallSafe: "@:Brand.carboniteSafe{''}の再インストール",
    timeDelayWarning: "注意: 新しいインストールがポータルに反映されるまでに最大24時間かかる場合があります。",
    copyLink: "リンクをコピー",
    windowsArmInfoLink: "どのバージョンのWindowsを使用しているか確認する方法",
  },
  DownloadSafeComponent: {
    downloadNow: "今すぐダウンロードする",
    downloadingOnWindows: "Windowsでダウンロードしますか?",
    sha1Message:
      "{download}インストーラーの実行中、セキュリティに関する警告が表示される場合があります。警告が表示されても、インストーラーを続行して問題ありません。{moreDetails}",
    moreDetails: "詳細はこちら",
  },
  DownloadsComponent: {
    downloadsH1: "利用可能なダウンロード",
    SAFEHeader: "@:Brand.carboniteSafeCloudBackup{''}",
    SAFEParagraph:
      "すべてのファイルやフォルダを安全に保護します。@:Brand.carboniteSafe{''} Cloud Backupによって、お客様のすべてのファイルは確実に保護され、クラウドから簡単に取得できるようになります。",
    SAFEMOBILEHeader: "@:Brand.carboniteMobile{''}",
    SAFEMOBILEParagraph:
      "@:Brand.carboniteSafe{''}でデスクトップやノートPCからバックアップされたファイル、写真やドキュメントを簡単にアクセス",
    WSAPHeader: "@:Brand.WebrootAntiVirus{''} Protection" /* this string is correctly translated as is */,
    WSAPParagraph:
      "リンクをクリックする、電子メールを開く、ファイルをダウンロードする、あるいはネットワークに参加したりするなどの行為はいずれも、データを盗んだりシステムを感染させたりする機会をハッカーに与える行為です。このアプリは、他の処理を低速化させることなく、Windows® コンピューターおよびMac® コンピューターシステムのウイルス感染と個人情報の盗難を防ぎます。",
    WSCBHeader: "@:Brand.webroot{'®'} for Chromebook" /* this string is correctly translated as is */,
    WSCBParagraph: "Chrome OS® デバイスでネットサーフィン、ショッピング、バンキングを行う際の安全性を確保します。",
    WSCBAboutLogin: "@:DownloadsComponent.WSCBHeader{''}ログインについて",
    WIFIHeader: "@:Brand.WIFI{''}",
    WIFIParagraph:
      "このVPNアプリは、公共および個人のWiFiネットワークで、業務、コンテンツ共有、銀行取引、ブラウジングを行う際に、データのセキュリティとプライバシーを保護するのに役立ちます。",
    WIFIAboutLogin: "@:DownloadsComponent.WIFIHeader{''}ログインについて",
    WWTSHeader: "@:Brand.WebrootWTSBE{''}",
    WWTSParagraph:
      "Webroot Web脅威シールド拡張機能は、危険なサイトにアクセスしようとするユーザーにあらかじめ警告を与えることにより、ブラウザの違いを超えて安全なオンライン体験を提供します。",
    WRMSHeader: "@:Brand.WebrootMS{''}",
    WRMSParagraph:
      "@:Brand.WebrootMS{''}は、Android® またはiOS® デバイスでウェブブラウジング、ショッピング、バンキングを行うお客様を常に保護します。",
    keycodesH1: "キーコード",
    keycodesInfo: "キーコードは、@:Brand.webroot{''}の一部のアプリをアクティブ化する目的で使用されます。",
    keycodesInfoParagraph: "ご利用のキーコードが見つからない場合",
    hide: "非表示",
    show: "表示",
    expires: "有効期限",
    Expired: "期限切れ",
    btnGoToBackup: "バックアップに進む",
    btnGetTheApp: "アプリを入手",
    btnGetTheExtension: "拡張機能を入手",
    btnActivate: "アクティブ化",
    btnManage: "管理",
    btnAddKeycode: "キーコードを追加する",
    additionalSecurityH1: "一段高いセキュリティを目指す",
    WSAVHeader: "@:Brand.WebrootAntiVirus{''} Protection" /* this string is correctly translated as is */,
    validateEmailWarning:
      "*お客様のセキュリティを確保するため、キーコードを追加する前に電子メールアドレスの確認を求められます。",
    safeCloudBackupParagraph:
      "@:Brand.carbonite{''}は、安全にファイルをバックアップするための最良の手段です。データの損失、盗難、ハードドライブの故障、物理的な破損など、重要なファイルやデータを保護する必要があるのなら、もう迷う必要はありません。",
    exploreSecureAnywhereParagraph:
      "高い評価を得ている弊社のアンチウイルスとインターネットセキュリティソリューションが、ハッカーやサイバー犯罪からお客様のデータ、デバイス、IDを保護します。サイバー犯罪への扉を閉ざすために必要な保護を入手しましょう。",
    learnMore: "詳細",
    WindowsVersions: "Windows (x64/x86)",
    ArmVersions: "Windows (ARM)",
    MacVersions: "macOS",
    MacWSAPVersion: "macOS",
    ChromeVersions: "Google Chrome",
    ChromeOSVersions: "Chrome OS",
    EdgeVersions: "Microsoft Edge",
    FirefoxVersions: "Mozilla Firefox",
    AndroidVersions: "Android",
    iOSVersions: "iOS",
    keycodesInactiveHeader:
      "現時点でアクティブなサブスクリプションはありません。以前のサブスクリプションは期限が切れています。",
    keycodesInactiveParagraph: "次のいずれかを選択してください。",
    keycodesInactivePoint1:
      "サブスクリプションを店舗にて購入された場合は、以下のキーコードを追加してアカウントに関連付けます。",
    keycodesInactivePoint2: "期限切れのサブスクリプションを更新するには、{subscriptions}ページに移動します。",
    keycodesInactivePoint3: "以下で新しいサブスクリプションを購入します。",
    subscriptions: "サブスクリプション",
    verifyEmailMessage:
      "セキュリティのため、一部のアプリや購入は電子メールアドレスの認証が完了するまでアクセスできません。",
    btnVerifyEmail: "電子メールアドレスの認証",
    noDownloads: "この製品にはダウンロードのサポートはありません",
  },
  EditPayment: {
    savePayment: "お支払い情報を保存",
  },
  EditShipping: {
    submit: "配送情報を保存",
    back: "ご注文内容確認に戻る",
  },
  EmailVerification: {
    emailVerifiedH1: "電子メール認証済み",
    successP1: "電子メールアドレスの認証に成功しました。下のボタンをクリックしてアカウントにお進みください。",
    verificationFailedH1: "電子メールアドレスを認証できません",
    expiredP1:
      "認証用リンクの期限が切れている可能性があります。認証用リンクの有効期間は、送信から{expirationTime}です。",
    expiredP2: "新しい認証リクエストの送信を希望する場合は、[{sendNewEmail}]をクリックしてください。",
    sendNewEmail: "新しい電子メールの送信",
    failureP1: "認証用リンクの有効期限が切れています。[個人情報]に戻って新しいリクエストを送信してください。",
    expirationTime: "6時間",
    needHelp: "ヘルプが必要な場合は、{contactSupport}。",
    emailSent: "電子メールが送信されました",
    backToLogin: "ログインに戻る",
    serverErrorH1: "弊社側で問題が発生しました...",
    serverErrorP1:
      "弊社のWebサイトで発生した問題により、お探しの情報を見つけることができませんでした。後ほど再度お試しください。",
  },
  EmailVerificationNotComplete: {
    header: "電子メールアドレスを認証できません",
    bodyP1: "認証用リンクの期限が切れている可能性があります。認証用リンクの有効期間は、送信から6時間です。",
    bodyP2: "新しい認証リクエストの送信を希望する場合は、[新しい電子メールの送信]をクリックしてください。",
    bodyP3: "ヘルプが必要な場合、{contactSupport}。",
    btnState1: "新しい電子メールの送信",
    btnState2: "電子メールが送信されました",
  },
  ErrorComponent: {
    h1Heading: "問題が発生しました...",
    p1Body:
      "弊社側で問題が発生しました。ページを更新するか、後ほど改めてお試しいただくか、サポートにお問い合わせください。",
  },
  ExpiredSubSupportSectionComponent: {
    h1Heading: "サポート",
    findHelpCarbText: "@:Brand.carboniteSafe{''}のヘルプを参照する",
    carboniteCustomerSupport: "@:Brand.carbonite{''}のカスタマーサポート",
    carboniteSupportText: "@:Brand.carbonite{''}のカスタマーサポートにアクセス(英語のみ)",
    webrootCustomerSupport: "@:Brand.webroot{''}のカスタマーサポート",
    findHelpWebrootText:
      "@:Brand.webrootSecureAnywhere{''}プロテクション、@:Brand.WebrootWTS{''}拡張機能、@:Brand.WebrootMS{''}アプリおよび@:Brand.WIFI{''}アプリに関するヘルプを検索してください。",
    findHelpWebrootLine1: "次のヘルプを参照する: ",
    findHelpWebrootLine2: "- @:Brand.webrootProtection{''}プロテクション",
    findHelpWebrootLine3: "- @:Brand.WebrootWTS{''}拡張機能",
    findHelpWebrootLine4: "- @:Brand.WebrootMS{''}アプリ",
    findHelpWebrootLine5: "- @:Brand.WIFI{''}アプリ",
    webrootSupportText: "@:Brand.webroot{''}のカスタマーサポートにアクセス",
  },
  FileAccessComponent: {
    bytes: "B",
    kilobytes: "KB",
    megabytes: "MB",
    gigabytes: "GB",
    terabytes: "TB",
    btnDownloadSelectedFiles: "選択したファイルのダウンロード",
    selectForDownloadTitle: "ダウンロード用に選択",
    selectAll: "このページ上のすべてを選択",
    search: "検索",
    searchResults: '"{term}"の結果: {results}件',
    clearSearchResults: "検索のクリア",
    searchPlaceholder: "検索...",
    info: "1度にダウンロードできるのは、最大5,000個、{maxDownloadSize}GBまで",
    selectionInfo: "{count}ファイル({size})選択",
    countExceeded: "ダウンロードあたり最大5,000個",
    sizeExceeded: "ダウンロードの上限は{maxDownloadSize}GB",
    bothExceeded: "1度にダウンロードできるのは、最大5,000個、{maxDownloadSize}GBまで",
    fileWasDeleted: "コンピューターから削除されました",
    fileWillBeDeleted: "- 間もなくクラウドから削除されます",
    fileAvailableSingular: "- 翌日の復元に利用可能",
    fileAvailablePlural: "- 今後{days}日の復元に利用可能",
    sortNameAZ: "名前(A～Z)",
    sortNameZA: "名前(Z～A)",
    sortExtensionAZ: "ファイルタイプ(A～Z)",
    sortExtensionZA: "ファイルタイプ(Z～A)",
    sortSizeSmallFirst: "サイズ(小さい順)",
    sortSizeLargeFirst: "サイズ(大きい順)",
    sortDateNewFirst: "日付(新しい順)",
    sortDateOldFirst: "日付(古い順)",
    files: "({0}ファイル)",
    downloadZipInfo: "ダウンロード用のZIPファイルを作成しています。完了すると自動的にダウンロードされます。",
    pagination: "1ページあたり{size}",
    nothingBackedUp: "このデバイスに関して、現時点では何もバックアップされていません",
    driveEncrypted1: "このコンピューターでは、プライベート暗号化キーが使用されています。",
    driveEncrypted2:
      "@:Brand.remoteFileAccess{''}は、プライベート暗号化キーを使用するコンピューターではご利用いただけません。",
    driveEncrypted3a: "詳細情報: ",
    driveEncrypted3b: "@:Brand.carboniteSafe{''}のプライベート暗号化キーの管理。",
    subscriptionExpired: "このコンピューターのサブスクリプションは期限切れになりました。",
    btnSubscriptionExpired: "サブスクリプションの更新",
    restrictedFile: "セキュリティのため、このファイルのダウンロードは制限されています",
    rfaDisabledP1: "このアカウントでは@:Brand.remoteFileAccess{''}が無効化されています。",
    rfaDisabledP2: "再度有効にする必要がある場合は、{contactSupport}。",
    folderEmpty: "このフォルダは空です",
    searchNotAvailable: "検索はサーバーでは実行できません。以下のバックアップセットに移動して実行してください。",
    backupRun: "バックアップを実行",
    availableInRuns: "{numberOfResults}個のバックアップの実行が利用できます",
    servers: "サーバー",
    backupPath: "バックアップパス",
    tooManyFiles: "ダウンロードあたり最大5,000ファイル",
    restoreTooBig: "ダウンロードあたり最大50 GB",
    notFound: "問題がありました。再度お試しください。",
    emptyFolder: "このフォルダにはダウンロードできるファイルがありません。",
    downloadError: "{numberOfFiles}のファイルの圧縮にエラーが発生しました。再度お試しください。",
    genericDownloadError: "エラーが発生しました。再度お試しください。",
    requestMaxLimit:
      "上限である5個のアクティブダウンロードに達しました。どれかダウンロードが完了するまでお待ちください。",
    multipleEncryptedRunsDownloadError1: "保護されたバックアップランのダウンロードは一回ずつに限られています。",
    multipleEncryptedRunsDownloadError2:
      "他の暗号化されたバックアップランのファイルは別のリクエストでダウンロードできます。",
    passPhraseError:
      "入力されたパスフレーズは正しくありません。ダウンロードを再度お試しいただき、正しいパスフレーズを入力してください。",
  },
  FinalizeAccount: {
    headerH1: "アカウント作成を完了しましょう",
    confirmNewPassword: "パスワードの確認",
    password: "パスワード",
    passwordRequirements: "パスワード要件:",
    passwordMatch: "パスワードが合っていません",
    invalidPasswordCharacter: '"{char}"の文字は使用できません。.',
    finishSetup: "アカウントセットアップを完了",
    welcome: "ようこそ{email}",
    pleaseEnter: "アカウントを保護するためにパスワードを入力してください",
    needToLogin: "既にパスワードが登録されているか、アカウントが確認できませんでした。ログインからお試しください。",
    passwordAdded: "パスワードが更新されました。次にお進みください。",
    login: "ログイン",
  },
  FlatRateCommon: {
    btnUpgrade: "アップグレードオファー",
    upgradeOffersParagraph:
      "<strong>アップグレードオファー</strong>をクリックして、あなたに利用可能なアップグレードオファーをご確認下さい。",
    contactSupportParagraph:
      "こちらが間違っている場合や、アップグレードに関するサポートが必要な場合は、{contactSupport}にお問い合わせください。",
  },
  FlatRateExpiredLicense: {
    header: "このオファーを利用するには、アクティブなサブスクリプションが必要です。",
    p1: "この{currentSku}のサブスクリプションはすでに期限が切れています。このアップグレードオファーは、アクティブなサブスクリプションのみ有効です。",
    p2: "デバイスの保護を継続したい場合は、サブスクリプションを更新して再開することができます。",
    p3: "またはこちら{link}.",
    exploreOffers: "ウェブルートセキュリティ製品の他のオファーを探す。",
    btnRenew: "今すぐ更新",
  },
  FlatRateOfferMismatch: {
    header: "このオファーは選択された製品と一致しません",
    p1: "このアップグレードオファーは、{validSku}のみ有効です。あなたのサブスクリプションは{currentSku}です。",
  },
  FlatRateRemainingDaysInvalid: {
    header: "このサブスクリプションはすでに更新されています。",
    p1: "この{currentSku}のサブスクリプションはすでに更新されており、アップグレードオファーは無効です。",
  },
  FooterComponent: {
    contactUs: "お問い合わせ",
    agent: "Agent" /* should not be translated */,
    termsOfService: "利用規約",
    privacyPolicy: "プライバシーポリシー",
    copyright: "{currentYear} {copyrightBrand}",
    allRightsReserved: "無断転載禁止",
    support: "サポート",
    cookieSettings: "Cookieの設定" /* Cookie should not be translated */,
  },
  GetFilesWizardComponent: {
    headerStepStart: "ファイルの復元",
    lostFiles: "ファイルを紛失した場合でも、速やかに復元できるよう支援します。",
    howWould: "ご希望のファイルの復元方法を選んでください。",
    stepStartOption1: "このコンピューターにファイルをダウンロードする。",
    stepStartOption2: "外付けハードドライブでファイルを送信する(追加料金が適用されます)。",
    courierUSOnly: "@:Brand.courierRecovery{''} はリカバリーハードディスクを米国の住所にのみ送付可能です。",
    courierContactSupportText: "ファイルの復元でその他ヘルプが必要な場合は、 {link}",
    incompatibleOSWarningMac: "このmacOSバックアップは、macOSコンピューターにのみ直接復元できます。",
    incompatibleOSWarningWindows: "このWindowsバックアップは、Windowsコンピューターにのみ直接復元できます。",
    incompatibleOSInfoMessage: "お使いのコンピューターは{OS}です。",
    next: "次へ",
    headerStepDownload: "ファイルの復元",
    whichFiles: "復元するファイルを選んでください。",
    allFiles: "すべてのファイル",
    allFilesText:
      "@:Brand.carbonite{''}バックアップ内のすべてまたは大半のファイルを復元する必要がある場合は、[すべて復元]オプションを使用してください。",
    completeRestore: "すべて復元",
    onlyAFew: "少数のファイルのみ",
    onlyAFewText:
      "特定の少数のファイルをコンピューターから取得するには、@:Brand.carbonite{''}@:Brand.anytimeAnywhereAccess{''}を使用します。",
    remoteAccess: "リモートアクセス",
    headerStepCompleteRestore: "ファイルの復元",
    note: "注意: 現在ご使用のコンピューター以外のコンピューターにファイルを復元する場合は、ファイルを復元するコンピューターから弊社Webサイトに戻ってください。",
    isCarboniteInstalled: "@:Brand.carbonite{''}は、このコンピューターにインストールされて実行されていますか?",
    howToTell: "判別する方法",
    yes: "はい",
    no: "いいえ",
    noneOfComputers:
      "@:Brand.carboniteSafe{''}をインストールして、新しいコンピューターをバックアップする準備が整いました。",
    toGetStarted: "開始方法: ",
    stepDownloadInstruction1: "{computer}でこのページにアクセスします",
    stepDownloadInstruction1sub: "バックアップするコンピューター",
    stepDownloadInstruction2: "[{add}]をクリックしてインストールファイルをダウンロードして実行します。{note}{button}",
    stepDownloadInstruction2sub: "このコンピューターを追加します",
    stepDownloadInstruction3: "画面の指示に従ってファイルをバックアップします",
    onceInstalled:
      "インストール後、保護の対象として特に重要と思われるファイルとフォルダを@:Brand.carboniteSafe{''}ソフトウェアが識別します。それらをご自身で選択することもできます。ソフトウェアが実行されると、ファイルがクラウドに自動的かつ安全にコピーされます。",
    viewDetailed: "詳しい手順を表示",
    downloadNow: "今すぐダウンロードする",
    onlyAvailableOnDesktop: "注意: @:Brand.carboniteSafe{''}は、WindowsおよびmacOSでのみご利用いただけます。",
  },
  GetHelpComponent: {
    getHelp: "ヘルプを見る",
  },
  HeaderComponent: {
    myAccount: "マイアカウント",
    support: "サポート",
    logOut: "ログアウト",
  },
  HomeComponent: {
    home: "ホーム",
    securityOverview: "セキュリティの概要",
    securityZeroState: "デバイスのセキュリティ",
    webPagesScreened: "検査済みのWebページ",
    pagesBlocked: "ブロックしたページ",
    filesScanned: "スキャンしたファイル",
    devicesBackedUp: "バックアップしたデバイス",
    malwareDetections: "マルウェア検出",
    numberOfScans: "スキャン数",
    remediations: "修復",
    backupOverview: "バックアップの概要",
    backupZeroState: "バックアップしてデータの安全性を確保",
    backupZeroStateText:
      "[バックアップ]セクションには、お客様のデータの安全性が絶えずどのように維持されているかについての重要な情報が表示されます。",
    backupNotPurchasedP1:
      "お客様のデータは、偶発的な削除やコンピューターのクラッシュなど各種形態のデータ損失から保護されていません。",
    backupNotPurchasedP2: "{protect}、データのバックアップと復元に関して高い評価を得ているソリューション。",
    protectYourSelfWithCarbonite: "@:Brand.carbonite{''}で自分のデータを保護",
    securityZeroStateText:
      "[セキュリティ]セクションには、お客様が絶えずどのように脅威から保護されているかについての重要なデータポイントが表示されます。",
    securityNotPurchasedP1:
      "お客様のデータは、ウイルス、マルウェア、フィッシングなど各種デジタルの脅威から保護されていません。",
    securityNotPurchasedP2:
      "{protect}、インターネットセキュリティとアンチウイルスに関して高い評価を得ているソリューション。",
    protectYourSelfWithWebroot: "@:Brand.webroot{''}で自分のデータを保護",
    dataBackedUp: "データバックアップ済み",
    numberOfFiles: "ファイル数",
    protecting: "保護しています",
    threatProtection: "脅威に対する保護",
    kilobytes: "キロバイト",
    megabytes: "メガバイト",
    gigabytes: "ギガバイト",
    terabytes: "テラバイト",
    thousand: "千",
    million: "百万",
    billion: "十億",
    trillion: "兆",
    computers: "コンピューター",
    shields: "シールド",
    subscriptions: "サブスクリプション",
    dangerDetails: "要対応: ",
    active: "アクティブ",
    inactive: "非アクティブ",
    expiring: "まもなく期限切れ",
    expired: "期限切れ",
    cancelled: "中止",
    filesRecovered: "復元されたファイル",
    install: "インストール",
    installAndActivate: "インストールとアクティブ化",
    installText: "インストール後、24時間以内にデータが表示される見込みです。",
    webrootSecureAnywhereComplete: "@:Brand.webrootComplete{''}",
    carbonitePlus: "@:Brand.carboniteSafePlus{''}",
    webrootSecureAnywhereAntivirus: "@:Brand.WebrootAntiVirus{''}",
    comparePlans: "プラン比較",
    comparePlansBBY: "@:Brand.bestBuy{''}までお問い合わせください",
    apiError:
      "デバイスの情報を取得している際に問題が発生しました。ページを更新するか、後ほど改めてお試しいただくか、サポートにお問い合わせください。",
    noSubscriptions: "サブスクリプションの期限が切れていて、データが安全ではありません。",
    securityEmailNotValidated:
      "セキュリティのため、一部のアプリや購入は電子メールアドレスの認証が完了するまでアクセスできません",
    goToDownloads: "ダウンロードに移動",
    btnVerifyEmail: "電子メールアドレスの認証",
    activateNow: "今すぐアクティブ化",
  },
  ImagePreviewComponent: {
    footerButtonDownload: "ダウンロード",
    previous: "戻る",
    name: "名前",
    size: "サイズ",
    modified: "変更済み",
    next: "次へ",
  },
  InvoiceDetailsComponent: {
    invoiceDetails: "請求書の詳細",
    payWith: "お支払い方法: ",
    editPayment: "支払い情報の編集",
    selectCol: "選択",
    invoiceIdCol: "請求書ID",
    dateCol: "日付",
    productCol: "製品",
    subscriptionCol: "サブスクリプション",
    outstandingBalanceCol: "未払い額",
    payThisInvoice: "この請求書の支払い",
    invoiceTotal: "選択した請求書の合計: ",
    continueStatement:
      "続行することで、お客様は{termsAndConditions}および{privacyPolicy}に同意したものとみなされます。",
    termsAndConditions: "{openTextBrand}利用規約",
    privacyPolicy: "プライバシーポリシー",
    computer: "コンピューター: ",
    keycode: "キーコード: ",
    renewNow: "今すぐ更新",
    cancel: "キャンセル",
  },
  KbSearchComponent: {
    search: "検索",
    knowledgebaseArticles: "ナレッジベース記事",
    popularSearches: "人気の検索",
    reinstallCarbonite: "@:Brand.carbonite{''}の再インストール",
    renewSubscription: "サブスクリプションの更新",
    restoreFiles: "ファイルの復元",
  },
  KeycodesComponent: {
    copy: "コピー",
    expires: "期限切れ",
    keycodes: "キーコード",
  },
  languageDropDownComponent: {
    label: "言語",
  },
  LastPassComponent: {
    managePasswords: "パスワードの管理",
    accountSetup: "アカウントセットアップ",
    activate: "アクティブ化",
    passwordManager: "@:Brand.lastPassFullName{''}",
    accountsUsed: "使用中のアカウント: {used}/{total}",
  },
  LastPassDialogComponent: {
    unusedAccounts:
      "@:Brand.webrootProtection{''}サブスクリプションで利用できる未使用の@:Brand.lastPass{''}アカウントがあります。",
    createNew: "新しい @:Brand.lastPass{''}アカウントを作成する",
    finishCreate: "@:Brand.lastPass{''}アカウントの設定を完了する",
    used: "{qtd}/{total}使用中",
    notActivated: "未アクティブ化({qtd}利用可能)",
    goToLastPass: "@:Brand.lastPass{'®'} に移動する",
    expires: "期限切れ",
    activate: "アクティブ化",
    yourKeycodes: "お客様の@:Brand.webroot{''}キーコード",
    accountSetup: "アカウントセットアップ",
    activateAccount: "@:Brand.lastPass{''}をアクティブ化する",
    linkFooterNeedHelp: "詳しい手順を表示",
    footerButtonCancel: "閉じる",
    ok: "OK",
    pleaseWait:
      "ご利用のアカウントの@:Brand.lastPass{''}をアクティブ化しています。しばらくお待ちください。これには数分かかることがあります。このページを更新したりページから移動したりしないでください。",
    activating: "アクティブ化しています...このページを更新したりページから移動したりしないでください",
    creating: "アカウントを作成しています...このページを更新したりページから移動したりしないでください",
    clickHere: "こちらをクリック",
  },
  LastPassDownloadsTabComponent: {
    Header: "@:Brand.lastPassFullName{''}",
    Paragraph:
      "すべてのパスワードに一段上のセキュリティを追加します。マスターパスワードを1つ使用したアカウントを作成すれば、それ以外のパスワードは @:Brand.lastPass{''}が管理して記憶します。",
    installToActivate:
      "@:Brand.webrootProtection{''}をインストールしてアクティブ化します(インストール後、アクティブ化されるまでに最大で24時間かかる場合があります)。",
    managePasswords: "パスワードの管理",
    accountSetup: "アカウントセットアップ",
    activate: "アクティブ化",
    passwordManager: "@:Brand.lastPassFullName{''}",
    accountsUsed: "@:Brand.lastPass{''}で使用中のアカウント: {used}/{total}",
    getTheApp: "アプリを入手",
  },
  LastPassSecurityTabComponent: {
    managePasswords: "パスワードの管理",
    accountSetup: "アカウントセットアップ",
    activate: "アクティブ化",
    passwordManager: "@:Brand.lastPassFullName{''}",
    accountsUsed: "使用中のアカウント: {used}/{total}",
    activateMessage: "最先端の安全なパスワード管理でログインとパスワードを安全に作成、管理しましょう。",
  },
  LoadingSpinnerComponent: {
    loading: "読み込んでいます...",
  },
  MacToMacMoveSubscriptionComponent: {
    headerH1: "サブスクリプションを移動",
    macToMacLine1: "サブスクリプションの移動元: ",
    macToMacLine2: "@:Brand.carbonite{''}サブスクリプションの移動は、新しいコンピューターで行う必要があります。",
    macToMacLine3:
      "サブスクリプションを移動する際、すべてのバックアップファイルを維持するよう選択できます。[{Continue}]をクリックすると、{nickname}から新しいコンピューターにバックアップを復元するよう求められます。",
    footerButtonContinue: "続ける",
    footerButtonCancel: "キャンセル",
  },
  MacToWindowsMoveSubscriptionComponent: {
    headerH1: "サブスクリプションを移動",
    line1: "ご確認ください。新しいコンピューターへのファイルのダウンロードはお済みでしょうか?",
    line2:
      "以前の{oldComputerOs}からこの{newComputerOs}にサブスクリプションを移動するために、@:Brand.carbonite{''}は{nickname}というニックネームのコンピューターの{oldComputerOs}バックアップを削除する必要があります。サブスクリプションを移動する前に、必要なファイルをすべてダウンロードしてください。",
    downloadButton: "ファイルのダウンロード",
    line3: "新しいコンピューターではない場合、",
    line4a: "このページを閉じてください。準備が整ったら、新しいコンピューターで",
    line4b: "Account.carbonite.com",
    line4c: "に移動し、[サブスクリプションを移動]を選択してこのウィンドウを再度開きます。",
    footerButtonContinue: "続ける",
    footerButtonCancel: "キャンセル",
    deleteLine1:
      "この{newComputerOs}コンピューターにサブスクリプションを移動するために、@:Brand.carbonite{''}は、{nickname}というニックネームのコンピューターの{oldComputerOs}バックアップを削除する必要があります。",
    deleteLine2: "まだ必要なファイルをすべてダウンロードしていない場合は、ここでファイルを復元してください。",
    deleteLine3:
      "{iUnderstand}ファイルが再度バックアップされるまで私は、それらに@:Brand.carbonite{''}経由でアクセスすることはできません。",
    deleteIUnderstandStatement: "バックアップを削除しようとしていることを理解しています。",
    footerButtonDelete: "バックアップの削除",
    linkToAccount: "Account.carbonite.com",
  },
  ManageResellerControlRequestComponent: {
    btnYes: "はい",
    btnNo: "いいえ",
    header: "管理者になることを要求",
    p1: "{firstName} {lastName} ({email}) がアカウントの管理を要求しています。受け入れた場合、アカウントのいくつかの設定や情報は対象の管理者が管理するようになります。",
    p2: "変更点は以下の通りです:",
    li1: "すべての購入は {firstName} {lastName}から実行される必要があります。あなたは本アカウントで購入ができなくなります。",
    li2: "{firstName} {lastName} はあなたの名前とメールアドレスが参照可能です。",
    li3: "{firstName} {lastName} は個人情報や支払い方法の変更は <strong>できません</strong>。",
    question: "{firstName} {lastName} を本アカウントの管理者に設定しますか？",
  },
  MonthlyToAnnualWarning: {
    header: "アップグレードの確認",
    p1: "月額プランから年額プランにアップグレードした場合、月額プランに戻すことはできません。",
    p2: "また、@:Brand.webrootProtection{''}のアプリを、こちらからメールで送信する新しいキーコードでアップデートする必要がある場合があります。",
    p3: "J選択された年額プランにアップグレードをしますか？",
    btnActivateNewKeycode: "新しいキーコードでアクティブ化をする方法",
    btnNo: "いいえ",
    btnYes: "はい、アップグレードします",
  },
  MoveMySubscriptionComponent: {
    headerH1: "サブスクリプションを移動",
    footerButtonMove: "はい。必要なものは揃っています",
    footerButtonCancel: "キャンセル",
    footerButtonContinue: "続ける",
  },
  NextSteps: {
    downloadInstructionsMac1: "1. ファイルを開く",
    downloadInstructionsMac2: "2. @:Brand.carbonite{''}を実行",
    downloadInstructionsMac3: "3. インストール",
    downloadInstructionsWindows1: "1. ファイルを開く",
    downloadInstructionsWindows2: "2. アクセスを許可",
    downloadInstructionsWindows3: "3. インストール",
    nextStepsMac1Line1: "ダウンロードは自動的に始まります。",
    nextStepsMac1Line2: "<strong>Carbonite-Personal-client.app.zip</strong>のファイルを開きます。",
    nextStepsMac2Line1:
      "<strong>Carbonite-Personal-client.app</strong>のアイコンをダブルクリックしてCarboniteをら開きます。",
    nextStepsMac2Line2: "アプリが開いてアプリケーションフォルダにインストールされます。",
    nextStepsMac3Line1: "“開く”をクリックし、macOSの確認画面およびインストーラの手順に沿ってインストールします。",
    nextStepsMac3Line2: "インストーラの手順に沿ってインストールします。",
    nextStepsMac3Line3: "詳細な手順について(英語のみ)",
    nextStepsWinDownloadStartAuto: "ダウンロードが間もなく自動的に始まります。ダウンロードが始まらない場合は",
    nextStepsWinDownloadStartAutoBtn: "こちらをクリックして手動でダウンロード",
    nextStepsWin1Line1: "ダウンロードは自動的に始まります。",
    nextStepsWin1Line2: "<strong>CarboniteSetup-personal-client.exe</strong>を開きます。",
    nextStepsWin1Line3: "詳細な手順について(英語のみ)",
    nextStepsWin2Line1: "Windows がアプリの変更を許可するか確認した場合は「はい」を選択します。.",
    nextStepsWin3Line1: "インストーラの手順に沿ってインストールします。",
    showDetails: "詳細を表示",
    hideDetails: "詳細を非表示",
    nextStepsWebrootRenewUpgradeP1:
      "ご利用中のコンピュータおよびモバイルデバイスにインストールされている@:Brand.webroot{''}保護は自動的に延長されるため、更新プログラムをダウンロードまたはインストールする必要はありません。アプリが自動的に更新されるまで最大24時間かかる場合があります。",
    nextStepsWebrootAllStepsMyAccount:
      "<strong>@:Brand.myAccount{''}</strong>はセキュリティソフトウェア、バックアップ、ID保護、請求書、更新などをインストールおよび管理するためのワンストップダッシュボードです",
    nextStepsWebrootEstablishedP2: "ダウンロードタブに移動してソフトウェアをインストールしてください。",
    downloadInstructionsBelow: "ダウンロード手順も以下に含まれています。",
    nextStepsWebrootProvisionalP2:
      "以下のボタンをクリックしてアカウントのパスワードを設定し、インストーラをダウンロードしてください。",
    btnSetPassword: "パスワードを設定",
    nextStepsWebrootProvisionalStep1:
      "アカウントのセットアップを完了”ボタンをクリックし、アカウントを保護するためのパスワードを選択してください。",
    nextStepsWebrootProvisionalStep2: "フトウェアをセットアップするためにダウンロードタブに移動してください。",
    nextStepsAllstate:
      "マイアカウントポータルへログイン後、ホームページの<strong>@:Brand.allstate{''} @:Brand.identityProtection{''}</strong> セクション、または <strong>@:Brand.identityProtection{''}</strong>タブへ移動ください。. そちらから @:Brand.allstate{'’s'}サイトへ移動し、保護したい情報の追加や確認ができます。",
  },
  NotableFeature: {
    backupForComputer: "{quantity}台分のPCのバックアップ",
    protectionForComputer_one: "{quantity}台分のPCの保護",
    protectionForComputer_other: "{quantity}台分のPCの保護",
    protectionForDevices_one: "{quantity}台分のデバイスの保護",
    protectionForDevices_other: "{quantity}台分のデバイスの保護",
    protectionForDevicesWithIdentity: "{quantity}台分のデバイスおよび{identityCount}個分の個人情報の保護",
    protectionForDevicesWithIdentities: "{quantity}台分のデバイスおよび{identityCount}個分の個人情報の保護",
    protectionForIdentity: "{identityCount} のID保護",
    protectionForIdentities: "{identityCount} のID保護",
    protectionForPcOptimizer: "最大 {quantity} 台のPCに対するチューンアップ、クリーンアップ、および修復ツール",
    backupForComputerName: "バックアップの該当PC:",
  },
  NotificationsComponent: {
    notificationPlaceholder:
      "これは単なるプレースホルダーテキストです。実際の通知はAPIから送信されます。<strong>{text}</strong>",
    AddCreditCardFailed: "クレジットカードを追加している際にエラーが発生しました。後ほど改めてお試しください。",
    AddCreditCardSuccess: "支払い方法が正常に変更されました。",
    EmailVerificationIncomplete: "電子メールアドレスの認証が完了していません",
    MovePaymentMethodMRToAR: "{plan}の支払い方法と自動更新の詳細は正常に変更されました。",
    PendingEmailVerification: "新しい電子メールアドレスを認証する必要があります。{link}",
    PrimaryEmailVerificationWebroot: "セキュリティのため、一部の機能にアクセスするには{link}。",
    UpdateEmailLanguagePreferenceSuccess: "言語設定の更新に成功しました",
    EmailVerificationComplete: "電子メールアドレスの更新に成功しました",
    RemoveCreditCardFailed: "クレジットカードを削除しているときにエラーが発生しました。後ほど改めてお試しください。",
    LoginFailed:
      "指定されたユーザー名またはパスワードが正しくありません。再度お試しいただくか、{link}オプションを使用してください。",
    LoginFailedLinkText: "パスワードをお忘れの場合",
    LoginAccountExists: "ご入力いただいた電子メールアドレスのアカウントは既に存在します。{link}。",
    LoginAccountExistsNoLoginLink: "ご入力いただいた電子メールアドレスのアカウントは既に存在します。",
    LoginAccountLinkText: "こちらからログインしてください",
    AccountLockedOut:
      "ログインの試行回数が上限を超えました。セキュリティ上の理由から、アカウントはロックされました。1時間後に再度お試しいただくか、{link}をクリックしてください。",
    TwoStepVerificationFailed: "セキュリティコードが無効です。再度お試しください。",
    TwoStepVerificationEmail:
      "このアカウントの電子メールが有効な場合は、パスワードをリセットするためのリンクが届きます。",
    TwoStepVerificationCodeSent: "セキュリティコードを再送信しました。",
    SetTwoStepVerificationFailure: "電話番号が無効です。再度お試しいただくか、別の番号をお試しください",
    SetTwoStepVerificationSuccess: "2要素認証が正常に更新されました",
    SetTwoStepVerificationTooManyVerificationAttempts:
      "ログインの試行回数が上限を超えました。セキュリティ上の理由により、アカウントはロックされました。1時間後に再度お試しください。",
    SetTwoStepVerificationLockOut: "アカウントがロックアウトされています",
    SetTwoStepVerificationNoPhoneToValidate: "電話番号が無効です。再度お試しいただくか、別の番号をお試しください",
    SetTwoStepVerificationPhoneAlreadyExists: "電話番号が無効です。再度お試しいただくか、別の番号をお試しください",
    SetTwoStepVerificationVerificationWindowExpired: "セキュリティコードが期限切れになりました。再度お試しください。",
    SetTwoStepVerificationInvalidPhoneType: "電話番号が無効です。再度お試しいただくか、別の番号をお試しください",
    SetTwoStepVerificationHighRiskScore: "電話番号が無効です。再度お試しいただくか、別の番号をお試しください",
    SetTwoStepVerificationEmptyPhone: "電話番号を入力してください",
    SetTwoStepVerificationInvalidCountry: "国を選択してください",
    SetTwoStepVerificationDeletePhone: "2要素認証が正常に削除されました",
    SetTwoStepVerificationUnverifiedPhone: "2要素認証の設定が完了していません。できる限り早く設定を完了してください。",
    SetTwoStepVerificationInvalidVerificationMethod: "確認方法を選択してください",
    SetSecurityQuestionsSuccess: "セキュリティの質問が正常に変更されました",
    SetSecurityQuestionsFailure: "セキュリティの質問の更新中にエラーが発生しました。後ほど改めてお試しください。",
    SetPasswordSuccess: "パスワードが正常に変更されました",
    SetPasswordFailure: "パスワードの更新中にエラーが発生しました。後ほど改めてお試しください。",
    SetUserFieldsSuccess: "ユーザー情報が正常に更新されました",
    SetUserFieldsFailure: "ユーザー情報の更新中にエラーが発生しました。後ほど改めてお試しください。",
    TwoStepVerificationExpired: "セキュリティコードが期限切れになりました。再度お試しください。",
    UnderMaintenance:
      "現在、ストアウェブサイトの更新を行っています。しばらくの間、更新、アップグレード、新規購入ができません。また後ほどご確認ください。",
    PasswordReset: "新しいパスワードが正常に設定されました。ログインしてください。",
    CannotCreateAccount: "現時点ではアカウントを作成できません。後ほど改めてお試しください",
    ValidationError_FraudKeycode:
      "キーコード{keycode}は、詐欺の可能性があるとしてフラグが設定されています。{link}までお問い合わせください。",
    ValidationError_LicenseStatusIneligible:
      "キーコード{keycode}は無効になっています。{link}までお問い合わせください。",
    ValidationError_KeycodeEmailMismatch:
      "キーコード<strong>{text}</strong>は既に既存のアカウントに関連付けられています。",
    ValidationError_KeycodeEmailMismatchDownloadsComponent:
      "キーコード<strong>{text}</strong>は既に既存のアカウントに関連付けられています。",
    support: "サポート",
    ValidationError_KeycodeIneligible:
      "キーコード<strong>{text}</strong>はビジネスライセンス用であり、このアカウントに追加することはできません。",
    ValidationError_InvalidKeycode:
      "キーコード<strong>{text}</strong>は有効ではありません。キーコードを確認して、再度お試しください。",
    ValidationError_LicenseInfoAlreadyLinked:
      "キーコードは既に他のアカウントにリンクされています。キーコードを確認して、再度お試しください。",
    KeycodeAlreadyExists: "キーコード<strong>{text}</strong>は既に追加されています。",
    KeycodeExpired: "追加されたキーコードは既に期限が切れています。{link}",
    RenewHere: "ここで更新",
    RetrieveKeycodesFailed: "最近のご購入に使用されたキーコードを取得しているときに問題が発生しました。{link}",
    PartialRetrieveKeycodesFailed:
      "利用可能なダウンロードのいくつかを取得しているときに問題が発生しました。ページを更新するか、後ほど改めてお試しいただくか、サポートにお問い合わせください。",
    ServerErrors: "弊社側で問題が発生しました。後ほど改めてお試しください。",
    AddKeycodeSuccess: "キーコード<strong>{text}</strong>が正常に追加されました。",
    RecaptchaValidation_invalid: "ReCaptchaの応答が有効ではありません。",
    DownloadSafeFailed: "問題が発生しました。ダウンロードできません。後ほど改めてお試しください。",
    UnsupportedOS: "Carbonite Safeは、WindowsおよびmacOSでのみご利用いただけます",
    CardComputerBackupOutDated:
      "1つまたは複数のバックアップが期限切れです。コンピューターがインターネットに接続されていること、また@:Brand.carbonite{''}が無効になっておらず、一時停止もされていないことを確認してください。",
    CardComputerBackupOutDatedSevere:
      "1つまたは複数のバックアップが期限切れです。コンピューターがインターネットに接続されていること、また@:Brand.carbonite{''}が無効になっておらず、一時停止もされていないことを確認してください。",
    MoveMySubscriptionError: "サブスクリプションの移動中に何らかのエラーが発生しました。",
    MoveMySubscriptionSuccess: "サブスクリプションの移動が正常に実行されました。",
    MoveMySubscriptionComputerOrUser_Invalid:
      "無効なコンピューターやユーザーには、このデバイスのサブスクリプションを移動するためのアクセス権がありません。",
    SubscriptionsAlmostExpired:
      "1つまたは複数のサブスクリプションの期限が間もなく切れます。保護された状態を保つために今すぐ更新してください。",
    RenewSuccessMessage: "ありがとうございました。お客様の支払いを処理し、サブスクリプションを更新しました。",
    RenewFailureMessage:
      "ご注文を処理しているときにエラーが発生しました。カード情報を確認して、後ほど改めてお試しください。",
    ResellerSubscriptionsExpired:
      "1つまたは複数のサブスクリプションの期限が切れました。ご利用のプロバイダーに連絡して更新してください。",
    ResellerSubscriptionsAlmostExpired:
      "1つまたは複数のサブスクリプションの期限が間もなく切れます。ご利用のプロバイダーに連絡して更新してください。",
    ResellerSubscriptionsAlmostExpiredTrial:
      "1つまたは複数のサブスクリプションの期限が間もなく切れます。ご利用のプロバイダーに連絡して購入してください。",
    ResellerTrialSubscriptionsAlmostExpired:
      "1つまたは複数のサブスクリプションの期限が間もなく切れます。ご利用のプロバイダーに連絡して購入してください。",
    ResellerTrialSubscriptionsExpired:
      "1つまたは複数のサブスクリプションの期限が切れました。ご利用のプロバイダーに連絡して購入してください。",
    LastPassProvisioned: "アクティブ化が完了しました。LastPassアカウントの作成を完了するための{url}。",
    LastPassError: "LastPassをアクティブ化しているときに問題が発生しました。後ほど改めてお試しください。",
    SubscriptionWillExpireTrial:
      "1つまたは複数の体験版サブスクリプションの期限が間もなく切れます。保護された状態を保つために今すぐ購入してください。",
    CardComputerSubscriptionWillExpire:
      "1つまたは複数のサブスクリプションの期限が間もなく切れます。保護された状態を保つために今すぐ更新してください。",
    CardComputerSubscriptionExpired:
      "1つまたは複数のサブスクリプションの期限が切れました。対策を講じなかった場合、バックアップしたデータが失われるおそれがあります。",
    InvalidActivationCode: "ライセンスキーコードが有効ではありません。コードを確認して、再度お試しください。",
    BusinessActivationCode: "キーコードはビジネス用ライセンスであり、このアカウントに追加することはできません。",
    InactiveActivationCode: "ライセンスキーコードが有効ではありません。キーコードを確認して、再度お試しください。",
    ExpiredActivationCode: "ライセンスキーコードは既に期限が切れています。",
    ActivationCodeAlreadyApplied: "キーコードは既に既存のアカウントに関連付けられています。",
    ActivationCodeAppliedSuccess: "@:Brand.carbonite{''}のアクティベーションコードが正常に追加されました。",
    ActivationCodeAppliedFailed:
      "問題が発生しました。アクティベーションコードを適用できませんでした。後ほど改めてお試しいただくか、サポートまでご連絡ください。",
    AutoRenewTurnOnSuccess: "{carbonitePlan}の支払い方法と自動更新の詳細は正常に変更されました。",
    AutoRenewTurnOffSuccess: "{carbonitePlan}の自動更新を無効化にすることに成功しました。",
    AutoRenewTurnOnOrOffFailure:
      "問題が発生しました。自動更新を{on/off}にできません。再度お試しいただくか、サポートまでご連絡ください。",
    CancelSubscriptionFailure:
      "問題が発生しました。サブスクリプションをキャンセルできません。再度お試しいただくか、サポートまでご連絡ください。",
    CancelSubscriptionSuccess: "サブスクリプションが正常にキャンセルされました。",
    DataAtRiskOutstandingInvoice:
      "データが危険にさらされています: 登録されているクレジットカードに請求できませんでした。データが消去される前にクレジットカード情報を更新し、サブスクリプションを更新してください。",
    SubscriptionsExpired:
      "1つまたは複数のサブスクリプションの期限が切れました。引き続き保護機能をご利用いただくために今すぐ更新してください。",
    OptInToAutomaticVideoBackupSuccess:
      "{deviceName}によってビデオのバックアップが自動的に開始されます。この変更が有効になるまでに数時間かかる場合があります。",
    AlreadyUsedOrderHeaderToken: "アカウントは既に設定されています。{link}。",
    showAlerts: "{qty}件の警告を表示",
    hideAlerts: "警告を非表示",
    VatError_Default: "VAT IDの追加中に問題が発生しました。フォームを確認してもう一度やり直してください。",
    VatError_INVALID_INPUT: "VAT ID番号または国が無効です。フォームを確認して再度お試しください。",
    VatError_FORMAT_INVALID: "無効なVAT IDの形式です。",
    VatError_SERVICE_UNAVAILABLE: "現在サービスは利用できません。後ほど改めてお試しください。",
    VatError_MS_UNAVAILABLE:
      "Member Stateサービスは現在ご利用いただけません。後ほど改めてお試しいただくか、別のMember Stateで再度お試しください。",
    VatError_TIMEOUT:
      "時間内にMember Stateサービスに到達できませんでした。後ほど改めてお試しいただくか、別のMember Stateで再度お試しください。",
    VatError_SERVER_BUSY: "処理を妨げる問題が弊社側で発生しました。後ほど改めてお試しください。",
    VatError_BUY_GENERIC_ERROR: "VAT IDの追加に問題が発生しました。",
    EncryptionKeySuccess:
      "暗号化キーが正常にアップロードされました。今後この暗号化は、お客様に代わって@:Brand.carbonite{''}が管理いたします。お客様がキーのコピーを保管する必要はありません。",
    EncryptionKeyWrongPassword: "パスワードが間違っています。",
    EncryptionKeyFailure: "暗号化キーのアップロードに失敗しました。再度お試しください。",
    EncryptionKeyInvalidFile: "アップロードされたファイルが無効です。",
    SetEmailAddressFailure: "電子メールアドレスの更新中にエラーが発生しました。後ほど改めてお試しください。",
    EmailAddressFormSubmissionError: "電子メールアドレスを更新できません。次のエラーがないかをご確認ください。",
    EmailAlreadyInUse:
      "この電子メールアドレスは使用できません。別の電子メールアドレスをお試しいただくか、{contactSupport}。",
    PasswordResetNoLogin: "パスワードがリセットされました。",
    RFA_ComputerHasPrivateKey:
      "このコンピューターでは、データの保護にプライベート暗号化キーが使用されています。プライベート暗号化キーを使用するコンピューターでは、リモートファイルアクセスをご利用いただけません。リモートファイルアクセスおよびプライベート暗号化キーの詳細については、{link}",
    ClickHere: "こちらをクリックしてください。",
    RFA_ExpiredSubscription:
      "このコンピューターのサブスクリプションは期限切れになりました。{link}でサブスクリプションを更新してください",
    ExpiredDestination: "こちら",
    ValidationError_DuplicateCustomerInEcommerce:
      "キーコードの入力に問題がありました。Webrootのカスタマーサポートまでご連絡ください。",
    ValidationError_AMZSLicense:
      "ご入力いただいたキーコードは、Amazonアカウントで管理されています。Amazonの{link}ページにアクセスしてください。",
    ValidationError_AMZSLicense_link: "メンバーシップとサブスクリプション",
    AllstateSignUpComplete: "完了しました。お客様のAllstate Identity Protectionアカウントを作成しました。",
    BrowserNotSupportedError:
      "古いバージョンのブラウザは、マイアカウントポータルをサポートしていない場合があります。サブスクリプションをご利用いただくには、ブラウザを最新バージョンにアップデートしてください。",
    RateLimitCreditCardError: "クレジットカードを追加する操作の最大回数を超過しました。24時間後に再度お試しください。",
    GenericCreditCardError: "エラー リクエストを処理しています。",
    AllstateExpiredError:
      "サブスクリプションの有効期限が切れました。個人情報を脅威から保護するには今すぐ更新してください。",
    AllstateExpiredErrorReEnroll:
      "サブスクリプションの有効期限が切れました。サブスクリプションを更新して@:Brand.identityProtection{'.'}に再登録しましょう。",
    RenewNow: "今すぐ更新",
    AllstateExpiringWarning:
      "サブスクリプションの有効期限がもうすぐ切れます。個人情報を脅威から引き続き保護するには更新をしてください。",
    AllstateExpiringWarningReEnroll:
      "サブスクリプションの有効期限がもうすぐ切れます。@:Brand.idProtection{''}の有効期限が一旦切れると、再登録が必要になるため継続して保護する場合は更新をしてください。",
    AllstateConflictError:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} プランを再アクティブ化するために@:Brand.allstate{'’'}のCustomer Care Team(英語のみ)、800-789-2720にお問合せください",
    unable_to_use_AllstateKeycode:
      "このアカウントでは@:Brand.allstate{''} @:Brand.identityProtection{''}プランが有効となっており、現在はアカウントごとに1プランに限定されています。このキーコードに関連するプランを有効にするには、新規アカウントを作成してください。",
    DoubleAllstatePremiumError:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} はアカウントの一つのサブスクリプションにのみ適用可能です。@:Brand.identityProtection{'.'}でセットアップするプランを選択してください。他のプランについては {contactSupport}",
    AllstateSignUpError:
      "情報の送信時に問題が発生しました。再度@:brand.idProtection{''}のセットアップをお試しください。",
    WSIDPFUpgradeInfo:
      "@:Brand.WebrootPremiumFamily{''}へアップグレードしました。 家族メンバーをあなたの @:Brand.allstate{''} @:Brand.identityProtection{''} アカウントへ追加",
    WSIDPUpgradeInfo:
      "@:Brand.AllstateIdentityProtectionPremium{''}へアップグレードしました。 費用とクレジットモニタリングを @:Brand.allstate{''} @:Brand.identityProtection{''} ウェブサイトへ追加",
    WSAPFUpgradeInfo:
      "@:Brand.AllstateIdentityProtectionPremiumFamily{''}へアップグレードしました。 家族メンバーをあなたの @:Brand.allstate{''} @:Brand.identityProtection{''} ウェブサイトへ追加",
    AllstateUpgradeInfoBtn: "ID保護アカウントに移動",
    BuyflowAutoRenewMonthlyError:
      "月額サブスクリプションは自動更新の有効化が必要です。手動での更新をご希望の場合、年間サブスクリプションを選択してください。",
    CreditCardDeclinedError:
      "申し訳ありませんが、銀行はこの取引を拒否しました。クレジットカードの詳細をご確認ください。銀行にご連絡していただき、アカウントに適用された可能性のあるセキュリティチェックを削除する必要がある場合があります",
    CreditCardAddressError: "請求先住所に問題がありました。情報を確認して再度お試しください。",
    PayPalCreditCardAddressError:
      "PayPalアカウントの住所が無効です。クレジットカード情報をご提供いただくか、PayPalのページに戻って情報を更新してください。",
    CreditCardInsufficientFunds: "クレジットカートの処理が承認されませんでした。別のお支払い方法をお試しください。",
    CreditCardInvalidExpirationDate: "クレジットカードの有効期限が無効です。再度確認してお試しください。",
    PurchaseFailureError: "クレジットカードまたは請求先住所問題がありました。情報を確認して再度お試しください。",
    BuyCreateAccountSMBUserError:
      "入力したメールアドレスのアカウントは既に存在します。別のメールアドレスを使用してください",
    InactiveKeycodeAllstateError:
      "@:Brand.allstate{''} アカウントを作成する前に@:Brand.webroot{''} を先にインストールしてください。(反映に24時間かかる場合があります)",
    NewAccountPasswordError: "問題が発生しました。時間を置いて再度お試しください。",
    InvalidShippingAddress: "配送先住所が無効です。再度確認して入力してください。",
    PurchaseDoneContinueToMyAccountPage:
      "購入が完了しました。「マイアカウントに進む」をクリックすることにより、サブスクリプションを確認できます。",
    PreliminaryUserTimeOut:
      "一定時間、操作がなかったため、セッションがタイムアウトしました。買い物かごおよびメールアドレスをご確認の上、チェックアウト継続にお進みください。",
    PreliminaryUserOrderConfirmationTimeOut:
      "一定期間操作がなかったため、セッションがタイムアウトしました。メールをご確認いただき、アカウントのセットアップおよびソフトウェアのダウンロードの手順についてご確認ください。",
    AllstateBuyflowLimit:
      "以下のメールアドレスは既に @:Brand.allstate{''} @:Brand.identityProtection{''} の購入に関連付けられています。 (1つのメールアドレスにつき1つの制限があります)。",
    NonMigratedLegacyWebrootError: "サブスクリプションの管理のためにサポートへのご連絡が必要です。{サポートにお問合せ}",
    NonZuoraEcommWebrootError: "サブスクリプションの管理のためにサポートへのご連絡が必要です。{サポートにお問合せ}",
    contactSupportTeam: "サポートチームにお問合せ",
    allStateNonAvailabilityMessageLine1: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine1",
    allStateNonAvailabilityMessageLine2: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine2",
    AutoRenewDiscountEnabled: "下記サブスクリプションの次回の更新に{renewalDiscount}％の割引が適用されます。",
    LicenseDaysRemaining:
      "あなたのサブスクリプションは即時にアップグレードされ、現在のサブスクリプションから{replacementText}日間が追加されます。",
    UpgradeOnRetailRenewalNotAllowed:
      "最近の更新が開始されるまで、アップグレードはできません。すぐにサポートが必要な場合は、サポートチームにお問い合わせください。",
    PlanetOpenTextInvalidCart: "We are unable to complete the purchase at this time.", //No need to translate, this is an US only product line for PlanetOT
    PlanetOpenTextEnrollmentGuidError:
      "This purchase link has expired. Please generate a new one and complete your purchase within 24 hours.", //No need to translate, this is an US only product line for PlanetOT
  },
  OrderConfirmation: {
    h1OrderConfirmation: "ご注文の処理が完了しました",
    btnContinue: "マイアカウントに進む",
    orderConfirmationConclusion:
      "マイアカウントのウェブサイトを使用してセキュリティアプリのダウンロード、バックアップされたデバイスの管理、サブスクリプションの管理やサポートにお問合せをすることができます。",
    isRenewOrUpgradeLine1:
      "ありがとうございました。コンピュータ名{computer_name}のサブスクリプションが更新されました{renewed}。@:Brand.carboniteSafe{''}アプリは自動的に更新されます。",
    btnContinueToMyAccount: "@:Brand.myAccount{''}に進む",
    isRenewOrUpgradeLine2:
      "を押して、いつでもサブスクリプションの確認やバックアップしているデバイスにアクセスしたり、購入済みのアプリをダウンロード、ヘルプなどもアクセスできます。",
    isEstablishedAccountLine1:
      "ありがとうございます。サブスクリプションがアクティブ化されましたので、製品のダウンロードとインストールができます。",
    btnLogInToMyAccount: "@:Brand.myAccount{''}に進む",
    isEstablishedAccountLine2:
      "を押して、いつでもサブスクリプションの確認やバックアップしているデバイスにアクセスしたり、購入済みのアプリをダウンロード、ヘルプなどもアクセスできます。",
    isEstablishedAccountNextStepsLine1:
      "今お使いのPCでバックアップを行いたい場合は今すぐ@:Brand.carboniteSafe{''}をダウンロードしてインストールできます。",
    isEstablishedAccountMobile:
      "バックアップしたいコンピュータのウェブブラウザで{url}のアドレスを開き、バックアップタブから@:Brand.carboniteSafe{''}をダウンロードします。",
    provisionalLine1:
      "今お使いのPCでバックアップを行いたい場合は今すぐ@:Brand.carboniteSafe{''}をダウンロードしてインストールできます。",
    btnContinueToDownload: "ダウンロードに進む",
    h1DownloadInstructions: "@:Brand.carboniteSafe{''}のダウンロードとインストール",
    downloadInstructionsLine1: "ダウンロードが間もなく自動的に始まります。ダウンロードが始まらない場合は",
    downloadInstructionsBtnLine1: "こちらをクリックして手動でダウンロード",
    h1SetUpMyAccount: "@:Brand.myAccount{''}をセットアップ",
    setUpMyAccountLine1:
      "<strong>@:Brand.myAccount{''}</strong>はサブスクリプションに含まれるすべてのアクセス、ダウンロード、インストールおよび管理機能を一元管理できるダッシュボードです。",
    setUpMyAccountLine2:
      "“MyAccountセットアップを完了する”ボタンをクリックし、アカウントを保護するためのパスワードを入力してください。",
    btnFinishMyAccountSetup: "@:Brand.myAccount{''}セットアップを完了する",
    isFullAccountLine1:
      "今お使いのPCでバックアップを行いたい場合は今すぐ@:Brand.carboniteSafe{''}をダウンロードしてインストールできます。",
    isFullAccountBtnLine2: "@:Brand.myAccount{''}にログイン",
    isFullAccountLine2:
      "していつでもサブスクリプションの確認やバックアップしているデバイスにアクセスしたり、購入済みのアプリをダウンロード、ヘルプなどもアクセスできます。",
    isMobileLine1: "パスワードを選択して<strong>@:Brand.myAccount{''}</strong>のセットアップを完了させましょう",
    provisionalMobileLine2:
      "次に、バックアップしたい端末のブラウザからに{url}のURLを開き、ログインしたらバックアップタブから@:Brand.carbonite{''}のソフトウェアをダウンロードしてインストールします",
    isMobileBtnLine2Url: "myaccount.carbonite.com",
    thanks: "ありがとうございます！",
    thanksMessage:
      "ご購入いただきありがとうございます。セキュリティの設定を完了するために以下の手順に従ってください。領収書とダウンロード手順をメールでお送りしました。",
  },
  OutstandingInvoiceAlertComponent: {
    h1Heading: "対応が必要です",
    safeLine1: "バックアップされたデータが永久に削除されるまで、{outstandingDays} 日残っています。",
    webrootLine1: "お使いの{productName}保護が一時停止されるまで、あと{outstandingDays}日残っています。",
    line2: "請求書{invoiceNumber}が未払いです",
    safeLine3: "{productName} インストール先のコンピューター: {computerName}",
    webrootLine3: "キーコード{keycode}を使用した{productName}",
    btnRenewNow: "今すぐ更新",
  },
  PassPhraseDialogComponent: {
    title: "パスフレーズを入力",
    continueBtn: "ダウンロードを続行",
    closeBtn: "キャンセル",
    p1: "お客様のデータは、アカウントに設定されたパスフレーズで暗号化されます。",
    label1: "パスフレーズを入力",
  },
  PasswordRulesComponent: {
    uppercase: "大文字1文字",
    lowercase: "小文字1文字",
    number: "数字1文字",
    characters: "{min}～{max}文字",
  },
  PaymentHistoryComponent: {
    accountCredit: "アカウントのクレジット",
    amountOfCredit:
      "ご利用のアカウントには{amount}のクレジットがあります。このクレジットは今後のお支払いにご利用いただけます。",
    paymentHistory: "お支払い履歴",
    dateCol: "日付",
    subscriptionNumberCol: "サブスクリプション番号",
    invoiceCol: "請求書番号",
    computerNameCol: "コンピューター名",
    productCol: "製品",
    creditCardCol: "支払い方法",
    amountCol: "金額",
    taxAmountCol: "税額",
    totalWithoutCredit: "合計(クレジットを除く)",
    total: "合計",
    otherTransactionNotice:
      "@:Brand.webroot{''} の購入に関する一部お支払い情報はこちらで確認できない可能性があります。お手数ですがご購入時にメールに送付された請求書をご確認ください。",
    keycode: "キーコード: ",
    quantity: "数量: ",
    activeUntilCancelled: "キャンセルされるまでアクティブです",
    notApplicable: "該当なし",
    noInvoicesToDisplay: "表示するお支払い履歴がありません",
    requestRefund: "返金をリクエスト",
  },
  PendingEmailVerificationNotification: {
    FinishEmailVerification: "電子メールの認証を完了します。",
  },
  PersonalInformationComponent: {
    edit: "編集",
    personalInfo: "個人情報",
    firstName: "名: ",
    lastName: "姓: ",
    notVerified: "未認証",
    Current: "(現在)",
    CancelEmailChangeRequest: "電子メール変更リクエストのキャンセル",
    FinishEmailVerification: "電子メールの認証を完了",
    Disclaimer: "*新しい電子メールアドレスの認証が完了するまで、ログインには引き続き現在のアドレスをご使用ください。",
    email: "電子メール: ",
    AltEmail: "連絡用電子メール: ",
    Password: "パスワード",
    NewPassword: "新しいパスワード: ",
    TwoStep: "2要素認証",
    TwoStep2: "(推奨)",
    TwoStepTurnOn2fa: "2要素認証を有効化する",
    TwoStepPhoneNumber: "電話番号: ",
    TwoStepParagraph1Heading: "2要素認証について: ",
    TwoStepParagraph1:
      "2要素認証(2FA)を追加すると、アカウントにアクセスする際の保護が一層強化されます。アカウントへの不正アクセスを防ぐために、2要素認証を有効にすることを強くお勧めします。",
    TwoStepParagraph2:
      "その具体的な仕組みは次のとおりです。2FAの設定後は、ログイン時に、1回限りの一意のコードを含んだ確認メッセージが携帯電話番号または電子メールアドレスに届くようになります。このコードを入力することで本人確認が行われ、確認が完了するとアカウントの情報が表示されます。",
    TwoStepParagraph3:
      "このセキュリティレイヤーが追加されることで、必要に応じたパスワードのリセットも容易になります。セキュリティの質問に答える代わりに、セキュリティコードプロセスを使用することができます。",
    TwoStepNotVerified: "未認証",
    TwoStepNotSetUp: "現在設定されていません",
    SecurityQuestions: "セキュリティの質問",
    Question1Header: "質問1",
    Question1: "あなたが生まれた街は?(街の名前だけを入力してください)",
    Question2Header: "質問2",
    Question2: "結婚式を挙げた街は?",
    Question3Header: "質問3",
    Question3: "母親が生まれた街は?(街の名前だけを入力してください)",
    SecurityQuestion1Header: "セキュリティの質問1",
    SecurityQuestion2Header: "セキュリティの質問2",
    SecurityQuestion3Header: "セキュリティの質問3",
    noEntry: "入力されていません",
    update: "編集",
    FinishSetup: "設定の完了",
    emailAddress: "電子メールアドレス",
    verifyEmailAddress: "電子メールアドレスの認証",
    verifyEmailAddressMessage: "セキュリティのため、お客様の電子メールアドレスを認証してください。",
    verified: "認証済み",
    PreferredLanguage: "言語設定",
    PreferredEmailLanguage: "電子メール言語設定:",
  },
  PhoneAssistance: {
    haveQuestions: "ご質問があるか、電話でご注文されたい場合(英語のみ)",
    description1: "米国の営業チームにお問合せください。月ー金、 午前7:00ー午後7:00 MST. 電話番号:",
    description2: "または米国のtoll free:" /* this string is properly translated as is */,
    yourOrderCode: "お客様の注文番号は:",
  },
  PreferredEmailLanguageComponent: {
    headerH1: "電子メール言語設定",
    communicationEmailP1:
      " @:Brand.carbonite{''} と @:Brand.webroot{''} からアカウントの状態、ニュース、プロモーションに関して電子メールでご連絡します。その際のメールの言語設定を選択してください。",
    language: "言語",
    save: "保存",
    cancel: "キャンセル",
  },
  PrimaryEmailVerificationWebrootNotification: {
    VerifyEmailAddress: "電子メールアドレスの認証",
  },
  PromoCodeComponent: {
    promoCode: "プロモーションコード",
    PromoCodePct: "{amount}% の割引が適用済み",
    PromoCodeAmt: "{amount} の割引が適用済み",
    BonusMonthsPromo: "{amount} ヵ月の無料期間適用済み",
    invalidPromoCode: "無効な プロモーションコード",
  },
  RefundComponent: {
    heading: "製品返金",
    product: "製品",
    keycode: "キーコード",
    paymentMethod: "お支払い方法",
    purchaseDate: "購入日",
    refundBtn: "返金要請",
  },
  RemoveCreditCardComponent: {
    headerH1: "クレジットカードの削除",
    paragraph1:
      "リクエストされたクレジットカードは削除されます。間違えた場合は、[いいえ。カードは削除しません]リンクをクリックしてください。",
    footerButtonSave: "はい。カードを削除します",
    footerButtonCancel: "いいえ。カードは削除しません",
  },
  ReviewOrderComponent: {
    endingIn: "有効期限",
    exp: "有効期限:",
    thisSubscriptionWillAutomaticallyRenew:
      "すべてのサブスクリプションは上記の日付に自動的に更新されます*。 事前にリマインドメールをお送りします。",
    thisSubscriptionIsValidUntil: "この支払いは上記日付までのすべてのサブスクリプションがカバーされます*。",
    permissionLabel:
      "提供した情報には偽りがなく、正確で、完全であることを確認しました。また、{brand}{''}の利用規則およびプライバシーポリシーに同意します。",
    secureCheckout: "購入を完了",
    GDPREmailOptIn:
      "{brand}{''} LLCから他の製品やサービス、プロモーションなどについてメールの受信を許諾します。また、メールの受信についてご連絡いただくことにより、いつでも解除できることも理解しました。",
    flatRateUpgradeRenewalText: "ご契約は{date}に{amount}で{year}年間更新されます。",
  },
  SafeLongExpiredComputer: {
    header: "このサブスクリプションを更新できません",
    p1: "このサブスクリプションは30日以上前に期限切れとなり、更新できません。新しい@:Brand.carboniteSafe{''}プランを購入するには、<strong>ショッピングを開始</strong>をクリックしてください。",
    startShoppingBtn: "Start Shopping" /* this string is properly translated in this context */,
  },
  SecurityAnswerValidation: {
    SecurityAnswer: "セキュリティの回答",
    AnswerDefaultError: "回答に次を含めることはできません: ******.",
    AnswerMatchingError: "セキュリティの質問への回答は一意であることが必要です。",
    AnswerEmptyStringError: "回答にはスペース以外を含む必要があります。",
  },
  SecurityComponent: {
    headerH1: "セキュリティ概要",
    headerH2: "デバイス",
    protecting: "保護しています",
    threatProtection: "脅威に対する保護",
    noLicenseHeaderH1: "セキュリティ",
    noLicenseHeaderH2: "表示するアクティブなセキュリティサブスクリプションはありません。",
    addWebroot: "今すぐ@:Brand.webrootProtection{''}を追加しましょう。",
    noLicenseP1:
      "高い評価を得ている弊社のアンチウイルスとインターネットセキュリティソリューションが、ハッカーやサイバー犯罪からお客様のデータ、デバイス、IDを保護します。サイバー犯罪への扉を閉ざすために必要な保護を入手しましょう。",
    learnMoreBtn: "詳細",
    noLicenseP2:
      "小売店や同様の購入手段でキーコードを入手した場合、{downloadsLink}ページにそのキーコードを入力することでサブスクリプションを追加できます。",
    downloadsLink: "ダウンロード",
    goToDownloads: "ダウンロードに移動",
    expires: "有効期限: ",
    expired: "期限切れ",
    expiresIn: "{daysToExpire} {daysNoun}後({expirationDate})に期限切れ",
    day: "日",
    days: "日",
    noDevicesHeader: "このサブスクリプションではまだデバイスを保護していません。",
    noDevicesParagraph:
      "まず、[ダウンロード]ページにアクセスして、このサブスクリプションで利用できるすべてのアプリを確認します。",
    noDevicesViewDetailsLink: "詳しい手順を表示",
    installBtn: "インストール",
    installAndActivateBtn: "インストールとアクティブ化",
    renewBtn: "今すぐ更新",
    buyBtn: "今すぐ購入",
    bbyBtn: "Best Buyに問い合わせる",
    showHiddenDevices: "非表示のデバイスを表示",
    serverError:
      "お客様のデバイスおよび、脅威に対する保護についての情報を取得している最中に問題が発生しました。ページを更新するか、後ほど改めてお試しいただくか、{contactSupportLink}。",
    serverErrorDevices:
      "デバイスの情報を取得している最中に問題が発生しました。ページを更新するか、後ほど改めてお試しいただくか、{contactSupportLink}。",
    howToEnableBtn: "有効にする方法",
    isNotActiveYet: "まだアクティブ化されていません",
    activeUntilCancelled: "キャンセルされるまでアクティブです",
    verifyEmailMessage: "@:DownloadsComponent.verifyEmailMessage",
    btnVerifyEmail: "@:HomeComponent.btnVerifyEmail",
    WSCBUnavailable: "現在のところ、Chromebookデバイスの情報は表示されません。",
    getStartedWSCB:
      "まず、[ダウンロード]ページにアクセスして、このサブスクリプションで利用できるすべてのアプリを確認します。",
    getTheApp: "アプリを入手",
    WSCBnote: "*現在のところ、モバイルとChromebookデバイスは表示されません。",
  },
  SelectPlan: {
    currentPlan: "現在のプラン",
    bestValue: "価格",
    selectedPlan: "選択したプラン",
    perMonth: "/月",
    billedAnnually: "年単位のご請求",
    unlimitedBackup: "無制限のバックアップ",
    secureCloud: "暗号化された安全なクラウド",
    photoBackup: "自動的なファイルと写真のバックアップ",
    allFileTypes: "すべてのファイルと画像タイプをサポートしています",
    easyMobile: "モバイルデバイスから簡単にアクセス",
    hardDriveBackup: "外部ハードドライブのバックアップ",
    videoBackup: "自動的な動画ファイルのバックアップ",
    courierDelivery: "復元ファイルのCourier delivery(米国のみ)" /* this string is properly translated */,
    percentSavings: "{savings}割引",
  },
  SelectPlanLink: {
    linkText: "プラン選択に戻る",
  },
  SetDeviceNicknameComponent: {
    nicknameLabel: "デバイス名",
    headerH1: "デバイス名の変更",
    footerButtonCancel: "キャンセル",
    footerButtonSave: "保存",
  },
  SetNewUserPassword: {
    headerH1: "アカウント作成を完了しましょう",
    confirmNewPassword: "パスワードの確認",
    password: "パスワード",
    passwordRequirements: "パスワード要件:",
    passwordMatch: "パスワードが合っていません",
    invalidPasswordCharacter: '"{char}"の文字は使用できません。.',
    finishSetup: "アカウントセットアップを完了",
    welcome: "ようこそ{email}",
    pleaseEnter: "アカウントを保護するためにパスワードを入力してください",
  },
  SetSecurityQuestionsComponent: {
    headerH1: "セキュリティの質問の編集",
    answer: "回答",
    footerButtonSave: "保存",
    footerButtonCancel: "キャンセル",
    chooseOption: "- 質問を選択してください -",
    question1: "あなたが生まれた街は? (街の名前だけを入力してください)",
    question2: "結婚式を挙げた街は?",
    question3: "母親が生まれた街は? (街の名前だけを入力してください)",
  },
  SetTwoStepVerificationComponent: {
    h1Heading: "2要素認証",
    h1Heading2: "セキュリティコードの確認",
    informationP1: "アカウントをどのように確認しますか?",
    option1: "ショートメッセージ",
    option2: "電話",
    option3: "2要素認証を無効にする(推奨されません)",
    label1: "電話番号を追加します",
    heading1: "2要素認証を無効にしますか?",
    informationP2:
      "2要素認証(2FA)があることで、アカウントにアクセスする際の保護が一層強化されます。アカウントへの不正アクセスを防ぐために、2要素認証を有効にすることを強くお勧めします。このセキュリティレイヤーが追加されることで、必要に応じたパスワードのリセットも容易になります。セキュリティの質問に答える代わりに、セキュリティコードプロセスを使用することができます。",
    informationP3:
      "*標準のショートメッセージ料金が適用されます。@:Brand.carbonite{''}と@:Brand.webroot{''}がお客様の電話番号を第三者と共有することは決してありません。",
    save: "保存",
    cancel: "キャンセル",
    next: "次へ",
    submit: "コードを送信",
    informationP1a:
      "このアカウントの電子メールが有効な場合は、セキュリティコードが電話またはショートメッセージで送信されます",
    informationP1b: "以下にセキュリティコードを入力してください。",
    labelSecurityCode: "セキュリティコード",
    informationP3a: "セキュリティコードが届かない場合",
    informationP3b: "再送信",
    informationP3c: "新しいコードを送信する",
    submitCode: "コードを送信",
    bodyP1: "{phone}に送信された5桁のセキュリティコードを入力してください",
    bodyP2: "このコードの有効期間は、送信されてから1時間です。",
    USA: "アメリカ",
    AF: "アフガニスタン",
    AL: "アルバニア",
    DZ: "アルジェリア",
    AD: "アンドラ",
    ARG: "アルゼンチン",
    AW: "アルバ",
    AUS: "オーストラリア",
    AUT: "オーストリア",
    BHS: "バハマ",
    BRB: "バルバドス",
    BEL: "ベルギー",
    BMU: "バミューダ",
    BRA: "ブラジル",
    CAN: "カナダ",
    CHL: "チリ",
    CHN: "中国",
    COL: "コロンビア",
    CRI: "コスタリカ",
    CU: "キューバ",
    CZ: "チェコ共和国",
    DNK: "デンマーク",
    DM: "ドミニカ",
    DO: "ドミニカ共和国",
    ECU: "エクアドル",
    FI: "フィンランド",
    FRA: "フランス",
    DACH: "ドイツ",
    GRC: "ギリシャ",
    GL: "グリーンランド",
    GTM: "グアテマラ",
    HKG: "香港",
    HU: "ハンガリー",
    IS: "アイスランド",
    IND: "インド",
    IRL: "アイルランド",
    ISR: "イスラエル",
    ITA: "イタリア",
    JAM: "ジャマイカ",
    JPN: "日本",
    KEN: "ケニア",
    LU: "ルクセンブルク",
    MYS: "マレーシア",
    MEX: "メキシコ",
    NLD: "オランダ",
    NZL: "ニュージーランド",
    NOR: "ノルウェー",
    PAN: "パナマ",
    PER: "ペルー",
    PHL: "フィリピン人",
    PL: "ポーランド",
    PT: "ポルトガル",
    PRI: "プエルトリコ",
    RUS: "ロシア",
    SGP: "シンガポール",
    ZAF: "南アフリカ",
    KOR: "韓国",
    ESP: "スペイン",
    SWE: "スウェーデン",
    CHE: "スイス",
    THA: "タイ",
    TTO: "トリニダード・トバゴ",
    TR: "トルコ",
    TUV: "米領バージン諸島",
    ARE: "アラブ首長国連邦",
    GBR: "イギリス",
    PY: "Paraguay ", // English
    EST: "Estonia ", // English
    SVN: "Slovenia ", // English
  },
  SetUserFieldsComponent: {
    headerH1: "個人情報の編集",
    firstName: "名(省略可)",
    lastName: "姓(省略可)",
    email: "電子メールアドレス",
    altEmail: "連絡用電子メールアドレス(省略可)",
    footerButtonSave: "保存",
    footerButtonCancel: "キャンセル",
    emailInUse: "電子メールアドレスは既に他のアカウントに関連付けられています。",
    alternateEmailInUse: "電子メールアドレスは既に他のアカウントに関連付けられています。",
    firstNameError: "名",
    lastNameError: "姓",
    invalidCharacters: "次の文字は使用できません",
  },
  SetUserPasswordComponent: {
    headerH1: "パスワードの変更",
    confirmNewPassword: "新しいパスワードの確認",
    currentPassword: "現在のパスワード",
    newPassword: "新しいパスワード",
    footerButtonSave: "保存",
    footerButtonCancel: "キャンセル",
    incorrectPassword: "パスワードが間違っています。再度お試しください。",
    passwordRequirements: "パスワード要件: ",
    passwordMatch: "パスワードが一致しません。",
    invalidPasswordCharacter: '"{char}"文字は使用できません。',
  },
  ShippingAddressFormErrors: {
    firstNameInvalidCharacter: "{0}文字は使用できません。",
    lastNameInvalidCharacter: "{0}文字は使用できません。",
    addressInvalidCharacter: "{0}文字は使用できません。",
    cityInvalidCharacter: "{0}文字は使用できません。",
    nameLengthMin: "お名前は入力必須です",
    addressLength: "住所は、3文字以上60文字以下で入力してください",
    cityLength: "市区町村は、2文字以上50文字以下で入力してください",
    stateSelect: "州を選択してください",
    stateLength: "都道府県は必須です。",
    zipLength: "郵便番号は5桁から9桁内で入力してください。（ハイフンを入力しないでください）。",
    zipNumbersOnly: "郵便番号は数字のみで入力してください。",
    zipRequired: "郵便番号は必須です。",
    firstNameLengthMax: "名は50文字未満とする必要があります。",
    lastNameLengthMax: "姓は50文字未満とする必要があります。",
    firstNameLengthMin: "名は入力必須です。",
    lastNameLengthMin: "姓は入力必須です。",
    addressRequired: "住所は必須です。",
    cityRequired: "市区町村は必須です。",
  },
  ShippingForm: {
    shipping: "配送",
    sameAddressLabel: "配送先住所は請求先住所と同じです",
    firstName: "名",
    lastName: "姓",
    country: "国",
    state: "州",
    address: "住所1",
    address2: "住所2 (オプション)",
    city: "市",
    zip: "Zip/郵便番号" /* this string is properly translated */,
    selectOne: "- 選択してください -",
  },
  SidebarComponent: {
    downloads: "ダウンロード",
    upgradeBoxHeader: "Proにアップグレード",
    upgradeBoxHeaderSmb: "プランのアップグレード",
    upgradeBoxBody:
      "@:Brand.carboniteSafePro{''}(英語のみ)には、コンピューターの台数無制限、コンピューターの一元管理、HIPAA準拠などが含まれます。",
    upgradeBoxBodySmb: "@:Brand.carboniteOffice{''}プランのアップグレード",
    upgradeBoxLink: "今すぐプランを見る",
    upgradeBoxLinkSmb: "プランのアップグレード",
    creditCards: "クレジットカード",
    paymentHistory: "お支払い履歴",
    personalInformation: "個人情報",
    subscriptions: "サブスクリプション",
    support: "サポート",
    backup: "バックアップ",
    smbBackupDashboard: "バックアップダッシュボード",
    smbNotifications: "お知らせ",
    smbManageBackupPolicies: "バックアップポリシーの管理",
    smbAccountSummary: "アカウントの概要",
    smbBillingInformation: "請求先情報",
    smbPersonalInformation: "個人情報",
    smbGetHelp: "ヘルプを見る",
    smbContactUs: "お問い合わせ",
    home: "ホーム",
    security: "セキュリティ",
    allstate: "@:Brand.identityProtection{''}",
  },
  SpecialOffers: {
    specialOffersH1: "スペシャルオファー",
    AllstateIdentityProtectionBasicDescription1:
      "Protect yourself from identity theft with @:Brand.allstate{''}で個人情報の盗難から保護しましょう。" /* this string is properly translated */,
    AllstateIdentityProtectionBasicDescription2: "本製品は米国でのみご利用いただけます。",
    AllstateIdentityProtectionBasicDescription3:
      "1アイデンティティの保護, $25K coverage limit." /* this string is properly translated */,
    AllstateIdentityProtectionPremiumDescription1: "@:Brand.allstate{''}で個人情報の盗難から保護しましょう。",
    AllstateIdentityProtectionPremiumDescription2: "本製品は米国でのみご利用いただけます。",
    AllstateIdentityProtectionPremiumDescription3:
      "1アイデンティティの保護, $1M coverage limit." /* this string is properly translated */,
    AllstateIdentityProtectionPremiumFamilyDescription1:
      "個人情報の盗難や詐欺から@:Brand.allstate{''} @:Brand.identityProtection{''}で家族全体のアカウントを保護しましょう",
    AllstateIdentityProtectionPremiumFamilyDescription2: "本製品は米国でのみご利用いただけます。",
    AllstateIdentityProtectionPremiumFamilyDescription3:
      "10個人の保護, $1M coverage limit." /* this string is properly translated */,
    WebrootWiFiSecurityDescription1: "@:Brand.webroot{''} VPNでWiFi接続をプライベートで安全にしましょう",
    WebrootWiFiSecurityDescription2: "3台の保護",
    WebrootPcOptimizerDescription1:
      "スピードアップ、最適化、クリーンアップ、エラー修復を行い、Windows PC を新品同様にします。",
    WebrootPcOptimizerDescription2: "10台のデバイスに対する最適化",
    startingAt: "開始日",
  },
  SpecialOffersInfo: {
    StartingAt: "開始日 {price}",
    SoftwareCDP1:
      "「ソフトウェアCDは、@:Brand.webrootProtection{''} を再インストールするためのオプションの方法です。一部のお客様はこの方法を好まれます。購入にはソフトウェアのダウンロードも含まれていますのでご確認ください。」",
    AllstateIdentityProtectionBasicLI1: "@:Brand.allstate{''} @:Brand.identityProtection{''} Basic 1個人用",
    AllstateIdentityProtectionBasicLI2: "個人情報、金融情報およびダークウェブのモニタリング",
    AllstateIdentityProtectionBasicLI3: "24/7の米国の専門修復エキスパート",
    AllstateIdentityProtectionPremiumL1: "@:Brand.allstate{''} @:Brand.identityProtection{''} Premium 1個人用",
    AllstateIdentityProtectionPremiumL2: "個人情報、金融情報およびダークウェブのモニタリング",
    AllstateIdentityProtectionPremiumL3: "クレジットモニタリング (1 bureau)" /* this string is properly translated */,
    AllstateIdentityProtectionPremiumL4: "24/7の米国の専門修復エキスパート",
    AllstateIdentityProtectionPremiumFamilyL1:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} Premium 10人家族用" /* this string is properly translated */,
    AllstateIdentityProtectionPremiumFamilyL2: "個人情報、金融情報およびダークウェブのモニタリング",
    AllstateIdentityProtectionPremiumFamilyL3:
      "クレジットモニタリング (1 bureau)" /* this string is properly translated */,
    AllstateIdentityProtectionPremiumFamilyL4: "24/7の米国の専門修復エキスパート",
    WebrootWiFiSecurityP1:
      "@:Brand.webroot{''} WiFi プライバシーVPNは自宅や公共のWiFiスポットの通信を暗号化し、オンラインの活動を匿名化することにより、データやプライバシーを保護します。",
    WebrootWiFiSecurityP2: "@:Brand.webroot{''} プライバシー VPNで接続を保護しましょう",
    WebrootPcOptimizerDescriptionP1:
      "スピードを向上させ、スペースを確保し、エラーを修復する究極の PCチューンアップツールです。",
    WebrootPcOptimizerDescriptionP2: "Windows PCを数秒で最高のパフォーマンスに復元します。",
  },
  SplitButtonComponent: {
    AlsoAvailableFor: "その他対応:",
  },
  StrongPasswordMessages: {
    useUpperCase: "パスワードには大文字(ABCDなど)を少なくとも1つ含める必要があります",
    useLowerCase: "パスワードには小文字(abcdなど)を少なくとも1つ含める必要があります",
    oneNumber: "パスワードには数字(1234など)を少なくとも1つ含める必要があります",
    characters: "パスワードは、{min}～{max}文字で設定してください",
    cannotUseBraces: "パスワードに{'<'}や{'>'}は使用できません",
  },
  SubscriptionInformationComponent: {
    subscriptionsHeading: "サブスクリプション",
    productCol: "製品",
    paymentMethodCol: "支払い方法",
    nextPaymentDateCol: "次回お支払い",
    manageCol: "管理",
    accountServicesHeading: "アカウントサービス",
    applyACLink: "@:Brand.carbonite{''}のアクティベーションコードを適用",
    optionsLink: "オプション",
    installLink: "インストール",
    computer: "コンピュータ名: ",
    upgradeLink: "アップグレード",
    renewUpgradeLink: "アップグレード",
    notApplicable: "該当なし",
    buyNowLink: "今すぐ購入",
    renewNowLink: "今すぐ更新",
    trial: "体験版",
    automatic: "自動",
    manual: "手動でのお支払い",
    keycode: "キーコード: ",
    expired: "期限切れ",
    closed: "完了",
    notActivatedYet: "まだアクティブ化されていません",
    contactBestBuyLink: "@:Brand.bestBuy{''}までお問い合わせください",
    contactAmazonLink: "@:Brand.amazon{''}に問い合わせる",
    processingRenewal: "更新を処理しています",
    paymentDetails: "支払い方法の詳細",
    note1:
      "注意: セキュリティ上の理由により、既存の支払い方法の情報を編集することはできません。[{change}]をクリックして、それらの情報を新しいカードとして追加してください。",
    changePaymentMethod: "支払い方法の変更",
    selectPaymentMethod: "支払い方法の選択",
    addPaymentMethod: "支払い方法の追加",
    paymentDetailsHeaderCarb: "新しい支払い方法は、次の@:Brand.carbonite{''}サブスクリプションに適用されます。",
    paymentDetailsHeaderWebroot: "新しい支払い方法は、次の@:Brand.webroot{''}サブスクリプションに適用されます。",
    on: "に",
    addPaymentHeader: "この支払い方法は、すべての@:Brand.carbonite{''}サブスクリプションに適用されます。",
    all: "すべて",
    unassigned: "未割り当て",
    addNewPaymentMethod: "支払い方法の追加",
    vatIdNumber: "VAT ID番号",
    edit: "編集",
    noDataToDisplay: "表示するサブスクリプションがありません",
    installToActive: "インストールしてアクティブ化",
    changeSubscription: "変更 @:Brand.carbonite{''}プラン",
    changePaymentProcessingMessage: "お支払い方法を更新しています。ページの更新や戻るボタンは押さないでください。",
    paymentTypeCC: "@:BuyPaymentComponent.paymentTypeCC",
    paymentTypePP: "@:BuyPaymentComponent.paymentTypePP",
  },
  SupportComponent: {
    h1Heading: "サポート",
    additionalProducts: "追加の製品",
  },
  TwoStepVerificationComponent: {
    h1Heading: "セキュリティコードの確認",
    cancel: "キャンセル",
    next: "コードを送信",
  },
  UnderMaintenanceComponent: {
    pardonTheInconvenience: "ご不便をおかけして申し訳ありません",
    UnderMaintenanceP1: "現在、ストアの更新を行っています。すぐに再開する予定ですので、後ほどご確認ください。",
  },
  UndoRemove: {
    text: "{product} が削除されました",
    undoBtn: "元に戻す",
    cartEmpty: "買い物かごが空です",
    startShopping: "ショッピングを開始",
  },
  UpdateEmailAddressComponent: {
    headerH1: "電子メールアドレスの編集",
    email: "電子メールアドレス",
    password: "パスワード",
    emailInUse: "この電子メールアドレスは使用できません。",
    save: "保存",
    cancel: "キャンセル",
    passwordStatement: "不正な変更を防ぐためにパスワードを入力する必要があります。",
    incorrectPassword: "パスワードが間違っています。",
    newEmailSameAsOld: "これはお客様の現在の電子メールアドレスです。新しいアドレスを入力してください。",
  },
  UpgradeSubscriptionComponent: {
    header: "アップグレード/追加",
    upgrade: "アップグレード",
    yourSubscriptions: "ご利用中のサブスクリプション",
    choose: "サブスクリプションの購入対象となるコンピューターを選択してください。",
    computerCol: "コンピューター",
    planCol: "プラン",
    expirationCol: "有効期限",
    statusCol: "状態",
    next: "次へ",
    cancel: "キャンセル",
    continue: "続ける",
    addNewSub: "新しいサブスクリプションの追加",
    youHaveUnusedH1: "未使用のサブスクリプションが{qty}個あります",
    youHaveUnusedP:
      "@:Brand.carbonite{''}アカウントで利用できる未使用の@:Brand.carbonite{''}サブスクリプションが{qty}個あります。このサブスクリプションを適用するか、新たに購入することができます。",
    wouldYouLikeToApplyNow: "このサブスクリプションを今適用しますか?",
    yesApply: "はい。未使用のサブスクリプションを適用します",
    noPurchase: "いいえ。サブスクリプションの追加購入を希望します",
    selectAC: "1.適用するアクティベーションコードを選択してください",
    chooseUse: "2.このアクティベーションコードはどのように使用しますか?",
    applyToExistingComputer: "同じ@:Brand.carbonite{''}プランで既存のコンピューターに適用します: ",
    applyToThisComputerAndInstall: "このコンピューターに適用してインストールします",
    addAnotherCode: "別のコードを追加します",
    active: "アクティブ",
    activeAutoRenewal: "アクティブ - 自動更新",
    activeUntilCancelled: "アクティブ - キャンセルされるまでアクティブです",
    expiresIn1day: "後1日で期限が切れます",
    expiresIn: "有効期限",
    days: "日",
    trial: "トライアル",
    expired: "期限切れ",
    trialExpired: "- 期限切れ",
  },
  UpgradeToProComponent: {
    header: "@:Brand.carboniteOffice{''}プランにアップグレード(英語のみ)",
    paragraph1:
      "複数のコンピューター、Windowsファイルサーバー、外付けハードドライブをバックアップする場合や、医療、財務、教育関連の機密を要するファイルがある場合は、@:Brand.carboniteOffice{''}プランが必要となります。",
    paragraph2:
      "企業・法人の活動を常時維持するレベルの堅牢な保護が得られるのは、@:Brand.carboniteOffice{''}プランのみです。機能は次のとおりです。",
    bulletPoint1: "コンピューター、外付けドライブ、NASデバイスを、台数無制限でバックアップ",
    bulletPoint2: "Windowsファイルサーバーをサポート",
    bulletPoint3: "HIPAA、FINRA、FERPA準拠をサポート",
    bulletPoint4: "簡単なセットアップとValetインストールでコンピューターの追加に対応",
    questions: "お問い合わせ",
    smallBusinessBackupEmail: "wr-enterprise{'@'}opentext.com",
    clickHere: "プランを表示するにはこちらをクリックしてください",
    noThanks: "何もしない",
    phoneNumber: "03-4588-6500",
  },
  VatInputComponent: {
    label: "EU VAT identification number" /* this string should not be translated */,
    edit: "編集",
    vatIdVerified: "VAT ID  認証済み" /* this string is properly translated */,
    addVat: "VAT ID追加" /* this string is properly translated */,
    businessCustomers: "日本の法人のお客様へ: VAT ID numberを追加することにより、消費税免除を受けられる場合があります",
  },
  VerifyExistingEmailAddressComponent: {
    close: "閉じる",
    Heading: "電子メールアドレスの認証",
    VerifyEmail: "お客様の電子メールアドレスを認証する必要があります。",
    ClickWord: "以下の",
    ButtonLabel: "[認証用の電子メールの送信]",
    AfterButton: "をクリックすると、認証用の電子メールが次の宛先に送信されます:",
    From: "送信元: ",
    CarbWebroot: "@:Brand.carbonite{''}+@:Brand.webroot{''}",
    YouHave: "電子メールの送信から",
    TimeFrame: "6時間",
    AfterTimeFrame: "以内に電子メールアドレスの認証を行ってください。",
    EmailSent: "電子メールが送信されました。",
  },
  VerifyPendingEmailAddressComponent: {
    headerH1: "電子メールアドレスの認証",
    line1: "新しい電子メールアドレスを認証する必要があります。電子メール{email}を確認してください。",
    line2:
      "電子メールアドレスの認証用リンクを記載した電子メールを、{carbonitePlusWebroot}から「{alert}」という件名で送信しました。念のため、迷惑メールフォルダを確認してください。",
    line3: "認証用の電子メールが送信されてから{time}以内に電子メールアドレスの認証を行ってください。",
    time: "6時間",
    close: "閉じる",
    sendAnotherEmail: "別の電子メールの送信",
    emailSent: "電子メールが送信されました",
    carbonitePlusWebroot: "@:Brand.carbonite{''} +@:Brand.webroot{''}", // used in line 2
    alert: "警告: 電子メールアドレスを認証してください", // alert from line 2
  },
  VerifyPrimaryEmailAddressComponent: {
    headerH1: "電子メールアドレスの確認",
    line1: "お客様の電子メールアドレスを確認する必要があります。電子メール{email}を確認してください。",
    line2:
      "電子メールアドレスの認証用リンクを記載した電子メールを、{carbonitePlusWebroot}から「{alert}」という件名で送信しました。念のため、迷惑メールフォルダを確認してください。",
    line3: "認証用の電子メールが送信されてから{time}以内に電子メールアドレスの認証を行ってください。",
    time: "6時間",
    close: "閉じる",
    sendAnotherEmail: "別の電子メールの送信",
    emailSent: "電子メールが送信されました",
    carbonitePlusWebroot: "@:Brand.carbonite{''} +@:Brand.webroot{''}", // used in line 2
    alert: "警告: 電子メールアドレスを認証してください", // alert from line 2
  },
  VerifyPrimaryEmailAddressCtnComponent: {
    headerH1: "電子メールアドレスの認証",
    line1: "キーコードを追加する前に、お客様の電子メールアドレスを認証してください。",
    line2:
      "電子メールアドレスの認証用リンクを記載した電子メールを{email}宛てに送信しました。念のため、迷惑メールフォルダを確認してください。",
    line3: "認証用の電子メールが送信されてから{time}以内に電子メールアドレスの認証を行ってください。",
    time: "6時間",
    cancel: "キャンセル",
    continue: "続ける",
    sendAnotherEmail: "別の電子メールの送信",
    emailSent: "電子メールが送信されました",
  },
  Verify2FA: {
    h1Heading: "セキュリティコードの確認",
    informationP1a:
      "このアカウントの電子メールが有効な場合は、セキュリティコードが電話またはショートメッセージで送信されます",
    informationP1b: "以下にセキュリティコードを入力してください。",
    informationP2: "このコードの有効期間: ",
    labelSecurityCode: "セキュリティコード",
    informationP3a: "セキュリティコードが届かない場合",
    informationP3b: "再送信",
    informationP3c: "新しいコードを送信する",
    cancel: "キャンセル",
    next: "コードを送信",
    bodyP1: "{phone}に送信された5桁のセキュリティコードを入力してください",
    bodyP2: "このコードの有効期間は、送信されてから1時間です。",
  },
  WebrootARTurnOffComponent: {
    WRAutoRenewOffLine1: "自動更新の無効化",
    WRAutoRenewOffLine2: "1)自動更新を無効化するには、{page}にアクセスします。",
    WRAutoRenewOffLine3: "2)そのページでキーコードを入力する必要があります(こちらを参考にしてください)。",
    WRAutoRenewOffLine4: "クリップボードにコピーします",
    WRAutoRenewOffLine5: "@:Brand.webroot{''}サブスクリプションの管理について詳しくは、{link}で検索してください。",
    Link1: "ナレッジベース",
    Link2: "こちらのページ",
    Keycode: "キーコード: ",
  },
  WebrootARTurnOnComponent: {
    WRAutoRenewOnLine1: "このサブスクリプションの自動更新を有効にしたい場合は、{link}までご連絡ください。",
    supportLink: "@:Brand.webroot{''}のサポート",
  },
  WebrootAutoRenewOffSurveyComponent: {
    autoRenewOffSurveyH2: "自動更新を無効にする理由を教えてください。",
    reasonTurnOff: "その理由について最も当てはまる回答を選択してください。",
    r301: "更新する予定だが手動で行いたい。",
    r302: "更新をする予定ですが、支払い方法の変更を希望します。",
    r304: "サービスの費用が高すぎるため、更新をする予定はありません。",
    r312: "別のブランド・ベンダーに変更するため、更新する予定はありません。",
    r313: "無料ソリューションに変更するため、更新する予定はありません。",
    r306: "その他(具体的にご記入ください):",
  },
  WebrootDownloadInstructions: {
    downloadInstructions: "ダウンロード手順",
    toInstall: "インストールするには:",
    WIFIp1:
      "この製品は専用のアカウントとパスワードが必要になり(<strong>@:Brand.WebrootSecureVPN{''}</strong>アカウント)、<strong>@:Brand.myAccount{''}</strong>とは別になります。",
    WIFIol1btn: "あなたの @:Brand.WebrootSecureVPN{''}アカウントを作成する。",
    WIFIol1: "すでにアカウントをお持ちの場合は、ステップ３へお進み下さい。",
    WIFIol2:
      "確認メールを受信します。メールに記載されているリンクをクリックして、あなたの @:Brand.WebrootSecureVPN{''}アカウントをアクティブ化ください。",
    WIFIol3btn: "ダウンロードページ",
    WIFIol3: "アプリをダウンロードするには @:Brand.myAccount{''}の{downloadsBtn}へ移動下さい。",
    forRenewals:
      "更新について: あなたの @:Brand.webroot{''}保護はインストールされたコンピュータやモバイルデバイスで自動的に延長されますので、更新プログラムをダウンロードやインストールする必要はありません。アプリが自動的に更新されるまで最大24時間お待ちください。",
    WIFIp3:
      "アプリにログインする際には、 <strong>@:Brand.WebrootSecureVPN{''}</strong>アカウントの資格情報を使用し、アカウント管理ウェブサイトにログインする際には、 <strong>@:Brand.myAccount{''}</strong>の資格情報を使用してください。",
    webrootForChromebook: "@:Brand.webroot{''} for Chromebook" /* this string should not be translated */,
    WSCBol1: "保護したいChromebookで{myAccount}へ移動する。",
    WSCBol2: "@:Brand.webroot{''} for Chromebook をインストールする。",
    WSCBol3: "以下のキーコードを使用して、アプリ内でモバイルセキュリティアカウントを作成する:",
    keycodeRetrieving: "キーコードを取得しています...",
    keycodeSuccess: "あなたのキーコードは:",
    keycodeFailed:
      "あなたのキーコードはメールにて送信されます、また @:Brand.myAccount{''}のダウンロードタブでも確認できます。",
    WSCBp1:
      "この製品は専用のアカウントとパスワードが必要になり (ウェブルート モバイルセキュリティ アカウント)、@:Brand.myAccount{''}とは別になります。",
    WSCBp2a:
      "あなたの @:Brand.WebrootMS{''}アカウントの資格情報を使用し @:Brand.WebrootChromebook{''}アプリへログインしてください。",
    WSCBp2b:
      "あなたの @:Brand.myAccount{''}アカウントの資格情報を使用し、アカウント管理ウェブサイトへログインしてください。",
    WSCBp4:
      "また、Google Chrome™ 用の {downloadApp}をダウンロードしてください。クリックする前に高リスクのウェブサイトに関する警告を受け取り、自動的に悪質なサイトをブロックします。",
    WPCOol1: "@:Brand.WebrootPcOptimizer{''} アプリをPCにインストールしてください。",
    WPCOol2: "以下のキーコードを使用して、アプリでアカウントを認証してください:",
    allOthersOl1: "保護したいコンピュータやモバイルデバイスで {myAccount}へ移動してください。",
    allOthersOl2:
      "<strong> @:Brand.myAccount{''}</strong>をセットアップ後、ログインをして <strong>Downloads</strong>タブへ移動してください",
    allOthersOl3: "インストール中に、キーコードの入力を求められます。",
    allOthersP1a: "すでにWebroot Protectionがインストールされており、このキーコードを適用したい場合",
    allOthersP1b: "詳しい手順については、こちらをクリックしてください。",
  },
  WebrootKeyCodes: {
    keyCodesOnlyLetterAndNumbers: "キーコードに含めることができるのは文字と数字(ハイフンを除く)のみです",
  },
  WebrootProductsInfo: {
    li: "この支払い方法はあなたの {sku} (キーコード {keycode})に適用されます。",
  },
  WebrootSupportSectionComponent: {
    webrootSecureAnyWhere: "@:Brand.webrootProtection{''}プロテクション",
    webThreatShield: "@:Brand.WebrootWTS{''}ブラウザ拡張機能",
    mobileSecurity: "@:Brand.WebrootMS{''}アプリ",
    searchKnowledge: "ナレッジベースの記事を検索",
    popularSearches: "人気の検索",
    chromebookSecurity: "Chromebookセキュリティアプリ",
    wifiSecurity: "@:Brand.WIFI{''}",
    search: "検索",
    findHelpWebrootText: "@:Brand.webroot{''}のナレッジベースの検索",
    webrootCustomerSupport: "@:Brand.webroot{''}のカスタマーサポート",
    webrootSupportText: "@:Brand.webroot{''}のカスタマーサポートにアクセス",
    installPC: "インストール(PC)",
    locateYourKeycode: "キーコードの特定",
    uninstallPC: "アンインストール(PC)",
  },
  WindowsArmInfo: {
    headerH1: "お使いのコンピューターにx64/x86プロセッサまたはARMプロセッサが搭載されているかを確認する方法",
    howToTellWhich: "お使いのコンピューターにx64/x86プロセッサまたはARMプロセッサが搭載されているかを確認する方法",
    win11olLi1: "<strong>スタート</strong>ボタンをクリックし、<strong>設定</strong>をクリックします。",
    win11olLi2: "左側のパネルで、<strong>システム</strong>をクリックします。",
    win11olLi3: "右側のパネルで、必要に応じてスクロールダウンし、<strong>バージョン情報</strong>をクリックします。",
    win10olLi1: "<strong>スタート</strong>ボタンをクリックし、<strong>設定</strong>をクリックします。",
    win10olLi2: "<strong>システム</strong>をクリックします。",
    win10olLi3: "左側のパネルで、必要に応じてスクロールダウンし、<strong>バージョン情報</strong>をクリックします。",
  },
  WindowsMoveSubscriptionComponent: {
    headerH1: "サブスクリプションを移動",
    moveNicknameLabel: "移動元のコンピューター: ",
    moveNicknameInstruction:
      "@:Brand.carbonite{''}サブスクリプションの移動は、新しいコンピューターで行う必要があります。@:Brand.carbonite{''}のサブスクリプションはコンピューター単位であるため、一度に使用できるサブスクリプションは、1台のコンピューターにつき1つのみです。",
    moveNicknameOptions: "オプションを選択してください: ",
    moveAndBackup: "サブスクリプションと{backup}を移動",
    startNewBackup: "新しいバックアップを開始",
    moveAndNote:
      "{note}: コンピューターを移動して新しいバックアップを開始した場合、以前のバックアップには引き続き30日間アクセスできます。",
    note: "注意",
    moveAndTransfer: "サブスクリプションと{transfer}を移動",
    transferFiles: "ファイルを移動",
    next: "次へ",
    footerButtonCancel: "キャンセル",
  },
  DownloadRestrictedFilesComponent: {
    header1: "ファイルをダウンロードするために本人確認を行ってください",
    header2: "ファイルのダウンロードができません",
    restrictedFilesLineOne: "選択されたファイルには機密データが含まれている可能性があります。",
    restrictedFilesLineTwo: "セキュリティ上の理由から、アカウントを確認するために追加の情報が必要です。",
    restrictedFilesLineThree: "ご本人確認のために、{device}に送信された5桁のセキュリティコードを入力してください。",
    restrictedFilesLineFour: "このコードの有効期間は、送信されてから1時間です。",
    securityCode: "セキュリティコード",
    trustDevice: "このデバイスを信頼する",
    restrictedFilesLineFive: "セキュリティコードが届かない場合",
    restrictedFilesLineSix: "電話が使えませんか？",
    sendAgain: "再送信",
    callMe: "私に電話をしてください",
    emailCode: "{email} にコードを送信",
    submitCode: "コードを送信",
    downloadFromClientOrCourier: "ファイルをCarboniteクライアントからダウンロードするか、配送回収を依頼してください。",
    infoTooltip: "次の30日間はコードの入力を求められません。",
    SecurityCodeSentToEmail: "セキュリティコードがメールに送信されました",
    resendTimerText: "{seconds}後に再送信",
    downloadWithoutRestrictedFiles: "機密データが含まれていないファイルをダウンロードする",
    proceed: "続行",
  },
};
