import { ref, computed } from "vue";
import {
  DEFAULT_TERM_LENGTH_MONTHS,
  LAST_API_FETCH_INTERVAL,
  DEFAULT_MAX_QUANTITY,
  FLAGS,
  DEFAULT_UPGRADE_GROUP,
  DEFAULT_QUANTITY,
  WEBROOT_SECURE_VPN,
  selectPlanRouteName,
} from "@/define";
import { logEvent } from "@/common/logger";
import { defineStore } from "pinia";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import {
  IProductCatalog,
  IProductCatalogResponse,
  IRatePlan,
  ICartItem,
  IDefaultProduct,
  IShoppingCart,
  IAdjustment,
  IPaymentMethod,
  IUserAddress,
  ILicenseKeyInfo,
  IPurchaseKeycode,
  ISubscription,
} from "@/common/api/unifiedPortal/interfaces";
import {
  pageStates,
  adjustments,
  layoutStates,
  paymentTypes,
  openTextBrands,
  purchaseErrors,
  webrootTrialUnits,
} from "@/components/Buy/BuyEnums";
import { sortArrayOfObjects } from "@/components/Buy/commonFn";
import { unifiedApi } from "@/common/index";
import { ProductCatalogType, ProductCatalogBrand } from "@/globalEnums";
import { useUserStore } from "@/stores/user";
import {
  IBeginShoppingCartRequest,
  IPurchaseError,
  IRecentPurchase,
  IShowShippingFormErrors,
} from "@/components/Buy/Interfaces";
import { IAdjustmentDisplay } from "@/components/Buy/Interfaces";
import {
  handlePurchaseError,
  redirectToUnifiedPortalSmbBuyflow,
  setShippingAddressFromCC,
} from "@/components/Buy/BuyHelper";
import { useRoute } from "vue-router";
import { cartUpdate, googleTagCartEvents } from "@/common/googleTagEvents";
import {
  webrootCDSku,
  webrootOnlyAllstateSkus,
  webrootSkusThatAlreadyHaveIdentity,
  webrootSkusWithIdentity,
  webrootSkusThatAreRenewOnly,
  webrootCDLP,
} from "@/common/webrootProductName";
import router from "@/routes";
import { useNotificationsStore } from "@/stores/notifications";

export const useBuyFlowStore = defineStore("buyflow", () => {
  const plans = ref<IProductCatalog[]>([]);
  const lastRequestTime = ref<number>(0);
  const isRunning = ref<boolean>(false);
  const cart = ref<IShoppingCart>();
  const pageState = ref<pageStates>();
  const layoutState = ref<layoutStates>(layoutStates.purchase);
  const paymentType = ref<string>(paymentTypes.CreditCard);
  const mainProductSku = computed(() => {
    return cart.value?.items[0].sku;
  });
  const changingFromMonthly = ref<boolean>(false); //used for buyflow 'checkout' conditions when changing plan
  const recommendedPlanSku = ref<string>();
  const computerName = ref<string>();
  const showProcessingOrderMessage = ref<boolean>(false);
  const processingOrderMessage = ref<string>("");
  const checkSalesTax = ref<boolean>(false);
  const userSelectionDirtyFlag = ref<boolean>(false);
  const isCalculatingCart = ref<boolean>(false);
  const userGuid = ref<string>(""); //This should only be populated when it comes from a query string. This indicates if the Account is in the unauthenticated flow or not
  const triggerStateChange = ref<boolean>(false);
  const hasAlreadyHaveAllstateError = ref<boolean>(false);
  const hasOwnedByBestBuyError = ref<boolean>(false);
  const hasWebrootPremium = ref<boolean>(false);
  const hasAllStateNonUSPaypalShippingAddress = ref<boolean>(false);
  const currentEmail = ref<string>();
  const creditCards = ref<IPaymentMethod[]>([]);
  const lastCartItemRemoved = ref<ICartItem | null>();
  const cartItemsSnapshot = ref<ICartItem[]>([]);
  const referralUrl = ref("");
  const recentPurchase = ref<IRecentPurchase>();
  const userStore = useUserStore();
  const shippingMatchesBilling = ref(true);
  //This is used to tell API which shopping cart to save
  const openTextBrand = ref(openTextBrands.Carbonite);
  const currentBrand = computed(() => {
    return plans.value?.find(p => p.sku === cart.value?.items[0]?.sku)?.brand ?? openTextBrand.value;
  });
  const planetOpenTextDiscountApplied = ref(false);
  const planetOpenTextDiscountMismatch = ref(false);

  const licenseKey = ref<string>("");
  const licenseKeyInfo = ref<ILicenseKeyInfo>();
  const PurchaseKeycodes = ref<IPurchaseKeycode[]>([]);

  //Shipping Address should start blank
  const shippingAddress = ref<IUserAddress>({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const editShippingAddress = ref<IUserAddress>({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const showShippingFormErrors = ref<IShowShippingFormErrors>({
    firstName: [],
    lastName: [],
    address1: [],
    country: [],
    state: [],
    city: [],
    zipCode: [],
  });

  const showBillingFormErrors = ref<IShowShippingFormErrors>({
    firstName: [],
    lastName: [],
    address1: [],
    country: [],
    state: [],
    city: [],
    zipCode: [],
  });

  function resetShippingValues() {
    shippingAddress.value = {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      country: "",
    } as IUserAddress;
  }

  //Sales Tax will be added manually since the UX calls it out differently
  const adjustmentsConfig = {
    BonusMonthsPromo: {
      sortOrder: 1,
      textClass: "success-text",
      amountText: "--",
    },
    Discounts: {
      sortOrder: 2,
      textClass: "success-text",
    },
    PromoCodeAmt: {
      sortOrder: 3,
      textClass: "success-text",
    },
    PromoCodePct: {
      sortOrder: 3,
      textClass: "success-text",
    },
    PriorSubscriptionCredit: {
      sortOrder: 4,
      textClass: "success-text",
    },
    UsedCreditBalance: {
      sortOrder: 5,
    },
    OneTimeAmt: {
      sortOrder: 6,
    },
    TrialDaysRemaining: {
      sortOrder: 7,
      textClass: "success-text",
      amountText: "--",
    },
    LicenseDaysRemaining: {
      sortOrder: 8,
      textClass: "success-text",
      amountText: "--",
    },
    Tax: {
      sortOrder: 100, //Sales Tax should always be last
      textClass: "row-border-bottom",
    },
  };

  // check if cart has webroot premium, webroot premium family, Webroot Total Protection, or Webroot Total Protection Family
  const hasWebrootPremiumInCart = computed(() => {
    return cart.value?.items.some(item => webrootSkusThatAlreadyHaveIdentity.includes(item.sku ?? "")) ?? false;
  });

  const isNewUser = computed(() => userStore.currentUser && !userStore.currentUser?.subscriberInfo?.hasPasswordSet);

  const route = useRoute();
  const notificationsStore = useNotificationsStore();

  const isNewUserBuyFlow = computed(() => {
    return (
      isNewUser.value &&
      ((route.name == "buy" &&
        (route.query.step === pageStates.payment ||
          route.query.step === pageStates.reviewOrder ||
          route.query.step === pageStates.email)) ||
        route.name === pageStates.orderConfirmation)
    );
  });

  const hasShippableItem = computed(() => {
    if (cart.value) {
      return cart.value.items.some(item => {
        const cartItemPlan = plans.value.find(i => i.sku === item.sku);
        if (cartItemPlan?.requiresShipping) {
          return true;
        }

        if (item.bundledItems) {
          return item.bundledItems.some(bundleItem => {
            const cartItemPlan = plans.value.find(i => i.sku === bundleItem.sku);
            if (cartItemPlan?.requiresShipping) {
              return true;
            }
            return false;
          });
        }
        return false;
      });
    }
    return false;
  });

  //check if cart has webroot with all state
  const hasAllStateInCart = computed(() => {
    if (cart.value) {
      const result = cart.value.items.some(item => webrootSkusWithIdentity.includes(item.sku ?? ""));
      return result;
    }
    return false;
  });

  //check if cart has WW or ADP
  const hasWebrootRenewOnly = computed(() => {
    if (cart.value) {
      const result = cart.value.items.some(item => webrootSkusThatAreRenewOnly.includes(item.sku ?? ""));
      return result;
    }
    return false;
  });

  //check if cart has WTP or WTPF
  const hasOnlyCDLPinCart = computed(() => {
    if (cart.value) {
      const result = cart.value.items.every(item => webrootCDLP.includes(item.sku ?? ""));
      return result;
    }
    return false;
  });

  //check if all items in the cart are Allstate
  const hasOnlyAllStateOrVPNInCart = computed(() => {
    if (cart.value) {
      const skus = webrootOnlyAllstateSkus;
      skus.push(WEBROOT_SECURE_VPN);

      const result = cart.value.items.every(item => skus.includes(item.sku ?? ""));
      return result;
    }
    return false;
  });

  //check if only Allstate is in the cart, not including Webroot Secure VPN
  const hasOnlyAllStateInCart = computed(() => {
    if (cart.value) {
      const skus = webrootOnlyAllstateSkus;

      const result = cart.value.items.every(item => skus.includes(item.sku ?? ""));
      return result;
    }
    return false;
  });

  const hasCartItems = computed(() => {
    if (cart.value) {
      return cart?.value.items.length > 0;
    }
    return false;
  });

  const isPayPalPayment = computed(() => {
    return paymentType.value == paymentTypes.PayPal;
  });

  const selectedPlan = computed<ICartItem | null>(() => {
    return cart.value?.items ? cart.value?.items[0] : null;
  });

  //if we are a monthly consumer selecting our existing plan
  const disableCheckout = computed(() => {
    return (
      selectedTerm.value?.months === 1 && changingFromMonthly.value && mainProductSku.value === selectedPlan.value?.sku
    );
  });

  const isRenewOrUpgrade = computed(() => {
    return (
      cart.value?.items.some(l => l.computerId > 0) ||
      (cart.value?.items.some(l => l.licenseKey) && !licenseKeyInfo.value?.isBestBuyS2Recapture)
    );
  });

  const subscriptionTermsForSelectedPlan = computed<IRatePlan[]>(() => {
    if (selectedPlan.value) {
      const subscriptionInfo = plans.value.find(l => l.sku === selectedPlan.value?.sku);
      if (subscriptionInfo?.ratePlans) {
        return sortArrayOfObjects(subscriptionInfo.ratePlans, "months", "ascending");
      }
    }

    return [];
  });

  const selectedTerm = computed<IRatePlan | null>(() => {
    return (
      plans.value
        .find(l => l.sku === selectedPlan.value?.sku)
        ?.ratePlans.find(l => l.ratePlanId === selectedPlan.value?.ratePlanId) ?? null
    );
  });

  const creditCard = computed(() => {
    return creditCards.value.find(c => c.id === cart.value?.paymentMethodId);
  });

  const hasUsOnly = computed(() => {
    return cartOrBundleHas("isUsOnly", "true");
  });

  const isFlatRateUpgrade = computed(() => {
    if (selectedPlan.value) {
      return getItemSubscription(selectedPlan.value?.sku, selectedPlan.value?.ratePlanId)?.isFlatRateUpgrade || false;
    }

    return false;
  });

  function cartOrBundleHas(key: string, value: string, matchCase?: boolean): boolean {
    const match = (n: string) => (matchCase ? n : n.toLowerCase());
    const convertToString = (n: unknown) => (n += "");
    const filterExpression = (sku: string): boolean => {
      const cartItemPlan = plans.value.find(i => i.sku === sku);

      if (cartItemPlan?.[key]) {
        return match(convertToString(cartItemPlan?.[key])) === match(value);
      }
      return false;
    };

    if (cart.value) {
      return cart.value?.items.some(item => {
        if (filterExpression(item.sku)) {
          return true;
        }
        if (item.bundledItems) {
          return item.bundledItems.some(bundleItem => {
            return filterExpression(bundleItem.sku);
          });
        }
        return false;
      });
    }
    return false;
  }

  function getTermFromItem(item: ICartItem) {
    return plans.value.find(l => l.sku === item.sku)?.ratePlans.find(l => l.ratePlanId === item.ratePlanId) ?? null;
  }

  function getMonthsForItem(item: ICartItem) {
    const term = getTermFromItem(item);

    //If a term is found return the months
    if (term) {
      return term.months;
    }

    //If no term found return 0
    return 0;
  }

  async function populatePlans(catId = 0, isRenewOrUpgrade = false, isTrial = false, force = false) {
    try {
      // Waits for the current call to finish before trying and get aborted (in case a simultaneous call)
      while (isRunning.value) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const currentTime = new Date().getTime();

      if (currentTime - lastRequestTime.value >= LAST_API_FETCH_INTERVAL || force) {
        lastRequestTime.value = new Date().getTime();
        isRunning.value = true;
        //Ignore CatId for Renew/Upgrade
        const response: IProductCatalogResponse = (
          await unifiedApi.getProductCatalog(ProductCatalogType.Consumer, !isTrial && isRenewOrUpgrade ? 0 : catId)
        ).data;
        if (response) {
          plans.value = response.catalogProducts;

          //filter to Carbonite brand if Webroot is disabled
          if (FLAGS.ENABLE_BUYFLOW_WEBROOT === "false") {
            plans.value = response.catalogProducts.filter(
              n => n.brand.toLowerCase() === ProductCatalogBrand.Carbonite.toLowerCase()
            );
          }

          console.log("plans", plans.value);
          isRunning.value = false;
          logEvent("BuyFlow select plan state updated", "buyFlow store");
        }
      }
    } catch (err) {
      //redirect to error page
      handleApiError(err as AxiosError, false);
    }
  }

  //If the user has updated data we want to refresh the store next time they come to the page
  //This is a lot easier than trying to dig through 2 levels of arrays to update the store for the next time they come to the page
  function forceRefresh() {
    lastRequestTime.value = new Date().getTime() - LAST_API_FETCH_INTERVAL;
    logEvent("Force buyflow store refresh");
  }

  function getItemPlan(sku: string) {
    return plans.value.find(l => l.sku === sku);
  }

  function getItemSubscription(sku: string, ratePlanId: string) {
    return getItemPlan(sku)?.ratePlans.find(l => l.ratePlanId === ratePlanId);
  }

  //This method should only deal with setting the shopping cart. Not display items
  function beginShoppingCart(request: IBeginShoppingCartRequest) {
    const subscriptionInfo = getItemSubscription(request.sku, request.ratePlanId);
    if (subscriptionInfo) {
      const quantityFixed = request.quantity ?? 1;
      const subNumbers: string[] = [];
      if (request.subscriptionNumber) {
        subNumbers.push(request.subscriptionNumber);
      }

      const skuInfo = getItemPlan(request.sku);
      const bundleItems: ICartItem[] = [];
      if (skuInfo && !subscriptionInfo.isFlatRateUpgrade && !request.licenseKey) {
        if (skuInfo.candyRackItems && skuInfo.candyRackItems.length > 0 && !planetOpenTextDiscountApplied.value) {
          for (const candyRackSku of skuInfo.candyRackItems) {
            const candyRackItem = getItemPlan(candyRackSku);
            if (candyRackItem && candyRackItem.candyRackSettings && candyRackItem.candyRackSettings.bundleWithCart) {
              const bundleItem = {
                sku: candyRackItem.sku,
                quantity: 1,
                ratePlanId: candyRackItem.ratePlans[0].ratePlanId, //Just grabbing the first one as the only bundle item is the CD atm
              } as ICartItem;

              bundleItems.push(bundleItem);
            }
          }
        }
      }

      const cartItem = {
        computerId: request.deviceId || 0,
        subscriptionNumbers: subNumbers,
        autoRenew: true,
        sku: request.sku,
        ratePlanId: request.ratePlanId,
        quantity: quantityFixed,
        bundledItems: bundleItems,
        licenseKey: request.licenseKey || "",
        total: subscriptionInfo.newSubscriptionPrice * quantityFixed, //Need to manually adjust price for the cases where we don't have a user and can't set a cart in the DB yet
      } as ICartItem;

      if (request.deviceId) {
        cartItem.computerId = request.deviceId;
      }

      cart.value = {
        items: [cartItem],
        brand: plans.value?.find(p => p.sku === cartItem.sku)?.brand || openTextBrand.value,
      } as IShoppingCart;
      //updateShoppingCartTotal();
    }
  }

  //TODO: This should be change for story ZUOR-40985
  function setRecommendSkuFromCurrent(currentSku?: string) {
    recommendedPlanSku.value = getRecommendedSku(currentSku);
  }

  function getRecommendedSku(currentSku?: string, selectedPlanSku?: string) {
    const upgradeGroup = plans.value.find(m => m.sku === currentSku)?.upgradeGroup || DEFAULT_UPGRADE_GROUP;

    const currentUpgradeOrder =
      plans.value.find(n => n.sku === currentSku && n.upgradeGroup === upgradeGroup)?.upgradeOrder ?? 1;
    if (currentSku) {
      return plans.value.find(m => m.upgradeOrder === currentUpgradeOrder + 1 && m.upgradeGroup === upgradeGroup)?.sku;
    }

    const selectedPlanUpgradeOrder =
      plans.value.find(n => n.sku === selectedPlanSku && n.upgradeGroup === upgradeGroup)?.upgradeOrder ?? 1;

    return selectedPlanUpgradeOrder > 2
      ? ""
      : plans.value.find(m => m.upgradeOrder === 2 && m.upgradeGroup === upgradeGroup)?.sku;
  }

  //Use this method to replace an existing Safe Product in the store with a new one
  //Will also update the ratePlanId for the subscription
  function setNewItemInShoppingCart(sku: string) {
    userSelectionDirtyFlag.value = true;
    const newProduct = plans.value.find(l => l.sku === sku);

    if (newProduct) {
      const currentSku = selectedPlan.value?.sku;
      const currentRatePlanId = selectedPlan.value?.ratePlanId;
      const oldSubscriptionMonths = plans.value
        .find(l => l.sku === currentSku)
        ?.ratePlans.find(l => l.ratePlanId === currentRatePlanId)?.months;
      const newSubscription = newProduct.ratePlans.find(l => l.months === oldSubscriptionMonths);

      if (cart.value?.items) {
        //Get the first item for now since we are not supporting multi cart items
        const firstItem = cart.value.items[0];
        firstItem.sku = newProduct.sku;
        firstItem.ratePlanId = newSubscription?.ratePlanId ?? "";

        //const subscriptionInfo = getItemSubscription(firstItem.sku, newSubscription?.ratePlanId ?? "");
        //Calculate the new subtotal for now. Will need to get it from the API later
        // if (subscriptionInfo) {
        //   firstItem.total = subscriptionInfo?.price * firstItem.quantity;
        // }
      } else {
        const ratePlanId = getRatePlanIdBySku(sku, DEFAULT_TERM_LENGTH_MONTHS);
        //There is no cart yet
        if (!cart.value) {
          const request = {
            sku: sku,
            ratePlanId: ratePlanId,
          } as IBeginShoppingCartRequest;
          beginShoppingCart(request);
          //There is a cart but there are no cart items
        } else if (cart.value && cart.value.items.length === 0) {
          const newItem = {
            sku: sku,
            ratePlanId: ratePlanId,
            quantity: 1,
          } as ICartItem;

          cart.value.items.push(newItem);
        }
      }
      updateShoppingCartTotal(false, false, googleTagCartEvents.add);
    }
  }

  function selectTerm(ratePlanId: string) {
    if (cart.value?.items) {
      //Get the first item for now since we are not supporting multi cart items
      const firstItem = cart.value.items[0];
      //const subscriptionInfo = getItemSubscription(firstItem.sku, ratePlanId);
      //Calculate the new subtotal for now. Will need to get it from the API later
      // if (subscriptionInfo) {
      //   firstItem.total = subscriptionInfo?.price * firstItem.quantity;
      // }
      firstItem.ratePlanId = ratePlanId;
      updateShoppingCartTotal(false, false, googleTagCartEvents.change);
    }
  }

  //This will be going away once the API is hooked up. The API will handle all calculations
  async function updateShoppingCartTotal(
    redirectToPaymentPage = false,
    bubbleUpError = false,
    googleTagCartEvent: googleTagCartEvents | undefined = undefined
  ) {
    logEvent("updateShoppingCartTotal", "BuyFlow", cart.value);
    isCalculatingCart.value = true;
    if (cart.value && cart.value.items) {
      try {
        cart.value.userGuid = userGuid.value;
        //cart.value.total = cart.value.items.map(item => item.total).reduce((prev, curr) => prev + curr, 0);
        if (hasShippableItem.value && shippingMatchesBilling.value && creditCard.value?.address) {
          setShippingAddressFromCC(creditCard.value);
        }

        if (!cart.value.brand) {
          cart.value.brand = currentBrand.value || openTextBrand.value;
        }

        const response = (await unifiedApi.saveShoppingCart(cart.value)).data;
        if (response) {
          cartUpdate(response, googleTagCartEvent === undefined ? pageState.value : googleTagCartEvent);
          const user = useUserStore();
          response.userGuid = response.userGuid ?? user.currentUser?.userGuid;
          //Set the userGuid from the cart to make sure we have it for licenseKey unauthenticated flow
          userGuid.value = response.userGuid;
          cart.value = response;
          if (cart.value) {
            cart.value.userGuid = userGuid.value;
          }
        }
        hasAlreadyHaveAllstateError.value = false;
      } catch (error) {
        isCalculatingCart.value = false;
        const err = error as AxiosError;
        const response = err?.response?.data as IPurchaseError;
        logEvent("cart error", "", response);

        //Push some flat rate upgrade errors to special pages
        if (response && isFlatRateUpgrade.value) {
          if (
            response?.message === purchaseErrors.InvalidUpgradePath ||
            response?.message === purchaseErrors.CartItemRatePlanIdInvalid
          ) {
            router.push("/buy/error/mismatch");
            return;
          }

          if (response?.message === purchaseErrors.SubscriptionExpired) {
            router.push("/buy/error/expired");
            return;
          }

          if (response?.message === purchaseErrors.InvalidRemainingDays) {
            router.push("/buy/error/invalid");
            return;
          }
        }

        //Device has been purged error
        if (response && response?.message === purchaseErrors.NotEligibleForRenewal) {
          router.push("/buy/error/unrecoverable");
          return;
        }

        //Keycode is owned by best buy
        if (response && response?.message === purchaseErrors.OwnedByBestBuy) {
          hasOwnedByBestBuyError.value = true;
          clearCart(openTextBrands.Webroot, true);
          return;
        }

        // redirect an SMB user to UP buyflow
        if (response && response?.message === purchaseErrors.SmbUserIneligible) {
          logEvent("redirecting to SMB");
          redirectToUnifiedPortalSmbBuyflow();
          return;
        }

        // Display Allstate error - will only work if the categoryLicenseCodes are in Hoth in AlreadyHaveOneAllstateSku
        if (response && response?.message === purchaseErrors.AlreadyHaveOneAllstateSku) {
          notificationsStore.addNotification({ type: "AllstateBuyflowLimit", dismissible: false });
          hasAlreadyHaveAllstateError.value = true;
          return;
        }

        //Show error for retail keycodes that are outside of 30 days until expiration
        if (response && response?.message === purchaseErrors.UpgradeOnRetailRenewalNotAllowed) {
          notificationsStore.addNotification({ type: "UpgradeOnRetailRenewalNotAllowed", dismissible: false });
          return;
        }

        //Redirect if user is under maintenance
        if (response && response?.message === purchaseErrors.UnderMaintenance) {
          router.push("/underMaintenance");
          return;
        }

        if (redirectToPaymentPage) {
          logEvent("redirectToPaymentPage", "", {
            statusCode: err?.response?.status,
            response: response,
            bubbleUpError: bubbleUpError,
          });
          const statusCode = err?.response?.status;

          if (response) {
            handlePurchaseError(response.message, statusCode);
            if (bubbleUpError) {
              throw err;
            }
          }
        } else if (bubbleUpError) {
          throw err;
        } else {
          handleApiError(err as AxiosError, true);
        }
      }
    }
    isCalculatingCart.value = false;
  }

  function increaseQuantity(product: ICartItem) {
    const orderItem = cart.value?.items?.find(l => l.sku === product.sku && l.ratePlanId === product.ratePlanId);
    if (orderItem) {
      orderItem.quantity++;
      //orderItem.total = calculateSubscriptionTotal(product);
      updateShoppingCartTotal(false, false, googleTagCartEvents.change);
    }
  }

  function decreaseQuantity(product: ICartItem) {
    const orderItem = cart.value?.items?.find(l => l.sku === product.sku && l.ratePlanId === product.ratePlanId);
    if (orderItem) {
      orderItem.quantity--;
      //orderItem.total = calculateSubscriptionTotal(product);
      updateShoppingCartTotal(false, false, googleTagCartEvents.change);
    }
  }

  // function calculateSubscriptionTotal(product: ICartItem) {
  //   const subscriptionInfo = getItemSubscription(product.sku, product.ratePlanId);
  //   if (subscriptionInfo) {
  //     return subscriptionInfo.price * product.quantity;
  //   }

  //   return 0;
  // }

  // function getProductFromOrder(product: IOrderProduct) {
  //   return order.value?.products?.find(l => l.sku === product.sku && l.ratePlanId === product.ratePlanId);
  // }

  function setAutoRenew(isAutoRenew: boolean) {
    if (cart.value?.items) {
      for (const item of cart.value.items) {
        item.autoRenew = isAutoRenew;
      }
    }
  }

  // This may need to be updated after having the final ShoppingCart object
  function updateCreditCard(paymentMethodId: string) {
    if (cart.value) cart.value.paymentMethodId = paymentMethodId;
  }

  //TODO: add filter when product catalog is updated
  function getBasePlan(): IDefaultProduct {
    //const plansByType = plans.value.filter(l => l.)
    const basicPlan = plans.value.find(l => l.sku === "PersonalPlus");
    const oneYearRatePlan = basicPlan?.ratePlans.find(l => l.months === 12);

    return {
      sku: basicPlan?.sku || "",
      ratePlanId: oneYearRatePlan?.ratePlanId || "",
    };
  }

  function getRatePlanIdBySku(sku: string, months: number, includedUnits = 1): string {
    if (isSafeSku(sku)) {
      return plans.value.find(p => p.sku === sku)?.ratePlans.find(r => r.months === months)?.ratePlanId ?? "";
    }

    const ratePlanId =
      plans.value
        .find(p => p.sku === sku)
        ?.ratePlans.find(
          r => r.months === months && (r.includedUnits === includedUnits || r.includedIdentities === includedUnits)
        )?.ratePlanId ?? "";

    if (ratePlanId) {
      return ratePlanId;
    }

    //If We couldn't find the rate plan try to find it for the lowest number of units or identities.
    //This is to fix ZUOR-48871 where just passing an AllState sku alone was causing an empty cart.
    const plan = plans.value.find(p => p.sku === sku);

    //Sort the units and identities so the lowest value is first and use that.
    const lowestUnits =
      plan?.ratePlans.sort((a, b) => (a.includedUnits ?? 1) - (b.includedUnits ?? 1))[0].includedUnits || 1;
    const lowestIdentities =
      plan?.ratePlans.sort((a, b) => (a.includedIdentities ?? 1) - (b.includedIdentities ?? 1))[0].includedIdentities ||
      1;

    return (
      plans.value
        .find(p => p.sku === sku)
        ?.ratePlans.find(
          r => r.months === months && (r.includedUnits === lowestUnits || r.includedIdentities === lowestIdentities)
        )?.ratePlanId ?? ""
    );
  }

  //Find the rate plan for the trial based on the sku / included units
  function getRatePlanIdForTrialSubscription(sub: ISubscription) {
    return getRatePlanIdBySku(
      sub?.sku ?? "",
      DEFAULT_TERM_LENGTH_MONTHS,
      webrootTrialUnits[sub.licenseCategoryName ?? ""]
    );
  }

  function isSkuValid(sku: string) {
    return plans.value.some(s => s.sku === sku);
  }

  function isWebrootSku(sku: string) {
    return plans.value.some(l => l.sku === sku && l.brand === "Webroot") || sku === "Webroot"; // Added to keep support to Webroot Standalone by Carbonite, should be removed
  }

  function isSafeSku(sku: string) {
    return plans.value.some(l => l.sku === sku && l.brand === "Carbonite");
  }

  function isAllStateOrVpn() {
    // does the cart have allstate or does the cart have vpn
    const hasAllstateInCart = cart.value?.items?.some(l => webrootOnlyAllstateSkus.some(m => m === l.sku));
    const hasVPNInCart = cart.value?.items?.some(l => l.sku === WEBROOT_SECURE_VPN);
    // does the cart have a mixture of things
    const hasNonAllStateOrVpnInCart = cart.value?.items?.filter(
      l => l.sku !== WEBROOT_SECURE_VPN && !webrootOnlyAllstateSkus.some(m => m === l.sku)
    );
    if (hasNonAllStateOrVpnInCart && hasNonAllStateOrVpnInCart.length > 0) {
      return false;
    }
    return hasAllstateInCart || hasVPNInCart;
  }

  function getMaxQuantityBySku(sku: string): number {
    if (sku && isSafeSku(sku)) {
      return DEFAULT_MAX_QUANTITY;
    } else {
      return DEFAULT_QUANTITY;
    }
  }

  //Find the sku based on a given rate plan id
  function getSkuByRatePlan(ratePlanId: string) {
    let sku = "";

    for (const plan of plans.value) {
      const ratePlan = plan.ratePlans.find(l => l.ratePlanId === ratePlanId);
      if (ratePlan) {
        sku = plan.sku;
        break;
      }
    }

    return sku;
  }

  function hasWebrootCartItems() {
    return cart.value?.items?.some(l => isWebrootSku(l.sku));
  }

  function getPricePerYear(sku: string, months: number): number {
    const current = plans.value.find(l => l.sku === sku);
    const syb = current?.ratePlans.find(n => n.months === months);
    const price = isRenewOrUpgrade.value ? syb?.catalogPrice : syb?.newSubscriptionPrice;
    let perYearPrice = 0;
    if (months == 12) perYearPrice = price ?? 0;
    else if (months == 24) perYearPrice = (price ?? 0) / 2;
    else if (months == 36) perYearPrice = (price ?? 0) / 3;
    else if (months == 1) perYearPrice = (price ?? 0) * 12;
    return perYearPrice;
  }

  function getCatalogItemBySku(sku: string) {
    return plans.value.find(l => l.sku === sku);
  }

  function getAdjustmentsToDisplay() {
    if (!cart.value?.adjustments) return [];
    const adjustmentsToDisplay =
      cart.value?.adjustments
        .filter(a => !a.type.includes("Carbonite")) // Remove "Carbonite" adjustment
        ?.filter(a => a.type !== adjustments.RemainingCreditBalance) ?? []; // Filter out RemainingCreditBalance for now -- we don't have UX for this

    // this if is a temp fix -- won't be necessary when Hoth sends PriorSubscriptionCredit adjustment
    const priorSubscriptionCreditAmount = cart.value?.adjustments
      .filter(a => a.type.includes("Carbonite"))
      ?.reduce((accumulator, item) => {
        return accumulator + item.amount;
      }, 0);

    if (priorSubscriptionCreditAmount && priorSubscriptionCreditAmount != 0) {
      adjustmentsToDisplay.push({ type: adjustments.PriorSubscriptionCredit, amount: priorSubscriptionCreditAmount });
    }

    return adjustmentsToDisplay;
  }

  function getOrderedAdjustmentsToDisplay(adjustmentItems: IAdjustment[]) {
    const adjustmentsToDisplay: IAdjustmentDisplay[] = [];
    if (!adjustmentItems || adjustmentItems.length === 0) return adjustmentsToDisplay;

    for (const key in adjustmentsConfig) {
      if (key === adjustments.Tax && !cart.value?.paymentMethodId) {
        continue;
      }
      const config = adjustmentsConfig[key];
      const adjustment = adjustmentItems.find(l => l.type === key);
      if (adjustment) {
        if (
          key === adjustments.BonusMonthsPromo ||
          key === adjustments.TrialDaysRemaining ||
          key === adjustments.LicenseDaysRemaining
        )
          config.textReplacement = adjustment.amount;
        config.type = adjustment.type;
        config.amount = adjustment.amount;
        adjustmentsToDisplay.push(config);
      }
    }

    return adjustmentsToDisplay.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  function getRatePlanInfo(ratePlanId: string) {
    let response: IRatePlan | null = null;

    for (const plan of plans.value) {
      const ratePlan = plan.ratePlans.find(l => l.ratePlanId === ratePlanId);
      if (ratePlan) {
        response = ratePlan;
      }
    }

    return response;
  }

  function getBrandForSku(sku: string): string {
    const brand = plans.value.find(p => p.sku === sku)?.brand ?? "NoBrand";
    return brand;
  }

  // clears the cart
  async function clearCart(brand = openTextBrands.Carbonite, force = false) {
    if (cart.value || force) {
      cart.value = {
        items: [],
        userGuid: "",
        promotionCode: "",
        promoDetail: [],
        campaignId: 0,
        prospectId: 0,
        paymentMethodId: "",
        adjustments: [],
        total: 0,
        zuoraAccountId: "",
        brand: brand,
      };
    }
  }

  function getRatePlansForSku(sku: string) {
    return plans.value.find(l => l.sku === sku)?.ratePlans.filter(l => l.canPurchaseOnline) || [];
  }

  function removeFromCart(cartItem: ICartItem, itemIndex: number, isBundleItem = false) {
    notificationsStore.clearNotifications();
    if (cart.value && cart.value?.items.length > 0) {
      if (isBundleItem) {
        //Remove the bundle item from the cart items based on index
        cart.value?.items[itemIndex].bundledItems?.splice(itemIndex, 1);
      } else {
        //Store this item for the undo feature
        lastCartItemRemoved.value = cartItem;
        //Store a cloned snapshot of the cart items so we can put the item back where it was
        cartItemsSnapshot.value = cart.value.items.map(l => l);
        //Remove the item from the cart items
        cart.value?.items.splice(itemIndex, 1);
      }
      //Update the cart total
      updateShoppingCartTotal(false, false, googleTagCartEvents.remove);
    }
  }

  function addBundleItem(cartItem: ICartItem, itemIndex: number) {
    if (cart.value) {
      const { items } = cart.value;
      const item = items[itemIndex];
      if (!item.bundledItems) {
        item.bundledItems = [];
      }
      item.bundledItems.push(cartItem);
      updateShoppingCartTotal(false, false, googleTagCartEvents.add);
    }
  }

  function undoRemove() {
    if (lastCartItemRemoved.value && cartItemsSnapshot.value.length > 0 && cart.value) {
      const newItems: ICartItem[] = [];
      const clonedItems = cartItemsSnapshot.value.map(l => l);
      //Build a new array of cartItems while merging in any changes that may have happened
      for (const currentItem of clonedItems) {
        const mergeItem = cart.value.items.find(l => l.sku === currentItem.sku);
        if (mergeItem) {
          const newItem = Object.assign(currentItem, mergeItem);
          newItems.push(newItem);
        } else {
          newItems.push(currentItem);
        }
      }

      //Set the cart equal to the snapshot so the item goes back to the same spot it was before being removed
      cart.value.items = newItems;
      //Clear the stored items
      clearUndo();
      //Update the cart totals
      updateShoppingCartTotal(false, false, googleTagCartEvents.add);
    }
  }

  function clearUndo() {
    lastCartItemRemoved.value = null;
    cartItemsSnapshot.value = [];
  }

  async function getShoppingCart(userGuid = "") {
    const dbCart = (await unifiedApi.getShoppingCart(openTextBrand.value, userGuid)).data;
    if (dbCart) {
      dbCart.userGuid = userGuid;
      cart.value = dbCart;
    }
  }

  function prepareReturnUrl(route) {
    const isInBuyFlow = route === "buy" || route === selectPlanRouteName;
    if (isInBuyFlow) {
      //Set the cart in session storage
      sessionStorage.setItem("BuyFlow_Session_Cart", JSON.stringify(cart.value));
    }
    // sp=1 does not mean anything, just a trick to deal with the & signs.
    // lsc=1 mean loadSessionStorageCart. We were loading candy rack items when a user goes to the log in page per ZUOR-48044
    const step = isInBuyFlow ? `step=${pageState.value}&lsc=1` : `sp=1`;
    const referral = referralUrl.value ? `&referralUrl=${referralUrl.value}` : "";
    const returnUrl = `${window.location.origin}/${route}?${step}&${referral}`;
    return returnUrl;
  }

  function getCartItemByComputerId(computerId: number) {
    return cart.value?.items.find(l => l.computerId === computerId);
  }

  //used to set when cart has webroot with  allstate an paypal is selected as payment and the shipping address is not US
  function setHasAllStateNonUSPaypalShippingAddress(value: boolean) {
    hasAllStateNonUSPaypalShippingAddress.value = value;
  }

  function setLicenseKeyInfo(info: ILicenseKeyInfo) {
    const candyRackItemsToFilter = [webrootCDSku];
    const candyRackItems = info.candyRackItems?.filter(item => !candyRackItemsToFilter.some(l => l === item));
    //Create a new object
    const updatedInfo = Object.assign({}, info);
    updatedInfo.candyRackItems = candyRackItems ?? [];
    licenseKeyInfo.value = updatedInfo;
  }

  //Stores still need a return
  return {
    plans,
    lastRequestTime,
    isRunning,
    userSelectionDirtyFlag,
    disableCheckout,
    userGuid,
    referralUrl,
    populatePlans,
    forceRefresh,
    cart,
    pageState,
    layoutState,
    licenseKey,
    licenseKeyInfo,
    getItemPlan,
    getItemSubscription,
    beginShoppingCart,
    selectedPlan,
    setNewItemInShoppingCart,
    subscriptionTermsForSelectedPlan,
    selectedTerm,
    selectTerm,
    updateShoppingCartTotal,
    increaseQuantity,
    decreaseQuantity,
    getTermFromItem,
    setAutoRenew,
    updateCreditCard,
    setRecommendSkuFromCurrent,
    getRecommendedSku,
    mainProductSku,
    recommendedPlanSku,
    changingFromMonthly,
    computerName,
    getBasePlan,
    showProcessingOrderMessage,
    processingOrderMessage,
    getPricePerYear,
    checkSalesTax,
    isCalculatingCart,
    getRatePlanIdBySku,
    isRenewOrUpgrade,
    getSkuByRatePlan,
    getCatalogItemBySku,
    getAdjustmentsToDisplay,
    getRatePlanInfo,
    getOrderedAdjustmentsToDisplay,
    triggerStateChange,
    currentEmail,
    creditCards,
    getMaxQuantityBySku,
    getBrandForSku,
    isSkuValid,
    clearCart,
    getRatePlansForSku,
    isWebrootSku,
    isSafeSku,
    lastCartItemRemoved,
    cartItemsSnapshot,
    removeFromCart,
    undoRemove,
    clearUndo,
    getShoppingCart,
    recentPurchase,
    isNewUserBuyFlow,
    isNewUser,
    prepareReturnUrl,
    shippingAddress,
    shippingMatchesBilling,
    resetShippingValues,
    showShippingFormErrors,
    showBillingFormErrors,
    addBundleItem,
    hasShippableItem,
    hasCartItems,
    hasUsOnly,
    editShippingAddress,
    getCartItemByComputerId,
    getMonthsForItem,
    hasWebrootCartItems,
    hasWebrootPremium,
    hasWebrootPremiumInCart,
    paymentType,
    isPayPalPayment,
    openTextBrand,
    currentBrand,
    isFlatRateUpgrade,
    hasAllStateInCart,
    hasAllStateNonUSPaypalShippingAddress,
    setHasAllStateNonUSPaypalShippingAddress,
    setLicenseKeyInfo,
    PurchaseKeycodes,
    hasAlreadyHaveAllstateError,
    hasOnlyAllStateOrVPNInCart,
    hasOnlyAllStateInCart,
    hasOnlyCDLPinCart,
    hasWebrootRenewOnly,
    getRatePlanIdForTrialSubscription,
    isAllStateOrVpn,
    creditCard,
    hasOwnedByBestBuyError,
    planetOpenTextDiscountApplied,
    planetOpenTextDiscountMismatch,
  };
});
