<template>
  <div>
    <div v-if="showContactAmazonLink()">
      <button type="button" class="inline-btn-link" @click="goToAmazon()">
        {{ t("SubscriptionInformationComponent.contactAmazonLink") }}
      </button>
    </div>
    <div v-else-if="showBestBuyContactLink">
      <button type="button" class="inline-btn-link" @click="goToBestBuySupport()">
        {{ t("SubscriptionInformationComponent.contactBestBuyLink") }}
      </button>
    </div>
    <div v-else-if="showEvergreenMonthlyOptions()">
      <button type="button" class="inline-btn-link" @click="toggleCancelSubscriptionModal('click')">
        {{ t("SubscriptionInformationComponent.optionsLink") }}
      </button>
    </div>
    <div v-else-if="showWebrootNotActiveInstallLink()">
      <button type="button" class="inline-btn-link" @click="installWebrootSubscription()">
        {{ t("SubscriptionInformationComponent.installToActive") }}
      </button>
    </div>
    <div v-else-if="showBuyWebrootLink">
      <button type="button" class="btn-primary" @click="buyWebrootSubscription()">
        {{ t("SubscriptionInformationComponent.buyNowLink") }}
      </button>
    </div>
    <div v-else-if="showBuyWebrootLinkForTrial">
      <button type="button" class="btn-primary" @click="buyWebrootSubscription()">
        {{ t("SubscriptionInformationComponent.buyNowLink") }}
      </button>
    </div>
    <div v-else-if="showRenewWebrootLink">
      <button type="button" class="btn-primary" @click="renewWebrootSubscription()">
        {{ t("SubscriptionInformationComponent.renewNowLink") }}
      </button>
    </div>
    <div v-else-if="showSafeWebrootRenewLink">
      <button type="button" class="btn-primary" @click="renewSafeWebrootSubscription()">
        {{ t("SubscriptionInformationComponent.renewNowLink") }}
      </button>
    </div>
    <div v-else-if="showSafeOptionsLink() && !isProcessingRenewal(sub) && isUsingConsumerRatePlan() && isSafeProduct">
      <button type="button" class="inline-btn-link" @click="toggleAutoRenewOptionModal('safe click')">
        {{ t("SubscriptionInformationComponent.optionsLink") }}
      </button>
    </div>
    <div v-else-if="showSafeInstallLink()">
      <button type="button" class="inline-btn-link" @click="installSafe()">
        {{ t("SubscriptionInformationComponent.installLink") }}
      </button>
    </div>
    <div v-else-if="showWebrootOptionsLink() && !subscriptionCancelled()">
      <button type="button" class="inline-btn-link" @click="toggleAutoRenewOptionModal('webroot click')">
        {{ t("SubscriptionInformationComponent.optionsLink") }}
      </button>
    </div>
    <div v-else-if="showRenewSafeLink">
      <button class="btn-primary" @click="renewSafeSubscription(sub)">
        {{ t("SubscriptionInformationComponent.renewNowLink") }}
      </button>
    </div>
    <div v-else-if="showBuySafeLink">
      <button class="btn-primary" @click="buySafeSubscription()">
        {{ t("SubscriptionInformationComponent.buyNowLink") }}
      </button>
    </div>
    <div v-else-if="showInstallWebrootButton()">
      <button class="btn-primary" @click="installWebrootSubscription">
        {{ t("SubscriptionInformationComponent.installLink") }}
      </button>
    </div>
    <div v-else>
      <span v-if="!isProcessingRenewal(sub)">{{ t("SubscriptionInformationComponent.notApplicable") }}</span>
    </div>
    <AutoRenewOptionComponent
      :show-dialog="showAutoRenewOptionModal"
      :payment-methods="props.paymentMethods"
      :subscription="sub"
      @close-modal="toggleAutoRenewOptionModal"
      @save-modal="saveAutoRenewOptions"
      @auto-renew-discount-enabled="showAutoRenewDiscountEnabledNotification"
    />
    <CancelSubscriptionComponent
      :show-dialog="showCancelSubscriptionModal"
      :subscription="sub"
      @close-modal="toggleCancelSubscriptionModal"
      @save-modal="saveEvergreenMonthlyOptions"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { t } from "@/i18n";
import { useNotificationsStore } from "@/stores/notifications";
import { unifiedApi } from "@/common";
import { IPaymentMethod, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { ExpirationState, KeyCodeSeller } from "@/globalEnums";
import { webrootProducts, webrootLicenseCategories } from "@/common/webrootProductName";
import { subscriptionPageState } from "./SubscriptionsEnums";
import { carboniteProducts } from "@/common/productsBySku";
import {
  isProcessingRenewal,
  showSafeRenewLink,
  showSafeBuyLink,
  showCarbWebrootRenewLink,
  showWebrootRenewLink,
  showConvertToWebRootBuyLink,
  showWebrootBuyLinkForTrial,
  showContactBestBuyLink,
  isNotActiveYet,
  isExpiredAllstateWithNewSubscription,
  isNotRenewableOrUpgradeable,
  renewCarbWebrootSubscription,
} from "@/common/subscriptionHelper";
import { useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import AutoRenewOptionComponent from "@/components/shared/dialogs/AutoRenewOption/AutoRenewOption.vue";
import CancelSubscriptionComponent from "@/components/Subscriptions/CancelSubscription.vue";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { URLS_JA } from "@/define";
import { useUserStore } from "@/stores/user";
import { FLAGS, PREMIGRATION_WEBROOT_SKU } from "@/define";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { pageStates as buyPageStates } from "@/components/Buy/BuyEnums";
import { ZuoraSubscriptionStatus } from "@/globalEnums";

const componentName = "ManageSubscriptionColumnComponent";
const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
  displayForcedAutoRenewModal: {
    type: Boolean,
    default: false,
  },
  paymentMethods: {
    type: Object as PropType<IPaymentMethod[]>,
    default: null,
    required: false,
  },
});

const emits = defineEmits([
  "move-payment-method-mr-to-ar",
  "auto-renew-updated",
  "change-payment-method",
  "set-current-subscription",
  "buy-safe-sub",
  "cancel-subscription-state",
  "renew-safe",
  "show-auto-renew-failed",
  "add-payment-method",
  "auto-renew-discount-enabled",
  "go-to-outstanding-invoice-page",
]);

logEvent("created", componentName);
const router = useRouter();

const notificationsStore = useNotificationsStore();
const showAutoRenewOptionModal = ref<boolean>(false);
const showCancelSubscriptionModal = ref<boolean>(false);
const subscriptionsStore = useSubscriptionsStore();
const siteSettingsStore = useSiteSettingsStore();
const userStore = useUserStore();
const buyFlowStore = useBuyFlowStore();
// cSpell:ignore autorenew
// for url /subscriptions/computerId=$computerId$&action=autorenew will open auto renew model for the matching computer/subscription
if (props.displayForcedAutoRenewModal && showSafeOptionsLink() && !isProcessingRenewal(props.sub)) {
  toggleAutoRenewOptionModal("setup");
}

function toggleAutoRenewOptionModal(type: string | null) {
  if (!props.sub?.zuoraSubscriptionNumber) {
    notificationsStore.addNotification({ type: "NonZuoraEcommWebrootError" });
    return;
  }
  type = type || "modal";
  logEvent(
    "toggleAutoRenewOptionModal",
    componentName,
    `${showAutoRenewOptionModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showAutoRenewOptionModal.value = !showAutoRenewOptionModal.value;
  emits("set-current-subscription", props.sub);
}

function toggleCancelSubscriptionModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleCancelSubscriptionModal",
    componentName,
    `${showCancelSubscriptionModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showCancelSubscriptionModal.value = !showCancelSubscriptionModal.value;
}

function showAutoRenewDiscountEnabledNotification(sub: ISubscription) {
  emits("auto-renew-discount-enabled", sub);
}

async function saveAutoRenewOptions(res: string) {
  try {
    showAutoRenewOptionModal.value = false;
    notificationsStore.clearNotifications();
    logEvent("Open options", JSON.stringify(props.sub));

    if (res === "OK") {
      if (!props.sub.isAutoRenewEnabled) {
        emits("move-payment-method-mr-to-ar", props.sub);
      } else {
        logEvent("ManageAutoRenew modal is saved", res);
        emits("auto-renew-updated", props.sub);
      }
    } else if (res === "CHANGE_PAYMENT_METHOD") {
      emits("change-payment-method");
    } else if (res === "add-payment-method") {
      emits("add-payment-method");
    } else if (res === "FAILED") {
      emits("set-current-subscription");
    }
  } catch (error) {
    //Log event because this is not a real Error
    logEvent("ManageAutoRenew modal is closed", JSON.stringify(error));
  }
}

//All Amazon subs got to the same link, Amazon will handle the rest
function goToAmazon() {
  window.location.href = `https://www.amazon.com/dsv/subscriptions?subscription=${props.sub.retailerSubscriptionId}`;
}

function goToBestBuySupport() {
  subscriptionsStore.forceRefresh();
  window.open("https://www.bestbuy.com/services/remotesupport/partner", "_blank");
}

function buySafeSubscription() {
  emits("buy-safe-sub", props.sub);
}

// renews WR subs purchased outside of Carbonite
async function renewWebrootSubscription() {
  notificationsStore.clearNotifications();
  if (props.sub.hasOutstandingInvoices && props.sub.outstandingInvoice) {
    emits("go-to-outstanding-invoice-page", props.sub);
    return;
  }

  try {
    if (props.sub.keyCode) {
      var url;
      if (userStore.selectedLanguage === "ja") {
        url = URLS_JA["WEBROOT_HOME_RENEWAL"];
      } else {
        url = (await unifiedApi.getWebrootRenewUrl(props.sub.keyCode)).data.redirectUrl;
      }

      window.open(url, "_self");
      subscriptionsStore.forceRefresh();
    }
  } catch (err) {
    notificationsStore.addNotification({ type: "ServerErrors" });
    logException(err as Error);
  }
}

// buys non-zuora webroot subscriptions
async function buyWebrootSubscription() {
  notificationsStore.clearNotifications();
  if (props.sub.isTrial) {
    const ratePlanId = buyFlowStore.getRatePlanIdForTrialSubscription(props.sub);
    if (ratePlanId) {
      router.push({ name: "buy", query: { rpi: ratePlanId, lk: props.sub.licenseKey, step: buyPageStates.email } });
      return;
    }
  }

  // Offer Webroot Premium to the user if it is WSAIA
  if (props.sub.licenseCategoryName === webrootLicenseCategories.WSAIA) {
    router.push({ name: "buy", query: { step: buyPageStates.email, sku: "WebrootPremium" } });
    return;
  }

  try {
    if (props.sub.keyCode) {
      let url;
      if (userStore.selectedLanguage === "ja") {
        url = URLS_JA["WEBROOT_HOME"];
      } else {
        url = (await unifiedApi.getWebrootRenewUrl(props.sub.keyCode)).data.redirectUrl;
      }
      window.open(url, "_self");
      subscriptionsStore.forceRefresh();
    }
  } catch (err) {
    notificationsStore.addNotification({ type: "ServerErrors" });
    logException(err as Error);
  }
}

// renews WR subs purchased via Carbonite
function renewSafeWebrootSubscription() {
  if (
    !props.sub.isLegacyWebroot &&
    props.sub.licenseCategoryName === PREMIGRATION_WEBROOT_SKU &&
    FLAGS.ENABLE_SAFE_WEBROOT_MIGRATION === "true"
  ) {
    notificationsStore.addNotification({ type: "NonZuoraEcommWebrootError" });
    return;
  }
  renewCarbWebrootSubscription(props.sub);
}

function installSafe() {
  siteSettingsStore.installDeviceFromSubscriptionPage = true;
  siteSettingsStore.activationCodeToInstall = props.sub.activationCode ?? "";
  router.push("/backup");
}

async function saveEvergreenMonthlyOptions(res: boolean) {
  notificationsStore.clearNotifications();
  logEvent("cancel subscription modal is saved", JSON.stringify(res));

  if (res) {
    emits("cancel-subscription-state", subscriptionPageState.cancelSubscriptionSuccess);
  } else {
    emits("cancel-subscription-state", subscriptionPageState.cancelSubscriptionFailure);
  }
}

function subscriptionCancelled(): boolean {
  return props.sub.zuoraSubscriptionStatus === ZuoraSubscriptionStatus.Cancelled;
}

function showContactAmazonLink(): boolean {
  return props.sub.keyCodeSellerType === KeyCodeSeller.Amazon;
}

function showEvergreenMonthlyOptions(): boolean {
  return props.sub.isEvergreenMonthly && !props.sub.isCancelledEvergreenSubscription && props.sub.remainingDays > 0;
}

//We only ever want to show the contact link for BBY
const showBestBuyContactLink = computed(() => {
  return showContactBestBuyLink(props.sub);
});

function showSafeOptionsLink(): boolean {
  if (props.sub && props.sub.isActiveUntilCancelled && !props.sub.isEvergreenMonthly) {
    return false;
  }

  return (
    (props.sub.expiration === ExpirationState.Normal ||
      (props.sub.isAutoRenewEnabled && props.sub.remainingDays > 0)) &&
    (!props.sub.resellerId || props.sub.isPartnerReferral) &&
    !props.sub.hasOutstandingInvoices &&
    ((props.sub.keyCodeSellerType === KeyCodeSeller.Safe &&
      webrootProducts.some(product => {
        return product.licenseCategory === props.sub.licenseCategoryName;
      })) ||
      (!!props.sub?.computer && props.sub?.computer.id > 0))
  );
}

function showSafeInstallLink(): boolean {
  return (
    carboniteProducts.some(l => l.sku === props.sub.sku) &&
    (!props.sub?.computer || props.sub?.computer.id === 0) &&
    !isExpiredSafeLite()
  );
}

function isExpiredSafeLite(): boolean {
  return props.sub.remainingDays <= 0 && props.sub.isSafeLite;
}

function showWebrootOptionsLink(): boolean {
  return (
    !isSafeProduct.value &&
    (props.sub.expiration === ExpirationState.Normal || (props.sub.isAutoRenewEnabled && props.sub.remainingDays >= 0))
  );
}

function showWebrootNotActiveInstallLink(): boolean {
  return props.sub.keyCodeSellerType === KeyCodeSeller.Webroot && isNotActiveYet(props.sub);
}

const showBuyWebrootLink = computed(() => {
  return showConvertToWebRootBuyLink(props.sub);
});

const showBuyWebrootLinkForTrial = computed(() => {
  return showWebrootBuyLinkForTrial(props.sub);
});

function showInstallWebrootButton(): boolean {
  return isNotActiveYet(props.sub);
}

// shows or hides the renew link for WR subs purchased in Zuora
const showRenewWebrootLink = computed(() => {
  return isExpiredAllstateWithNewSubscription(props.sub) || isNotRenewableOrUpgradeable(props.sub)
    ? false
    : showWebrootRenewLink(props.sub);
});

// shows or hides the renew link for WR subs purchased via Carbonite
const showSafeWebrootRenewLink = computed(() => {
  return showCarbWebrootRenewLink(props.sub);
});

const showBuySafeLink = computed(() => {
  return showSafeBuyLink(props.sub);
});

const showRenewSafeLink = computed(() => {
  return showSafeRenewLink(props.sub);
});

function renewSafeSubscription(sub: ISubscription) {
  emits("renew-safe", sub);
}

function installWebrootSubscription() {
  subscriptionsStore.forceRefresh();
  router.push("/downloads");
}

function isUsingConsumerRatePlan() {
  let isConsumerRatePlan = false;
  buyFlowStore.plans.forEach(plan => {
    const ratePlan = plan.ratePlans.find(l => l.ratePlanId === props.sub.ratePlanId);
    if (ratePlan) {
      isConsumerRatePlan = true;
      return;
    }
  });

  return isConsumerRatePlan;
}

const isSafeProduct = computed(() => {
  return carboniteProducts.some(l => l.sku === props.sub.sku);
});
</script>
