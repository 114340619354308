<template>
  <div v-if="showSpinner" class="login-page">
    <LoadingSpinnerComponent />
  </div>
  <div v-else class="login-page">
    <NotificationsComponent context="CreateAccountTabLoginComponent" />
    <div class="create-account">
      <div class="info notification">
        <h1>{{ tn("welcomeHeading") }}</h1>
        <p>{{ tn("welcomeMessage1") }}</p>
        <p>{{ tn("welcomeMessage2") }}</p>
      </div>
      <h1>{{ tn("createAccount") }}</h1>
      <p>
        {{ tn("alreadyHaveAnAccountMessage") }}
        <button class="inline-btn-link" @click="goToLoginPage">{{ tn("redirectLogIn") }}</button>
      </p>
      <ul class="form">
        <li>
          <label for="create-email">{{ t("Common.email") }}</label>
          <InputErrorComponent :show="createAccountEmailErrors !== null">
            <input
              id="create-email"
              v-model.trim="createAccountEmail"
              :disabled="disableFormFields"
              type="text"
              @keyup="dirtyFlagEmail = true"
            />
            <template #error>
              <div>
                <span :key="createAccountEmailErrors?.name">
                  {{ createAccountEmailErrors?.message || t(createAccountEmailErrors?.name ?? "") }}
                </span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <label for="create-keycode">{{ t("Common.keycode") }}</label>
          <InputErrorComponent :show="createAccountKeycodeErrors && createAccountKeycodeErrors.length > 0">
            <input
              id="create-keycode"
              v-model="createAccountKeycode"
              :disabled="disableFormFields || disableKeyCodeField"
              type="text"
              @keyup="dirtyFlagKeycode = true"
            />
            <template #error>
              <div>
                <span v-for="e in createAccountKeycodeErrors" :key="e.name">{{ e.message }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <div class="label-wrapper">
            <label for="create-password">{{ t("Common.password") }}</label>
            <button
              v-if="createAccountPasswordHidden"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="createAccountPasswordHidden = !createAccountPasswordHidden"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="createAccountPasswordHidden = !createAccountPasswordHidden"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="createAccountPasswordErrors && createAccountPasswordErrors.length > 0">
            <input
              id="create-password"
              v-model.trim="createAccountPassword"
              :type="createAccountPasswordHidden ? 'password' : 'text'"
              autocomplete="off"
              @keyup="dirtyFlagPassword = true"
            />
            <template #error>
              <div>
                <span v-for="e in createAccountPasswordErrors" :key="e.name">{{ e.message }}</span>
              </div>
            </template>
          </InputErrorComponent>
          <p class="mt-quarter">{{ tn("passwordRequirements") }}</p>
          <PasswordRulesComponent :password="createAccountPassword" :show-rules="false" />
        </li>
        <li>
          <div class="label-wrapper">
            <label for="confirm-password">{{ tn("confirmPassword") }}</label>
            <button
              v-if="createAccountConfirmPasswordHidden"
              type="button"
              class="inline-btn-link"
              title="Show Confirm password"
              @click="createAccountConfirmPasswordHidden = !createAccountConfirmPasswordHidden"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="createAccountConfirmPasswordHidden = !createAccountConfirmPasswordHidden"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="showPasswordConfirmError">
            <input
              id="confirm-password"
              v-model.trim="createAccountConfirmPassword"
              :type="createAccountConfirmPasswordHidden ? 'password' : 'text'"
              autocomplete="off"
              @keyup="dirtyFlagConfirmPassword = true"
            />
            <template #error>
              <div>
                <span>{{ tn("passwordNotMatch") }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <div v-if="captchaSiteKey" class="captcha mb">
            <vue-recaptcha
              :key="captchaComponentKey"
              :sitekey="captchaSiteKey"
              :load-recaptcha-script="true"
              :language="recaptchaLang"
              @verify="captchaCallback"
              @expired="onCaptchaExpired"
            ></vue-recaptcha>
          </div>
          <!-- btn-primary should load with the disabled attribute, which gets removed when all 4 of the password requirements are met -->
          <button
            id="btnCreateAccount"
            type="submit"
            class="btn-primary position-relative"
            :disabled="!createAccountEnabled || isCreatingAccount"
            @click="createAccount()"
          >
            <spinner :is-spinning="isCreatingAccount" />
            {{ tn("createAccount") }}
          </button>
          <p v-if="createError" class="danger-text">{{ tn("createAccountError") }}</p>
        </li>
      </ul>
    </div>
    <BestBuySupportSectionComponent v-if="isBestBuyCode" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { VueRecaptcha } from "vue-recaptcha";

import { AxiosError } from "axios";
import { unifiedApi } from "@/common";
import {
  IAddKeycodeResponse,
  ITrialSignupResponse,
  ISetLanguagePreferenceRequest,
} from "@/common/api/unifiedPortal/interfaces";

import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { useConfigStore } from "@/stores/config";
import { useSiteSettingsStore } from "@/stores/siteSettings";

import { validateKeycode } from "@/common/webrootKeycodes";
import { validatePassword } from "@/common/strongPassword";
import { isValidEmail } from "@/common/validateEmail";
import { passwordRegex, emailCharactersRegex } from "@/globalRegex";
import { getCustomerPortalRedirectURL } from "@/common/loginRedirect";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { logout } from "@/common/logout";

import PasswordRulesComponent from "@/components/shared/PasswordRules.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import Spinner from "@/components/shared/Spinner.vue";
import BestBuySupportSectionComponent from "@/components/shared/BestBuySupportSection.vue";
import { handleApiError } from "@/common/handleApiError";
import LoadingSpinnerComponent from "@/components/shared/LoadingSpinner.vue";
import { IErrorMessage } from "@/common/api/interfaces";
import { INotificationOptions } from "@/stores/notificationsInterfaces";
import { loadLanguage, languages } from "@/i18n";
import { registerRefreshFunction, clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";
import { IValidationResult, reportStringErrors } from "@/common/validator";
import { MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH, MIN_EMAIL_LENGTH, MIN_PASSWORD_LENGTH } from "@/define";
import { NotificationType } from "@/stores/notifications";
import { getUserDetailsFromStore } from "@/common/userDetails";

const componentName = "CreateAccount";

const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const configStore = useConfigStore();
const siteSettings = useSiteSettingsStore();
const isCreatingAccount = ref<boolean>(false);
const showSpinner = ref<boolean>(true);
const createAccountEmail = ref<string>("");
const createAccountPassword = ref<string>("");
const createAccountKeycode = ref<string>("");
const createAccountConfirmPassword = ref<string>("");
const createAccountPasswordHidden = ref<boolean>(true);
const createAccountConfirmPasswordHidden = ref<boolean>(true);
const createError = ref<boolean>(false);
const enforceCaptcha = ref<boolean>(false);
const captchaSiteKey = ref<string>("");
const captchaResponseToken = ref<string>("");
const captchaComponentKey = ref<number>(0);
const disableFormFields = ref<boolean>(false);
const disableKeyCodeField = ref<boolean>(false);
const recaptchaLang = ref<string>(localStorage.getItem("selectedLanguage") || "en");
const dirtyFlagEmail = ref<boolean>(false);
const dirtyFlagKeycode = ref<boolean>(false);
const dirtyFlagPassword = ref<boolean>(false);
const dirtyFlagConfirmPassword = ref<boolean>(false);

let customerPortalLoginUrl = "";

const router = useRouter();
const route = useRoute();

const userDetails = computed(() => {
  return userStore.userDetails;
});
function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  logEvent("created", componentName);
  setLanguage();

  if (route.query.passwordReset) {
    notificationsStore.clearNotifications();
    notificationsStore.addNotification({ type: "PasswordReset" });
    logEvent("Password reset", `${componentName}/OnMounted`);
  }

  if (route.query.oht) {
    await defaultForm(webrootKeycode.value ?? "");
    logEvent("OHT token received", `${componentName}/OnMounted`);
  }

  // If showCaptcha=false is provided in the query, then ReCaptcha should not be displayed.
  // This allows a way to prevent ReCaptcha from interfering with automated tests.
  if (route.query?.showCaptcha !== "false") {
    captchaSiteKey.value = configStore.config?.captcha?.siteKey ?? "";
    enforceCaptcha.value = configStore.config?.captcha?.enforce ?? false;
  }

  customerPortalLoginUrl = getCustomerPortalRedirectURL(route?.name?.toString());
  logEvent(`Redirect URL: ${customerPortalLoginUrl}`, `${componentName}/OnMounted`);

  await manageLoggedInUsers();
});

onUnmounted(() => {
  clearRefreshFunctions();
});

const webrootKeycode = computed(() => {
  return route.query?.oht?.toString();
});

const isBestBuyCode = computed(() => {
  return route.query?.bb === "1";
});

function setLanguage() {
  let lang;
  if (route.query?.lang) {
    // Preference is LanguageSelector setting over route params.
    lang = localStorage.getItem("selectedLanguage") || (route.query?.lang as string);
  } else {
    // If lang query is not present preference is browser language.
    lang = navigator.language.split("-")[0]; // values can be en-US,en-Uk
  }

  if (!languages.some(l => l === lang)) {
    lang = "en";
  }

  loadLanguage(lang ?? "en");
  setCaptchaLanguage(lang ?? "en");
}

function setCaptchaLanguage(lang: string) {
  if (lang == "jp") {
    recaptchaLang.value = "ja";
  } else {
    recaptchaLang.value = lang;
  }
}

async function defaultForm(key: string) {
  try {
    logEvent(`Trying getWebrootUserInfo`, `${componentName}/defaultForm`);
    const result = (await unifiedApi.getWebrootUserInfo(key)).data;
    logEvent(`Result for getWebrootUserInfo: ${JSON.stringify(result)}`, `${componentName}/defaultForm`);

    if (result.success) {
      disableFormFields.value = true;
    } else {
      if (result.accountInfo.errorIdentifier === "ValidationError_EmailInUseNoLoginLink") {
        logEvent(`LoginAccountExistsNoLoginLink`, `${componentName}/defaultForm`);
        notificationsStore.addNotification({ type: "LoginAccountExistsNoLoginLink" });
        disableKeyCodeField.value = true;
      } else if (result.accountInfo.errorIdentifier === "ValidationError_LicenseInfoAlreadyLinked") {
        //for already consumed Order header token, show positive message, notifying uses that Account is already setup
        logEvent(`AlreadyUsedOrderHeaderToken`, `${componentName}/defaultForm`);
        notificationsStore.addNotification({
          type: "AlreadyUsedOrderHeaderToken",
          params: {
            url: getCustomerPortalRedirectURL(route?.name?.toString()),
            text: "NotificationsComponent.LoginAccountLinkText",
            linkToOutsideSite: true,
          },
        });
      }
      disableFormFields.value = false;
    }
    createAccountEmail.value = result.accountInfo.email;
    createAccountKeycode.value = result.accountInfo.keycode;
  } catch (error) {
    handleApiError(error as AxiosError, false);
  }
}

const emailInvalidCharacterError = computed(() => {
  return reportStringErrors(
    createAccountEmail.value,
    t("Common.email"),
    MIN_EMAIL_LENGTH,
    MAX_EMAIL_LENGTH,
    emailCharactersRegex
  );
});

const createAccountEmailErrors = computed(() => {
  if (dirtyFlagEmail.value === false) return null;
  const errs = isValidEmail(createAccountEmail.value).filter(e => !e.passed);
  if (errs.length > 0) return errs[0];
  if (emailInvalidCharacterError.value.length > 0) {
    return { name: "invalidCharacter", message: emailInvalidCharacterError.value } as IValidationResult;
  }
  return null;
});

const createAccountKeycodeErrors = computed(() => {
  return createAccountKeycode.value === "" && dirtyFlagKeycode.value === false
    ? []
    : validateKeycode(createAccountKeycode.value).filter(e => !e.passed);
});

function shimmyPassword() {
  //Need to shimmy the createAccountPassword field so that the computed property will re-evaluate the password
  //with the new localized strings. This will also trigger the confirm password to re-evaluate
  const password = createAccountPassword.value;
  createAccountPassword.value = "123";
  createAccountPassword.value = password;
}

registerRefreshFunction(shimmyPassword);

const passwordInvalidCharacterError = computed(() => {
  return reportStringErrors(
    createAccountPassword.value,
    t("Common.password"),
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH,
    passwordRegex
  );
});

const createAccountPasswordErrors = computed(() => {
  const result = validatePassword(createAccountPassword.value);

  //Piggybacking reportStringErrors on top of validatePassword to return
  //error message that displays the invalid characters used.
  result.push({
    name: "invalid_characters",
    message: passwordInvalidCharacterError.value,
    displayOrder: 5,
    passed: passwordInvalidCharacterError.value.length === 0,
  });

  const isNotValidPassword = result.some(e => !e.passed) && dirtyFlagPassword.value === true;
  return isNotValidPassword ? [result.filter(e => !e.passed)[0]] : [];
});

const showPasswordConfirmError = computed(() => {
  return (
    !!createAccountPassword.value &&
    !!dirtyFlagConfirmPassword.value &&
    createAccountPassword.value !== createAccountConfirmPassword.value
  );
});

const createAccountEnabled = computed(() => {
  if (!createAccountKeycode.value) return false;
  if (showPasswordConfirmError.value) return false;
  if (createAccountEmailErrors.value) return false;
  if (createAccountKeycodeErrors.value.length > 0) return false;
  if (createAccountPasswordErrors.value.length > 0) return false;

  // check for captcha response, if enabled
  if (enforceCaptcha.value && !captchaResponseToken.value) return false;

  return (
    !!createAccountEmail.value &&
    !!createAccountPassword.value &&
    !!createAccountConfirmPassword.value &&
    validatePassword(createAccountPassword.value)
  );
});

async function handleCreateAccountSuccess(createAccountResult: ITrialSignupResponse) {
  notificationsStore.clearNotifications();
  /**
   * Redirect user to downloads page.
   * Set the user data to login the user
   * Set setIsCreateAccountFlow to true to determine the user is coming from createAccountFlow
   */
  siteSettings.setIsCreateAccountFlow(true);
  if (createAccountResult.linkKeycodeSuccessMessage !== "Success_InactiveKeycode") {
    if (
      createAccountResult.errors["error"] &&
      createAccountResult.errors["error"].includes("ValidationError_KeycodeEmailMismatch")
    ) {
      userStore.createAccountEmailMismatchKeycode = createAccountKeycode.value;
    } else if (
      // If the expirationDate is returned, check if it is not a past date.
      // Note: The expirationDate may return empty if the pending email verification is activated
      createAccountResult.expirationDate.indexOf("0001") === -1 &&
      new Date(createAccountResult.expirationDate) < new Date()
    ) {
      logEvent(
        `isCreateAccountKeycodeExpired:${new Date(createAccountResult.expirationDate)}`,
        `${componentName}/createAccount`
      );
      logEvent(`isCreateAccountKeycodeExpired:${new Date()}`, `${componentName}/createAccount`);
      userStore.isCreateAccountKeycodeExpired = true;
    }
  }
  await reloadSubscriberInfo();
  logEvent("Account created redirecting to downloads", `${componentName}/createAccount`);
  await router.push("/downloads");
}

async function isLoggedIn() {
  try {
    const currentUser = (await unifiedApi.getUser(true))?.data;
    return currentUser;
  } catch (error) {
    // logging error but after that the error handling can continue
    //Don't show an error here since not being logged at this point isn't an error
    logException(error as Error, `${componentName}/createAccount`);
  }
}

async function handleCreateAccountError(createAccountResultMessage: string, formattedKeycode: string) {
  logEvent(`Errors on create account: ${createAccountResultMessage}`, `${componentName}/createAccount`);

  try {
    // if is logged in then the Account was created but there was an error applying keycode.
    // that error is gonna get forwarded to the download page to handle it
    if (await isLoggedIn()) {
      siteSettings.setIsCreateAccountFlow(true);
      router.push({
        name: "downloads",
        params: { errorMessage: createAccountResultMessage, keycode: formattedKeycode },
      });
      return;
    }
  } catch (error) {
    // logging error but after that the error handling can continue
    logException(error as Error, `${componentName}/createAccount`);
  }

  if (createAccountResultMessage === "ValidationError_EmailInUse") {
    //If User is already registered , show notification to log in
    // setting the IsCreateAccountFlow for security questions flow
    // and getting to downloads page.
    notificationsStore.addNotification({
      type: "LoginAccountExists",
      params: {
        url: getCustomerPortalRedirectURL(route.name?.toString()),
        text: "NotificationsComponent.LoginAccountLinkText",
        linkToOutsideSite: true,
      },
    });
  } else if (createAccountResultMessage === "ValidationError_EmailInUseNoLoginLink") {
    notificationsStore.addNotification({ type: "LoginAccountExistsNoLoginLink" });
    disableFormFields.value = false;
    disableKeyCodeField.value = true;
  } else if (createAccountResultMessage === "ValidationError_FraudKeycode") {
    //The keycode has been marked as license_distribution_method_id=919 meaning it has been labelled as Fraudulent.
    notificationsStore.addNotification({
      type: "ValidationError_FraudKeycode",
      params: { url: "support", text: "NotificationsComponent.support", keycode: formattedKeycode },
    });
  } else if (createAccountResultMessage === "ValidationError_LicenseStatusIneligible") {
    //The keycode that has status disabled will give this error state.
    notificationsStore.addNotification({
      type: "ValidationError_LicenseStatusIneligible",
      params: { url: "support", text: "NotificationsComponent.support", keycode: formattedKeycode },
    });
  } else if (createAccountResultMessage === "ValidationError_KeycodeEmailMismatch") {
    //The account_email provided in the request data does not match up with the keycode's customer_email in the Ecom database.
    notificationsStore.addNotification({
      type: "ValidationError_KeycodeEmailMismatch",
      params: { text: formattedKeycode },
    });
  } else if (createAccountResultMessage === "ValidationError_KeycodeIneligible") {
    //The keycode in the request data was valid, but identified as product_line_id=300 meaning the keycode is for an Enterprise product and not Consumer.
    notificationsStore.addNotification({
      type: "ValidationError_KeycodeIneligible",
      params: { text: formattedKeycode },
    });
  } else if (createAccountResultMessage === "ValidationError_InvalidKeycode") {
    //The keycode in the request data was not the correct format (20 alphanumeric digits, dashes optional between every 4 digits).  Or the keycode in the request was the correct format but not a valid keycode in the Ecom database in this environment.
    notificationsStore.addNotification({
      type: "ValidationError_InvalidKeycode",
      params: { text: formattedKeycode },
    });
  } else if (createAccountResultMessage === "ValidationError_LicenseInfoNotFound") {
    notificationsStore.addNotification({
      type: "ValidationError_InvalidKeycode",
      params: { text: formattedKeycode },
    });
  } else if (createAccountResultMessage === "ValidationError_LicenseInfoAlreadyLinked") {
    notificationsStore.addNotification({
      type: "ValidationError_KeycodeEmailMismatch",
      params: { text: formattedKeycode },
    });
    disableFormFields.value = false;
  } else if (createAccountResultMessage === "recaptchaValidation") {
    notificationsStore.addNotification({ type: "RecaptchaValidation_invalid" });
  } else if (createAccountResultMessage === "ValidationError_DuplicateCustomerInEcommerce") {
    notificationsStore.addNotification({ type: "ValidationError_DuplicateCustomerInEcommerce" });
  } else if (createAccountResultMessage === "ValidationError_AMZSLicense") {
    notificationsStore.addNotification({ type: "ValidationError_AMZSLicense" });
  } else {
    disableFormFields.value = false;
    notificationsStore.addNotification({ type: "ServerErrors" });
  }
}

async function createAccount() {
  notificationsStore.clearNotifications();
  logEvent(`Called createAccount`, `${componentName}/createAccount`);
  if (createAccountEnabled.value) {
    isCreatingAccount.value = true;

    const formattedKeycode = createAccountKeycode.value.replace(/-/g, "")?.replace(/ /g, "");

    try {
      logEvent(`Trying to create account`, `${componentName}/createAccount`);
      const createAccountResult = (
        await unifiedApi.createAccount(
          createAccountEmail.value,
          createAccountPassword.value,
          formattedKeycode,
          webrootKeycode.value ?? "",
          captchaResponseToken.value,
          localStorage.getItem("selectedLanguage") || "en"
        )
      ).data;
      logEvent(`Result for create account: ${JSON.stringify(createAccountResult)}`, `${componentName}/createAccount`);

      await handleCreateAccountSuccess(createAccountResult);
    } catch (error) {
      const e = error as AxiosError;
      await handleCreateAccountError((e.response?.data as IErrorMessage)?.message, formattedKeycode);

      //do this last so all the events just recorded are included
      logException(error as Error, `${componentName}/createAccount`);
    }

    isCreatingAccount.value = false;
  }
}

function captchaCallback(responseToken: string) {
  logEvent(`Captcha token: ${responseToken}`, `${componentName}/captchaCallback`);
  captchaResponseToken.value = responseToken;
}

function onCaptchaExpired() {
  logEvent("Captcha expired", `${componentName}/onCaptchaExpired`);
  captchaResponseToken.value = "";

  // incrementing the key forces the captcha component to reload
  captchaComponentKey.value += 1;
}

async function manageLoggedInUsers() {
  let redirectRequired = false;
  try {
    logEvent("Trying to get a logged user", `${componentName}/manageLoggedInUsers`);
    const currentUser = (await unifiedApi.getUser(true))?.data;
    if (currentUser) {
      //user is already logged in
      logEvent(`User is Logged In: ${JSON.stringify(currentUser)}`, `${componentName}/manageLoggedInUsers`);

      //get the subscriberInfo of the logged in user
      await reloadSubscriberInfo();
      const subscriberInfo = userStore.currentUser?.subscriberInfo;
      // Based on the language code value already set for the logged-in user
      // Setting the default language based on the local storage.
      if (!userDetails.value?.languageCode) {
        try {
          //Setting the default email communication language as english
          const request: ISetLanguagePreferenceRequest = {
            languageCode: localStorage.getItem("selectedLanguage") || "en",
          };
          await unifiedApi.setLanguagePreference(request);
          await getUserDetailsFromStore(true);
        } catch (error) {
          handleApiError(error as AxiosError, true);
        }
      }
      if (
        subscriberInfo !== null &&
        (subscriberInfo?.customerType === "Consumer" ||
          (subscriberInfo?.customerType === "ResellerUser" && !(subscriberInfo?.accountUID > 0)))
      ) {
        //if logged in user is a consumer or reseller, lets redirect them appropriately
        //if create Account page has a sales header token
        if (route.query.oht) {
          //check if Keycode is in valid format or not
          if (createAccountKeycode.value.length > 0 && createAccountKeycodeErrors.value.length === 0) {
            //Check if Logged in user's email and the keycode linked email are same or not
            //if they are not same, first update the email linked to the keycode
            // if (this.createAccountEmail !== this.user.email) {
            //     logEvent('Logged In User and Keycode registered Email are not same');
            //     //To Do : Update Keycode Email
            // }
            // else {
            //once email linked to the keycode part is working , we can remove this else condition
            logEvent(`Trying to add the keyCode to the user`, `${componentName}/manageLoggedInUsers`);
            redirectRequired = await addKeycodes(createAccountKeycode.value, route.query.oht);
            //setting the flow to createAccount for displaying the verify email dialog only for once
            siteSettings.setIsCreateAccountFlow(true);
            logEvent(`Is redirect required? ${redirectRequired}`, `${componentName}/manageLoggedInUsers`);
            //}
          } else {
            //if no keycode is means keycode is already consumed, so we can redirect the user to download page
            redirectRequired = true;
            logEvent(`Set to redirect to Download Page - OHT already consumed`, `${componentName}/manageLoggedInUsers`);
          }
        } else {
          redirectRequired = true;
          logEvent(`Set to redirect to Download Page - no OHT`, `${componentName}/manageLoggedInUsers`);
        }
        if (redirectRequired) {
          showSpinner.value = true;
          logEvent(`Redirecting to Download Page...`, `${componentName}/manageLoggedInUsers`);
          router.push("/downloads");
        }
      } else {
        //if logged in user is not a consumer means he can be SMB or possibly some other unsupported customer type.
        //call the log out api
        logEvent(`SMB or other unsupported type is logged in`, `${componentName}/manageLoggedInUsers`);
        logout();
      }
    }
  } catch {
    logEvent(`User is not logged in`, `${componentName}/manageLoggedInUsers`);
  }
  if (!redirectRequired) {
    showSpinner.value = false;
  }
}

async function addKeycodes(createAccountKeycode, orderHeaderToken) {
  try {
    logEvent("Trying to addKeycodes", `${componentName}/addKeycodes`);
    const isAdded: IAddKeycodeResponse = (await unifiedApi.addKeycode(createAccountKeycode, orderHeaderToken)).data;
    if (isAdded) {
      logEvent(`AddKeycodes result: ${JSON.stringify(isAdded)}`, `${componentName}/addKeycodes`);
      if (!isAdded.success) {
        return false;
      }
    } else {
      logEvent(`AddKeycodes result NULL`, `${componentName}/addKeycodes`);
      return false;
    }
  } catch (error) {
    const e = error as AxiosError;
    const notification = hasAPIValidationError(e, createAccountKeycode);
    handleApiError(e, true, notification.type, notification.params);
    return false;
  }
  return true;
}

function hasAPIValidationError(err: AxiosError, formattedKeycode: string): INotificationOptions {
  const type: NotificationType = (err.response?.data as IErrorMessage)?.message as NotificationType;
  const notificationMessage: NotificationType = "ServerErrors";
  let notificationOption: INotificationOptions = { type: notificationMessage };
  switch (type) {
    case "ValidationError_KeycodeEmailMismatch":
      notificationOption = { type: "ValidationError_KeycodeEmailMismatch", params: { text: formattedKeycode } };
      break;
    default:
      notificationOption = { type: notificationMessage };
  }
  return notificationOption;
}

function goToLoginPage() {
  window.location.href = customerPortalLoginUrl;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";
.info {
  text-align: left;
}
.login-page {
  background-color: white;
  border: none;
}
.create-account {
  padding: var(--space-half);
}
.position-relative {
  position: relative;
}

@media (min-width: 600px) {
  .login-page {
    padding: var(--space-half) var(--space-double);
  }
}
</style>
