<template>
  <div class="product-column">
    <div class="icon-name-wrapper">
      <div v-if="showWarning(props.sub) || showDanger(props.sub)">
        <svg-icon-component v-if="showWarning(props.sub)" icon="circle-triangle" class="warning icon-sm mr-quarter" />
        <svg-icon-component v-if="showDanger(props.sub)" icon="circle-exclamation" class="danger icon-sm mr-quarter" />
      </div>
      <div>
        <ul>
          <li>{{ props.sub.productDisplayName }}</li>
          <li v-if="isOnlyKeycode() && props.sub.displayKeycode">
            {{ t("SubscriptionInformationComponent.keycode") }} {{ props.sub.displayKeycode }}
          </li>
          <li v-else-if="computerName()" class="word-break">
            {{ t("SubscriptionInformationComponent.computer") }} {{ computerName() }}
          </li>
          <li
            v-if="
              canDisplayPerSubscriptionFeature(
                perSubscriptionFeatures.changePlan,
                props.sub.carbLicenseDistributionMethodCode
              ) &&
              showSafeUpgradeLink() &&
              !isProcessingRenewal(props.sub)
            "
          >
            <button type="button" class="inline-btn-link" @click="upgradeSafeSubscription(props.sub)">
              <span v-if="showBuyFlow()">{{ t("SubscriptionInformationComponent.changeSubscription") }}</span>
              <span v-else>{{ t("SubscriptionInformationComponent.upgradeLink") }}</span>
            </button>
          </li>
          <li v-if="showUpgradeRenewLink()">
            <button type="button" class="inline-btn-link" @click="upgradeWebrootSubscription()">
              {{ t("SubscriptionInformationComponent.renewUpgradeLink") }}
            </button>
          </li>
        </ul>
        <ul v-if="(isSafePlusOrPrime() || showKeycodeForTrial()) && props.sub.keyCode" class="plus-prime-keycode">
          <li>{{ t("Brand.webrootSecureAnywhereAntivirus") }}</li>
          <li>{{ t("SubscriptionInformationComponent.keycode") }} {{ props.sub.displayKeycode }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { PropType } from "vue";
import { logEvent, logException } from "@/common/logger";
import { EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY, FLAGS } from "@/define";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { KeyCodeSeller } from "@/globalEnums";
import { useNotificationsStore } from "@/stores/notifications";
import { carboniteProducts } from "@/common/productsBySku";
import {
  isSafeSubscriptionClosed,
  showWarning,
  showDanger,
  isProcessingRenewal,
  isNotActiveYet,
  isExpiredAllstateWithNewSubscription,
  isNotRenewableOrUpgradeable,
} from "@/common/subscriptionHelper";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { webrootLicenseCategories } from "@/common/webrootProductName";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useUserStore } from "@/stores/user";
import { URLS_JA } from "@/define";
import {
  canDisplayPerSubscriptionFeature,
  perSubscriptionFeatures,
  retailCodesMapper,
  canDisplayGlobalFeature,
  globalFeatures,
} from "@/common/featureDisplayAccess";
import { allstateLCNs } from "@/common/webrootProductName";

const componentName = "ProductColumnComponent";

const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

const emits = defineEmits(["buy-safe-sub", "go-to-outstanding-invoice-page"]);

logEvent("created", componentName);
const notificationsStore = useNotificationsStore();
const subscriptionsStore = useSubscriptionsStore();
const buyFlowStore = useBuyFlowStore();
const router = useRouter();
const userStore = useUserStore();

function showBuyFlow() {
  return FLAGS.ENABLE_BUYFLOW === "true";
}

function upgradeSafeSubscription(sub: ISubscription) {
  if (showBuyFlow()) {
    redirectToBuyFlow(sub);
    return;
  }

  // find the next SKU, if any, in the upgrade order for this sub.
  // if we find one, supply it in the redirect to the Buy Flow so that it'll be auto-selected.
  const upgradeSku = getUpgradeSku(props.sub);

  emits("buy-safe-sub", props.sub, upgradeSku);
}

async function upgradeWebrootSubscription() {
  // TODO: Redirect to new buyflow when we add Webroot
  notificationsStore.clearNotifications();
  subscriptionsStore.forceRefresh();
  if (props.sub.hasOutstandingInvoices && props.sub.outstandingInvoice) {
    emits("go-to-outstanding-invoice-page", props.sub);
    return;
  }
  try {
    if (props.sub.keyCode) {
      let url;
      if (userStore.selectedLanguage === "ja") {
        url = URLS_JA["WEBROOT_HOME_RENEWAL"];
      } else {
        url = (await unifiedApi.getWebrootRenewUrl(props.sub.keyCode)).data.redirectUrl;
      }
      window.open(url, "_self");
    }
  } catch (err) {
    notificationsStore.addNotification({ type: "ServerErrors" });
    logException(err as Error);
  }
}

function computerName() {
  return props.sub.computer?.description ?? props.sub.computer?.name;
}

function isOnlyKeycode() {
  return (
    props.sub.keyCode &&
    props.sub.sku &&
    carboniteProducts.filter(product => product.sku === props.sub.sku).length === 0
  );
}

function showSafeUpgradeLink(): boolean {
  const upgradeSku = getUpgradeSku(props.sub);
  const isUpgradableSku = !!upgradeSku;
  let showLink = false;

  if (
    (!props.sub.resellerId || props.sub.isPartnerReferral) &&
    !props.sub.isTrial &&
    isUpgradableSku &&
    props.sub.computer &&
    props.sub.computer.id > 0 &&
    !props.sub.isSafeLite
  ) {
    if (props.sub.isEvergreenMonthly) {
      showLink = !props.sub.isCancelledEvergreenSubscription && isSafeSubscriptionClosed(props.sub);
    } else {
      showLink = !isSafeSubscriptionClosed(props.sub);
    }
  }

  return showLink;
}

function showWebrootUpgradeLink(): boolean {
  if (
    isExpiredAllstateWithNewSubscription(props.sub) ||
    props.sub.isTrial ||
    isNotRenewableOrUpgradeable(props.sub) ||
    props.sub.isPlanetOt
  )
    return false;

  // Disable upgrade for Legacy Webroot
  if (
    props.sub.keyCodeSellerType === KeyCodeSeller.Safe &&
    props.sub.licenseCategoryName === webrootLicenseCategories.WSAV &&
    EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY.includes(props.sub.licenseCategoryName ?? "") &&
    FLAGS.ENABLE_SAFE_WEBROOT_MIGRATION === "true"
  ) {
    return false;
  }

  return (
    props.sub.keyCodeSellerType === KeyCodeSeller.Webroot &&
    props.sub.licenseCategoryName !== webrootLicenseCategories.WSCB && // remove Upgrade link from Chromebook (WSCB)
    !EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY.includes(props.sub.licenseCategoryName ?? "") && // remove Upgrade link from WW ADP & WSASB
    !isNotActiveYet(props.sub)
  );
}

function isSafePlusOrPrime() {
  return (
    props.sub.sku &&
    carboniteProducts.filter(product => product.sku !== "PersonalBasic").some(product => product.sku === props.sub.sku)
  );
}

function showKeycodeForTrial(): boolean {
  return props.sub.isTrial && !!props.sub.keyCode && props.sub.keyCodeSellerType === KeyCodeSeller.Safe;
}

/**
 * Gets the SKU of the product that is one tier higher than the provided subscription's SKU
 */
function getUpgradeSku(subscription: ISubscription): string | undefined {
  let upgradeSku: string | undefined = undefined;

  const currentSku = carboniteProducts.find(product => product.sku === subscription?.sku);

  if (currentSku) {
    const nextProduct = carboniteProducts.find(product => product.upgradeOrder === currentSku.upgradeOrder + 1);

    upgradeSku = nextProduct?.sku;
  }

  return upgradeSku;
}

async function redirectToBuyFlow(sub: ISubscription) {
  if (sub.sku && sub.subscriptionMonths) {
    buyFlowStore.changingFromMonthly = sub.isEvergreenMonthly;
    if (sub.computer?.id) {
      router.push({
        name: "selectPlan",
        query: {
          deviceId: sub.computer?.id,
          ratePlanId: sub.ratePlanId,
          subscriptionNumber: sub.zuoraSubscriptionNumber,
        },
      });
    } else {
      router.push({
        name: "selectPlan",
        query: {
          sku: sub.sku,
          ratePlanId: sub.ratePlanId,
          deviceId: sub.computer?.id,
          subscriptionNumber: sub.zuoraSubscriptionNumber,
        },
      });
    }
  }
}

function showUpgradeRenewLink() {
  return (
    ((allstateLCNs.includes(props.sub.licenseCategoryName ?? "") &&
      canDisplayGlobalFeature(globalFeatures.allStateUpgrade, props.sub?.webrootLicenseDistributionMethodCode)) ||
      (!allstateLCNs.includes(props.sub.licenseCategoryName ?? "") &&
        canDisplayPerSubscriptionFeature(
          perSubscriptionFeatures.changePlan,
          retailCodesMapper[props.sub.keyCodeSellerType || ""]
        ))) &&
    showWebrootUpgradeLink() &&
    isOnlyKeycode() &&
    props.sub.displayKeycode
  );
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.icon-name-wrapper {
  display: flex;
  align-content: flex-start;
}
.icon-name-wrapper div li {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  margin-right: var(--space-quarter);
  height: var(--icon-sm);
  width: var(--icon-sm);
}
.plus-prime-keycode {
  margin-top: var(--space);
}
@media (min-width: 600px) {
  td {
    width: 50%;
  }
}
</style>
