<template>
  <div v-if="showSpinner" class="credit-cards">
    <LoadingSpinner />
  </div>
  <div v-else-if="user?.subscriberInfo?.isUnderMaintenance">
    <UnderMaintenanceComponent />
  </div>
  <div v-else>
    <div class="subscription-information">
      <div v-if="pageState === subscriptionPageState.default" class="content">
        <PrimaryEmailVerificationWrapperComponent
          notification-context="SubscriptionInformationComponent"
          :email="email"
        />
        <section>
          <div>
            <h1>{{ tn("subscriptionsHeading") }}</h1>
          </div>
          <div v-if="subscriptions.length > 0" class="table-to-list-wrapper">
            <table>
              <thead>
                <tr>
                  <th class="sortable" :class="sortDirection.sku" @click="sortSubscriptions('sku')">
                    {{ tn("productCol") }}
                  </th>
                  <th class="sortable" :class="sortDirection.cardType" @click="sortSubscriptions('cardType')">
                    {{ tn("paymentMethodCol") }}
                  </th>
                  <th
                    class="sortable"
                    :class="sortDirection.customExpirationDate"
                    @click="sortSubscriptions('customExpirationDate')"
                  >
                    {{ tn("nextPaymentDateCol") }}
                  </th>
                  <th>{{ tn("manageCol") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(s, i) in filteredSubscriptions" :key="i" :class="addClassToRow(s)">
                  <td :data-th="tn('productCol')">
                    <ProductColumnComponent
                      :sub="s"
                      @buy-safe-sub="buyNowSafe"
                      @go-to-outstanding-invoice-page="goToOutstandingInvoicePage"
                    />
                  </td>
                  <td :data-th="tn('paymentMethodCol')">
                    <PaymentMethodColumnComponent
                      :sub="s"
                      @subscription-page-state-change="setPageState"
                      @set-current-subscription="setCurrentSubscription"
                    />
                  </td>
                  <td :data-th="tn('nextPaymentDateCol')">
                    <NextPaymentColumnComponent :sub="s" />
                  </td>
                  <td class="no-marker">
                    <ManageSubscriptionColumnComponent
                      :sub="s"
                      :display-forced-auto-renew-modal="!!s.computer && s.computer.id === computerIdToAutoRenew"
                      :payment-methods="paymentMethods"
                      @show-auto-renew-failed="showAutoRenewFailed"
                      @move-payment-method-mr-to-ar="handleMoveFromMRtoAR"
                      @cancel-subscription-state="setPageState"
                      @change-payment-method="handleChangePaymentMethod"
                      @renew-safe="renewSafe"
                      @buy-safe-sub="buyNowSafe"
                      @auto-renew-updated="showAutoRenewSuccess"
                      @auto-renew-discount-enabled="showAutoRenewDiscountEnabledNotification"
                      @set-current-subscription="setCurrentSubscription"
                      @add-payment-method="addCard"
                      @go-to-outstanding-invoice-page="goToOutstandingInvoicePage"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else class="message-box">
            {{ tn("noDataToDisplay") }}
          </div>
        </section>

        <section v-if="hasOutstandingInvoice">
          <OutstandingInvoiceAlert :subscriptions="outStandingSubscriptions" @renew-sub="moveToOutstandingInvoice" />
        </section>

        <section>
          <div>
            <h1>{{ tn("accountServicesHeading") }}</h1>
          </div>
          <div>
            <ul>
              <li class="mb">
                <button id="btnApplyAC" class="btn-link" @click="toggleApplyActivationCodeModal('click')">
                  {{ tn("applyACLink") }}
                </button>
              </li>
              <li v-if="vatInfo?.isVatSubscriber && vatInfoChanged">
                <div v-if="vatInfo.vatNumber !== null">
                  <div>{{ tn("vatIdNumber") }}: {{ vatInfo?.vatNumber }}</div>
                  <button id="btnAddVat" class="btn-link" @click="toggleAddVatNumberModal('edit')">
                    {{ tn("edit") }}
                  </button>
                  -
                  <button id="btnRemoveVat" class="btn-link" :disabled="isRemovingVat" @click="removeVatNumber()">
                    <spinner :is-spinning="isRemovingVat" />
                    {{ t("Common.remove") }}
                  </button>
                </div>
                <button v-else id="btnVatIdNumber" class="btn-link" @click="toggleAddVatNumberModal('vatIdNumber')">
                  {{ tn("vatIdNumber") }}
                </button>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <div v-if="pageState === subscriptionPageState.paymentMethodDetails" class="content">
        <div v-if="isCurrentSubscriptionWebroot">
          <h1>
            <button class="inline-btn-link" @click="pageState = subscriptionPageState.default">
              <svg-icon-component icon="chevron-left" class="icon-sm" />
            </button>
            {{ tn("selectPaymentMethod") }}
          </h1>
          <p>{{ tn("paymentDetailsHeaderWebroot") }}</p>
          <SelectPayment
            :subscription="currentSubscription"
            :edit-card-from-subscription="true"
            :payment-methods="paymentMethods"
            :show-details="true"
            :btn-text="t('Common.save')"
            :is-auto-renew="false"
            :show-cancel-button="true"
            :brand="openTextBrands.Webroot"
            :is-edit-payment-method="true"
            @edit-payment-method="editPaymentMethodHandler"
            @add-card="addCard"
            @reset-subscription-state="setPageState"
          />
        </div>
        <div v-else>
          <h1>
            <button class="inline-btn-link" @click="pageState = subscriptionPageState.default">
              <svg-icon-component icon="chevron-left" class="icon-sm" />
            </button>
            {{ tn("paymentDetails") }}
          </h1>
          <p>{{ tn("paymentDetailsHeaderCarb") }}</p>
          <SafeProductsInfoComponent :subscriptions="subscriptions" />
          <div class="card-credit">
            <CardCreditComponent :card="defaultCreditCard" />
          </div>
          <div>
            <button
              type="button"
              class="btn-outline-primary"
              @click="setPageStateFromPaymentDetails('editPaymentMethod', 'paymentMethodDetails')"
            >
              {{ tn("changePaymentMethod") }}
            </button>
          </div>
        </div>
        <i18n-t keypath="SubscriptionInformationComponent.note1" tag="p" class="mt" scope="global">
          <template #change>
            <strong>{{ tn("changePaymentMethod") }}</strong>
          </template>
        </i18n-t>
      </div>
      <div
        v-if="
          pageState === subscriptionPageState.addPaymentMethod || pageState === subscriptionPageState.editPaymentMethod
        "
        class="content"
      >
        <h1>
          <button class="inline-btn-link" @click="cancelAddPayment(addPaymentPreviousState)">
            <svg-icon-component icon="chevron-left" class="icon-sm" />
          </button>
          {{ tn("addPaymentMethod") }}
        </h1>
        <div v-if="!isCurrentSubscriptionWebroot">
          {{ tn("addPaymentHeader") }}
          <SafeProductsInfoComponent :subscriptions="subscriptions" />
        </div>
        <PaymentTypeSelector v-if="isCurrentSubscriptionWebroot" @change-payment-type="setPaymentType" />
        <div v-if="currentPaymentType == paymentTypes.PayPal" class="mt">
          <button id="btnPaypalCheckout" type="button" class="btn-shell" @click="initiatePayPalRequest()">
            <svg-icon-component icon="paypal-button" class="paypal-svg-175" />
          </button>
        </div>
        <div v-else-if="currentPaymentType == paymentTypes.CreditCard">
          <AddCreditCardComponent
            :sub="currentSubscription"
            :is-auto-renew="
              addPaymentPreviousState === subscriptionPageState.movePaymentMethodMRToAR || isAutoRenewEnabled()
            "
            :is-edit-payment="pageState === subscriptionPageState.editPaymentMethod"
            :credit-card-previous-state="addPaymentPreviousState"
            :submit-btn-text="t('Common.save')"
            :brand="currentSubscriptionBrand"
            @edit-payment="editPaymentMethodHandler"
            @auto-renew-payment-method-added="showAutoRenewSuccess"
            @subscription-page-state-change="setPageState"
            @payment-method-added="addPaymentMethodHandler"
          />
        </div>
      </div>
      <div v-if="pageState === subscriptionPageState.movePaymentMethodMRToAR" class="content">
        <div v-if="paymentMethods.length > 0">
          <h1>
            <button class="inline-btn-link" @click="cancelAddPayment(subscriptionPageState.default)">
              <svg-icon-component icon="chevron-left" class="icon-sm" />
            </button>
            {{ tn("changePaymentMethod") }}
          </h1>
          <p v-if="isCurrentSubscriptionWebroot" class="mb">{{ tn("paymentDetailsHeaderWebroot") }}</p>
          <p v-else class="mb">{{ tn("paymentDetailsHeaderCarb") }}</p>
          <SelectPayment
            :payment-methods="paymentMethods"
            :subscription="currentSubscription"
            :show-details="true"
            :btn-text="t('Common.save')"
            :is-auto-renew="shouldAutoRenew()"
            :show-cancel-button="true"
            :brand="autoRenewalStore.selectedBrandForAutoRenewal"
            @add-card="addCard"
            @set-auto-renew="enableAutoRenew"
            @reset-subscription-state="setPageState"
          />
        </div>
        <div v-else>
          <h1>
            <button class="inline-btn-link" @click="cancelAddPayment(subscriptionPageState.default)">
              <svg-icon-component icon="chevron-left" class="icon-sm" />
            </button>
            {{ tn("addPaymentMethod") }}
          </h1>
          <div v-if="!isCurrentSubscriptionWebroot">
            {{ tn("addPaymentHeader") }}
            <SafeProductsInfoComponent :subscriptions="subscriptions" />
          </div>
          <PaymentTypeSelector v-if="isCurrentSubscriptionWebroot" @change-payment-type="setPaymentType" />
          <div v-if="currentPaymentType == paymentTypes.PayPal" class="mt">
            <button id="btnPaypalCheckout" type="button" class="btn-shell" @click="initiatePayPalRequest()">
              <svg-icon-component icon="paypal-button" class="paypal-svg-175" />
            </button>
          </div>
          <div v-else-if="currentPaymentType == paymentTypes.CreditCard">
            <AddCreditCardComponent
              :sub="currentSubscription"
              :brand="currentSubscriptionBrand"
              :is-auto-renew="shouldAutoRenew()"
              :credit-card-previous-state="
                paymentMethods.length > 0 ? addPaymentPreviousState : subscriptionPageState.default
              "
              @set-auto-renew="enableAutoRenew"
              @subscription-page-state-change="setPageState"
              @payment-method-added="addPaymentMethodHandler"
            />
          </div>
        </div>
      </div>
    </div>
    <InvoiceDetailsComponent
      v-if="pageState === subscriptionPageState.invoiceDetails"
      :payment-methods="paymentMethods"
      :subscriptions="subscriptions"
      :brand="currentSubscriptionBrand"
      @success="handleSuccess"
      @subscription-page-state-change="setPageState"
      @updated-payment-methods="updatePaymentMethods"
    />
  </div>
  <AddVatNumberComponent
    :show-dialog="showAddVatNumberModal"
    :vat-info="vatInfo"
    @close-modal="toggleAddVatNumberModal"
    @save-modal="addVatNumber"
  />
  <ApplyActivationCodeComponent
    :show-dialog="showApplyActivationCodeModal"
    @close-modal="toggleApplyActivationCodeModal"
    @save-modal="saveApplyActivationCodeModal"
  />

  <BestBuySupportSectionComponent v-if="hasBestBuySub" class="ml" />
  <LoadingBoxComponent
    :show-dialog="buyFlowStore.showProcessingOrderMessage"
    :message="tn('changePaymentProcessingMessage')"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted, watch, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import i18n, { t } from "@/i18n";
import { unifiedApi } from "@/common";
import {
  ISubscription,
  IPaymentMethod,
  IAutoRenewUpdateRequest,
  ISubscriberVatInfo,
  IActivationCodeDialogResponse,
  IGetPaymentMethodsResponse,
  IAutoRenewRequest,
  IPayOutstandingInvoicesRequest,
} from "@/common/api/unifiedPortal/interfaces";
import { useUserStore } from "@/stores/user";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import { PAYPAL_CARD_TYPE, SafeDataPurgeDays } from "@/define";
import { pageState as subscriptionPageState, ExpirationState, KeyCodeSeller, payPalCheckOutPage } from "@/globalEnums";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { carboniteSkus, getProductDisplayNameBySku, IProductDisplayRequest } from "@/common/productsBySku";
import {
  isSafeSubscriptionClosed,
  showWarning,
  showDanger,
  isProcessingRenewal,
  isNotActiveYet,
  processSubscriptionDataForDisplay,
  isAllstateSubscription,
  hideExpiredTrial,
} from "@/common/subscriptionHelper";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import Spinner from "@/components/shared/Spinner.vue";
import LoadingBoxComponent from "@/components/shared/LoadingBox.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import ManageSubscriptionColumnComponent from "@/components/Subscriptions/ManageSubscriptionColumn.vue";
import ProductColumnComponent from "@/components/Subscriptions/ProductColumn.vue";
import NextPaymentColumnComponent from "@/components/Subscriptions/NextPaymentColumn.vue";
import PaymentMethodColumnComponent from "@/components/Subscriptions/PaymentMethodColumn.vue";
import SafeProductsInfoComponent from "@/components/Subscriptions/SafeProductsInfo.vue";
import OutstandingInvoiceAlert from "@/components/Subscriptions/OutstandingInvoiceAlert.vue";
import CardCreditComponent from "@/components/CreditCards/CardCredit.vue";
import AddCreditCardComponent from "@/components/CreditCards/AddCreditCard.vue";
import InvoiceDetailsComponent from "@/components/Subscriptions/InvoiceDetails.vue";
import BestBuySupportSectionComponent from "@/components/shared/BestBuySupportSection.vue";
import AddVatNumberComponent from "@/components/shared/dialogs/AddVatNumber/AddVatNumber.vue";
import ApplyActivationCodeComponent from "@/components/shared/dialogs/ApplyActivationCode/ApplyActivationCode.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import { clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";
import { getUserDetailsFromStore } from "@/common/userDetails";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { openTextBrands, pageStates, paymentTypes } from "@/components/Buy/BuyEnums";
import { FLAGS } from "@/define";
import { createPayPalAgreementToken } from "@/components/Buy/Payment/Payment";
import { isWebrootProduct } from "@/common/subscriptionHelper";
import PaymentTypeSelector from "@/components/Buy/Payment/PaymentTypeSelector.vue";
import { showAllstateBanner } from "@/components/Allstate/AllstateHelper";
import SelectPayment from "@/components/Buy/Payment/SelectPayment.vue";
import { useAutoRenewalStore } from "@/stores/autoRenewal";
import { usePayPalStore } from "@/stores/payPal";
import UnderMaintenanceComponent from "@/components/UnderMaintenance/UnderMaintenance.vue";

const componentName = "SubscriptionInformationComponent";

logEvent("created", componentName);

const filteredSubscriptions = computed(() => {
  //Get rid of expired Trials
  return subscriptions.value.filter(subscription => !hideExpiredTrial(subscription));
});
const userStore = useUserStore();
const buyFlowStore = useBuyFlowStore();
const notificationsStore = useNotificationsStore();
const subscriptionsStore = useSubscriptionsStore();
const siteSettingsStore = useSiteSettingsStore();
const autoRenewalStore = useAutoRenewalStore();
const payPalStore = usePayPalStore();

const isRemovingVat = ref<boolean>(false);
const subscriptions = ref<ISubscription[]>([]);
const outStandingSubscriptions = ref<ISubscription[]>([]);
const showSpinner = ref<boolean>(true);
const pageState = ref<subscriptionPageState>(subscriptionPageState.default);
const paymentMethods = ref<IPaymentMethod[]>([]);
const addPaymentPreviousState = ref<subscriptionPageState>(subscriptionPageState.default);
const defaultCreditCard = ref<IPaymentMethod | null>(null);
const currentSubscription = ref<ISubscription>();
const vatInfo = ref<ISubscriberVatInfo>();
const vatInfoChanged = ref<boolean>(true);
const computerIdToAutoRenew = ref<number>(-1);
const user = computed(() => userStore.currentUser);
const router = useRouter();
const showAddVatNumberModal = ref<boolean>(false);
const showApplyActivationCodeModal = ref<boolean>(false);
const sortColumn = ref<string>("");
const email = ref<string>("");
const currentPaymentType = ref<paymentTypes>(paymentTypes.CreditCard);
const sortDirection = ref<Record<string, unknown>>({
  sku: "ascend",
  cardType: "",
  customExpirationDate: "",
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const currentSubscriptionBrand = computed(() => {
  if (carboniteSkus.find(sku => sku === currentSubscription.value?.sku)) {
    return openTextBrands.Carbonite;
  }

  return openTextBrands.Webroot;
});

async function editPaymentMethodHandler(sub: ISubscription, paymentMethodId: string) {
  logEvent("editPaymentMethodHandler", componentName);
  try {
    const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
      allowAutoRenew: sub.isAutoRenewEnabled,
      surveyChoice: "",
      zuoraSubscriptionNumber: sub.zuoraSubscriptionNumber,
      surveyChoiceCode: null,
      paymentMethodId: paymentMethodId,
    };

    await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest);
    //If actions happen we need to force the store to refresh so we are't left with stale data
    await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
    subscriptions.value = processData(subscriptionsStore.subscriptions);
    notificationsStore.clearNotifications();
    buyFlowStore.showProcessingOrderMessage = false;
    pageState.value = subscriptionPageState.default;
    logEvent("page state change", subscriptionPageState.default);
    setPaymentMethods();
    notificationsStore.addNotification({ type: "AddCreditCardSuccess" });
    pageState.value = subscriptionPageState.default;
  } catch (error) {
    buyFlowStore.showProcessingOrderMessage = false;
    logException(error as Error);
  }
}
function addCard(isEditPayment = false) {
  if (isEditPayment) {
    pageState.value = subscriptionPageState.editPaymentMethod;
  } else {
    pageState.value = subscriptionPageState.addPaymentMethod;
  }
}

async function enableAutoRenew(paymentMethodId: string) {
  try {
    if (currentSubscription.value !== undefined) {
      const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
        allowAutoRenew: true,
        zuoraSubscriptionNumber: currentSubscription.value.zuoraSubscriptionNumber,
        surveyChoice: "",
        surveyChoiceCode: null,
        paymentMethodId: paymentMethodId,
      };
      const updated = (await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest)).data;
      if (updated) {
        await getUserDetailsFromStore(true);
        await showAutoRenewSuccess(currentSubscription?.value);
      }
    }
  } catch (error) {
    handleApiError(error as AxiosError);
  } finally {
    pageState.value = subscriptionPageState.default;
  }
}

function toggleAddVatNumberModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleAddVatNumberModal",
    componentName,
    `${showAddVatNumberModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  return (showAddVatNumberModal.value = !showAddVatNumberModal.value);
}

function toggleApplyActivationCodeModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleApplyActivationCodeModal",
    componentName,
    `${showApplyActivationCodeModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  return (showApplyActivationCodeModal.value = !showApplyActivationCodeModal.value);
}

const hasOutstandingInvoice = computed(() => {
  return outStandingSubscriptions.value.length > 0;
});

function getOutstandingSubscriptions() {
  outStandingSubscriptions.value = [];
  subscriptions.value.forEach(sub => {
    if (sub.hasOutstandingInvoices && sub.outstandingInvoice) {
      outStandingSubscriptions.value.push(sub);
    }
  });
}

const hasBestBuySub = computed(() => {
  return !!user.value?.subscriberInfo?.hasBestBuyS2;
});

onBeforeMount(async () => {
  logEvent("before mounted", componentName);
  email.value = user.value?.email ?? "";

  if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  }
  //Auto renew Error message on failure
  var autoRenewResponse = autoRenewalStore.getAutoRenewResponse();
  if (autoRenewResponse?.status == false && autoRenewResponse?.message == "AllStateNonUs") {
    notificationsStore.addNotification({ type: "AllstateNonUS" });
    //clear the store value for making this ready for next user event in UI
    autoRenewalStore.setAutoRenewResponse({ status: true });
  }
  try {
    await Promise.all([
      getSubscriptionsForDisplay(),
      setPaymentMethods(),
      getConsumerVatInfo(),
      buyFlowStore.populatePlans(),
    ]);
    if (autoRenewResponse?.status == true) {
      const autoRenewRequest = autoRenewalStore.getAutoRenewRequestParameters;
      const currentSubscription = subscriptions.value.find(
        s => s.zuoraSubscriptionNumber === autoRenewRequest?.zuoraSubscriptionNumber
      );
      if (currentSubscription) {
        showAutoRenewSuccess(currentSubscription);
      }
    }
    getOutstandingSubscriptions();
    showSpinner.value = false;
    manageOutstandingInvoiceUrl();
  } catch (error) {
    handleApiError(error as AxiosError, false);
  }
});

onMounted(() => {
  //Check if there was an error during the paypal flow
  //Checking this in the onMounted will allow it to bypass all the clearNotifications() calls we do everywhere
  if (payPalStore.purchaseError) {
    notificationsStore.addNotification({
      type: `${PAYPAL_CARD_TYPE}${payPalStore.purchaseError}` as NotificationType,
      dismissible: false,
    });

    //Clear the error from the store
    payPalStore.purchaseError = null;
  }
});

onUnmounted(() => {
  clearRefreshFunctions();
});

async function getSubscriptionsForDisplay() {
  await subscriptionsStore.populateSubscriptions();
  subscriptions.value = processData(subscriptionsStore.subscriptions);
  sortSubscriptions("sku");
  processNotifications();
}

async function setPaymentMethods() {
  logEvent("setPaymentMethods", componentName);
  try {
    const response: IGetPaymentMethodsResponse = (await unifiedApi.getUserPaymentMethods()).data;
    paymentMethods.value = response.paymentMethods;
    defaultCreditCard.value =
      paymentMethods.value !== null && paymentMethods.value.length > 0
        ? (paymentMethods.value.find(card => card.defaultPaymentMethod) ?? null)
        : null;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function showOutstandingInvoiceSection(subscription: ISubscription) {
  return subscription.hasOutstandingInvoices && subscription.outstandingInvoice;
}

function buyNowSafe(subscription: ISubscription, sku: string | undefined = undefined) {
  if (showOutstandingInvoiceSection(subscription)) {
    setPageState("invoiceDetails");
    return;
  }

  buySafe(subscription, sku);
}

//Used for trials and upgrades. Goes to new VD buyflow
function buySafe(sub: ISubscription, sku?: string) {
  buyFlowStore.changingFromMonthly = sub.isEvergreenMonthly;

  router.push({
    name: "selectPlan",
    query: {
      sku: sku ?? "",
      deviceId: sub.computer?.id,
      subscriptionNumber: sub.zuoraSubscriptionNumber,
    },
  });
}

function moveToOutstandingInvoice(sub: ISubscription) {
  if (showOutstandingInvoiceSection(sub)) {
    currentSubscription.value = sub;
    setPageState("invoiceDetails");
    return;
  }
}

function goToOutstandingInvoicePage(sub: ISubscription) {
  currentSubscription.value = sub;
  setPageState("invoiceDetails");
}

function renewSafe(sub: ISubscription) {
  if (showOutstandingInvoiceSection(sub)) {
    goToOutstandingInvoicePage(sub);
    return;
  }

  router.push({
    name: "buy",
    query: {
      step: pageStates.payment,
      deviceId: sub.computer?.id,
      subscriptionNumber: sub.zuoraSubscriptionNumber,
    },
  });
}

function handleChangePaymentMethod() {
  setPageStateFromPaymentDetails("addPaymentMethod", "paymentMethodDetails");
}

function handleMoveFromMRtoAR(sub: ISubscription) {
  logEvent("page state change", subscriptionPageState.movePaymentMethodMRToAR);
  pageState.value = subscriptionPageState.movePaymentMethodMRToAR;
  //this needs to be set in case the user wants to add a new CC
  setAddPaymentPreviousState(subscriptionPageState.movePaymentMethodMRToAR);
  setCurrentSubscription(sub);
}

async function addPaymentMethodHandler(sub: ISubscription, paymentMethodId: string) {
  logEvent("addPaymentMethodHandler", componentName);
  notificationsStore.clearNotifications();
  if (
    pageState.value === subscriptionPageState.movePaymentMethodMRToAR ||
    addPaymentPreviousState.value === subscriptionPageState.movePaymentMethodMRToAR
  ) {
    try {
      const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
        allowAutoRenew: !sub.isAutoRenewEnabled,
        surveyChoice: "",
        zuoraSubscriptionNumber: sub.zuoraSubscriptionNumber,
        surveyChoiceCode: null,
        paymentMethodId: paymentMethodId,
      };

      await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest);
      //If actions happen we need to force the store to refresh so we are't left with stale data
      await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
      subscriptions.value = processData(subscriptionsStore.subscriptions);

      const request: IProductDisplayRequest = {
        sku: sub.sku,
        webrootKeycode: sub.keyCode ?? undefined,
        isTrial: sub.isTrial,
      };

      const productDisplayName = i18n.global.t(getProductDisplayNameBySku(request)).toString();

      notificationsStore.addNotification({
        type: "MovePaymentMethodMRToAR",
        params: { carbonitePlan: productDisplayName },
      });
    } catch (error) {
      buyFlowStore.showProcessingOrderMessage = false;
      logException(error as Error);
      notificationsStore.addNotification({ type: "AutoRenewTurnOnOrOffFailure" });
    }
  } else {
    //If actions happen we need to force the store to refresh so we aren't left with stale data
    await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
    subscriptions.value = processData(subscriptionsStore.subscriptions);
    notificationsStore.addNotification({ type: "AddCreditCardSuccess" });
    //after success , update the vat info section as well
    await getConsumerVatInfo();
  }
  buyFlowStore.showProcessingOrderMessage = false;
  pageState.value = subscriptionPageState.default;
  addPaymentPreviousState.value = subscriptionPageState.default;
  logEvent("page state change", subscriptionPageState.default);
  setPaymentMethods();
}

async function cancelAddPayment(state: string) {
  sessionStorage.removeItem("product_name_for_auto_renew");
  await setPageState(state);
}

async function setPageState(state: string) {
  notificationsStore.clearNotifications();
  pageState.value = subscriptionPageState[state];
  logEvent("page state change", subscriptionPageState[state]);
  //if coming back to default page then show banners just like at the start of page.
  if (state === subscriptionPageState.default) {
    processNotifications();
  } else if (state === subscriptionPageState.cancelSubscriptionFailure) {
    notificationsStore.addNotification({ type: "CancelSubscriptionFailure" });
    pageState.value = subscriptionPageState.default;
    logEvent("page state change", subscriptionPageState.default);
  } else if (state === subscriptionPageState.cancelSubscriptionSuccess) {
    logEvent("cancelSubscriptionSuccess");
    showSpinner.value = true;
    try {
      //If actions happen we need to force the store to refresh so we are't left with stale data
      await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
      subscriptions.value = processData(subscriptionsStore.subscriptions);
      showSpinner.value = false;
      notificationsStore.addNotification({ type: "CancelSubscriptionSuccess" });
      pageState.value = subscriptionPageState.default;
      logEvent("page state change", subscriptionPageState.default);
    } catch (err) {
      logException(err as Error);
    }
  }
}

async function showAutoRenewDiscountEnabledNotification(sub: ISubscription) {
  logEvent("showAutoRenewDiscountEnabledNotification", componentName);
  notificationsStore.clearNotifications();
  try {
    showSpinner.value = true;
    //If actions happen we need to force the store to refresh so we are't left with stale data
    await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
    subscriptions.value = processData(subscriptionsStore.subscriptions);
    const refreshedSubscription = subscriptions.value.find(
      l => l.zuoraSubscriptionNumber === sub.zuoraSubscriptionNumber
    );
    showSpinner.value = false;
    notificationsStore.addNotification({
      type: "AutoRenewDiscountEnabled",
      params: { renewalDiscount: refreshedSubscription?.renewalDiscount },
    });
  } catch (err) {
    logException(err as Error);
  }
}

async function showAutoRenewSuccess(sub: ISubscription) {
  logEvent("showAutoRenewSuccess", componentName);
  notificationsStore.clearNotifications();

  try {
    showSpinner.value = true;
    //If actions happen we need to force the store to refresh so we are't left with stale data
    await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
    subscriptions.value = processData(subscriptionsStore.subscriptions);
    showSpinner.value = false;

    const request: IProductDisplayRequest = {
      sku: sub?.sku || sub?.licenseCategoryName || "",
      webrootKeycode: sub.keyCode ?? undefined,
      isTrial: sub.isTrial,
    };

    const productDisplayName = i18n.global.t(getProductDisplayNameBySku(request)).toString();
    if (!sub.isAutoRenewEnabled) {
      notificationsStore.addNotification({
        type: "AutoRenewTurnOnSuccess",
        params: { carbonitePlan: productDisplayName },
      });
    } else {
      notificationsStore.addNotification({
        type: "AutoRenewTurnOffSuccess",
        params: { carbonitePlan: productDisplayName },
      });
    }
  } catch (err) {
    logException(err as Error);
  }
}

function showAutoRenewFailed() {
  logEvent("AutoRenewTurnOnOrOffFailure");
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: "AutoRenewTurnOnOrOffFailure" });
}

function setAddPaymentPreviousState(state: string) {
  addPaymentPreviousState.value = subscriptionPageState[state];
}

function setPageStateFromPaymentDetails(state: string, previousState: string) {
  setPageState(state);
  setAddPaymentPreviousState(previousState);
}

function addClassToRow(subscription: ISubscription): string {
  let className = "";
  if (showDanger(subscription)) {
    className = "row-danger";
  } else if (showWarning(subscription)) {
    className = "row-warning";
  }

  return className;
}

async function initiatePayPalRequest() {
  buyFlowStore.showProcessingOrderMessage = true;
  logEvent("Initiating paypal transaction", componentName);
  try {
    const paypalAgreementTokenResponse = await createPayPalAgreementToken();
    if (paypalAgreementTokenResponse && currentSubscription.value != null) {
      logEvent("Got the paypal agreement token, redirecting to paypal approval url", componentName);
      //set values to auto renew store
      const autoRenewRequest: IAutoRenewRequest = {
        allowAutoRenew:
          pageState.value === subscriptionPageState.editPaymentMethod
            ? currentSubscription.value?.isAutoRenewEnabled
            : !currentSubscription.value?.isAutoRenewEnabled,
        zuoraSubscriptionNumber: currentSubscription.value?.zuoraSubscriptionNumber,
        //paymentMethodId: This will be set in the paypal approval once redirect back from paypal portal
        //reason:  //TODO,
        //reasonCode:  //TODO,
      };
      // indicate that this is an edit payment, not an auto renew
      sessionStorage.setItem(
        "isEditPayment",
        pageState.value === subscriptionPageState.editPaymentMethod ? "true" : "false"
      );
      // store product name so the notification has access to it
      sessionStorage.setItem("product_name_for_auto_renew", currentSubscription.value.productDisplayName ?? "");
      //store in session since the redirect to paypal doesn't persist data in pinia store
      sessionStorage.setItem("subscription_autoRenewRequest", JSON.stringify(autoRenewRequest));
      //set paypal checkout from page in since the redirect to paypal doesn't persist data in pinia store
      sessionStorage.setItem("PayPal_CheckOutPage", payPalCheckOutPage.SubscriptionInformation);

      //set paypal token id since the redirect to paypal doesn't persist data in pinia store
      sessionStorage.setItem("PayPal_token_id", paypalAgreementTokenResponse.tokenId);
      window.location.href = paypalAgreementTokenResponse.approvalUrl;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  } finally {
    buyFlowStore.showProcessingOrderMessage = false;
  }
}
/**
 * Check all the subscriptions for subs that need a notification
 */
function processNotifications() {
  notificationsStore.clearNotifications();
  if (subscriptions.value.length === 0) {
    return;
  }
  //We only want to show the highest priority notifications
  // notification for editing payment from the manage payment column of the subscriptions table
  if (autoRenewalStore.isEditPayment) {
    notificationsStore.addNotification({
      type: "AddCreditCardSuccess",
    });
  }
  // notification for adding payment from the auto renew flow
  else if (
    sessionStorage.getItem("product_name_for_auto_renew") &&
    sessionStorage.getItem("subscription_autoRenewRequest")
  ) {
    const autoRenewRequest: IAutoRenewRequest = JSON.parse(sessionStorage["subscription_autoRenewRequest"]);
    if (
      subscriptions.value.find(x => x.zuoraSubscriptionNumber == autoRenewRequest.zuoraSubscriptionNumber)
        ?.isAutoRenewEnabled
    ) {
      notificationsStore.addNotification({
        type: "AutoRenewTurnOnSuccess",
        // "product_name_for_auto_renew" is set to props.subscription.productDisplayName
        params: { carbonitePlan: sessionStorage.getItem("product_name_for_auto_renew") || undefined },
      });
      autoRenewalStore.isEditPayment = false;
      sessionStorage.removeItem("product_name_for_auto_renew");
    }
  }

  // Allstate upgrade banner displays if More than 90 days from sub expiring and api returns showUpgradedBanner and flag is set to show allstate family
  showAllstateBanner();

  //Amazon Webroot, Only show banner for first 30 days of expiration
  if (
    subscriptions.value.some(
      l => l.keyCodeSellerType === KeyCodeSeller.Amazon && l.expiration === ExpirationState.Expired
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Best Buy S2/TTS Webroot subs, Only show banner for first 30 days of expiration
  if (
    subscriptions.value.some(
      l => l.keyCodeSellerType === KeyCodeSeller.BestBuy && l.expiration === ExpirationState.Expired
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //outstanding Invoice
  if (hasOutstandingInvoice.value) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Evergreen Monthly
  if (subscriptions.value.some(l => l.isEvergreenMonthly && l.remainingDays <= 0 && !isSafeSubscriptionClosed(l))) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Direct Expired Banner
  if (
    subscriptions.value.some(
      l =>
        (!l.resellerId || l.isPartnerReferral) &&
        !l.isEvergreenMonthly &&
        !l.isTrial &&
        !isAllstateSubscription(l) &&
        ((!l.isAutoRenewEnabled && l.expiration === ExpirationState.Expired) ||
          (l.isAutoRenewEnabled &&
            !isProcessingRenewal(l) &&
            l.remainingDays < 0 &&
            l.remainingDays > SafeDataPurgeDays.Reseller) ||
          (l.computer?.remainingDaysToPurge && l.computer.remainingDaysToPurge > 0 && !l.computer?.dataHasBeenPurged))
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Direct Trial Expired Banner
  if (
    subscriptions.value.some(
      l =>
        (!l.resellerId || l.isPartnerReferral) &&
        l.isTrial &&
        l.remainingDays < 0 &&
        l.remainingDays > SafeDataPurgeDays.Trials
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Reseller Expired Banner at -29 days switch to direct message
  if (
    subscriptions.value.some(
      l =>
        l.resellerId &&
        l.resellerId > 0 &&
        !l.isPartnerReferral &&
        !l.isTrial &&
        ((l.remainingDays <= -29 && l.remainingDays > SafeDataPurgeDays.Reseller) ||
          (l.computer?.remainingDaysToPurge && l.computer.remainingDaysToPurge > 0 && !l.computer?.dataHasBeenPurged))
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Reseller Expired Banner
  if (
    subscriptions.value.some(
      l =>
        l.resellerId &&
        l.resellerId > 0 &&
        !l.isPartnerReferral &&
        !l.isTrial &&
        ((!l.isAutoRenewEnabled && l.expiration === ExpirationState.Expired) ||
          (l.isAutoRenewEnabled &&
            !isProcessingRenewal(l) &&
            l.remainingDays < 0 &&
            l.remainingDays > SafeDataPurgeDays.Reseller) ||
          (l.computer?.remainingDaysToPurge && l.computer.remainingDaysToPurge > 0 && !l.computer?.dataHasBeenPurged))
    )
  ) {
    notificationsStore.addNotification({ type: "ResellerSubscriptionsExpired", dismissible: false });
    return;
  }

  //Webroot Expired
  if (
    subscriptions.value.some(
      l =>
        l.keyCodeSellerType === KeyCodeSeller.Webroot &&
        !isNotActiveYet(l) &&
        (l.expiration === ExpirationState.Expired || l.expiration === ExpirationState.FinalWarning) &&
        !isAllstateSubscription(l)
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  //Allstate Expired
  if (
    subscriptions.value.some(
      l =>
        l.keyCodeSellerType === KeyCodeSeller.Webroot &&
        !isNotActiveYet(l) &&
        (l.expiration === ExpirationState.Expired || l.expiration === ExpirationState.FinalWarning) &&
        isAllstateSubscription(l)
    )
  ) {
    if (subscriptions.value.every(s => s.remainingDays <= 0 && isAllstateSubscription(s))) {
      notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
      return;
    }
  }

  //Reseller Trial Expired Banner
  if (
    subscriptions.value.some(
      l =>
        l.resellerId &&
        l.resellerId > 0 &&
        !l.isPartnerReferral &&
        l.isTrial &&
        l.remainingDays <= 0 &&
        l.remainingDays > SafeDataPurgeDays.Trials
    )
  ) {
    notificationsStore.addNotification({ type: "ResellerTrialSubscriptionsExpired", dismissible: false });
    return;
  }

  //Direct Expiring soon Banner
  if (
    subscriptions.value.some(
      l =>
        l.keyCodeSellerType !== KeyCodeSeller.BestBuy &&
        (!l.resellerId || l.isPartnerReferral) &&
        !l.isAutoRenewEnabled &&
        !l.isEvergreenMonthly &&
        !l.isTrial &&
        l.expiration === ExpirationState.Upcoming
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsAlmostExpired", dismissible: false });
    return;
  }

  //Reseller Expiring soon Banner
  if (
    subscriptions.value.some(
      l =>
        l.resellerId &&
        l.resellerId > 0 &&
        !l.isPartnerReferral &&
        !l.isTrial &&
        l.expiration === ExpirationState.Upcoming &&
        !l.isAutoRenewEnabled
    )
  ) {
    notificationsStore.addNotification({ type: "ResellerSubscriptionsAlmostExpired", dismissible: false });
    return;
  }

  //Direct Trial Expiring soon Banner
  if (
    subscriptions.value.some(
      l =>
        l.keyCodeSellerType !== KeyCodeSeller.BestBuy &&
        (!l.resellerId || l.isPartnerReferral) &&
        !l.isAutoRenewEnabled &&
        !l.isEvergreenMonthly &&
        l.isTrial &&
        l.expiration === ExpirationState.Upcoming
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionWillExpireTrial", dismissible: false });
    return;
  }

  //Reseller Trial Expiring soon Banner
  if (
    subscriptions.value.some(
      l =>
        l.resellerId &&
        l.resellerId > 0 &&
        !l.isPartnerReferral &&
        l.isTrial &&
        l.expiration === ExpirationState.Upcoming &&
        !l.isAutoRenewEnabled
    )
  ) {
    notificationsStore.addNotification({ type: "ResellerTrialSubscriptionsAlmostExpired", dismissible: false });
    return;
  }

  //Webroot expiring soon
  if (
    subscriptions.value.some(
      l =>
        l.keyCodeSellerType === KeyCodeSeller.Webroot &&
        !isNotActiveYet(l) &&
        l.expiration === ExpirationState.Upcoming &&
        !l.isAutoRenewEnabled
    )
  ) {
    notificationsStore.addNotification({ type: "SubscriptionsAlmostExpired", dismissible: false });
    return;
  }
}

/**
 * Add any post data retrieval processing for each sub
 */
function processData(subscriptions: ISubscription[]): ISubscription[] {
  const data = processSubscriptionDataForDisplay(subscriptions);
  manageAutoRenewUrl();
  return data ?? null;
}

async function handleSuccess() {
  notificationsStore.addNotification({ type: "RenewSuccessMessage", dismissible: false });
  pageState.value = subscriptionPageState.default;
  logEvent("page state change", subscriptionPageState.default);
  //If actions happen we need to force the store to refresh so we are't left with stale data
  await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
  subscriptions.value = processData(subscriptionsStore.subscriptions);
  //Refresh the outstanding invoice array so the alert section leaves
  getOutstandingSubscriptions();
}

async function saveApplyActivationCodeModal(response: IActivationCodeDialogResponse) {
  try {
    showSpinner.value = true;
    if (response?.type === "existingSub") {
      showSpinner.value = true;
      subscriptionsStore.forceRefresh();
      await getSubscriptionsForDisplay();
      notificationsStore.addNotification({ type: "ActivationCodeAppliedSuccess" });
    } else if (response?.type === "newSub") {
      //Applied to new computer, go to backup page to download the installer
      siteSettingsStore.activationCodeAppliedSuccess = true;
      siteSettingsStore.activationCodeToInstall = response?.activationCode;
      await getUserDetailsFromStore(true);
      router.push("/backup");
    }
    showSpinner.value = false;

    toggleApplyActivationCodeModal("saveApplyActivationCodeModal");
    await reloadSubscriberInfo();
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

async function updatePaymentMethods(updatedCreditCards: IPaymentMethod[]) {
  paymentMethods.value = updatedCreditCards;
}

async function getConsumerVatInfo() {
  try {
    logEvent("getConsumerVatInfo", componentName);
    vatInfo.value = (await unifiedApi.getConsumerVatInfoAsync(null)).data;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

async function addVatNumber(response: string) {
  logEvent("addVatNumber", componentName);
  if (response === "OK") {
    //reload vat info data
    await getConsumerVatInfo();
    refreshVatInfoSection();
  } else {
    logEvent("Failure in adding Vat Number", JSON.stringify(response));
  }

  toggleAddVatNumberModal("addVatNumber");
}

async function removeVatNumber() {
  isRemovingVat.value = true;

  try {
    const vatInfoResponse = (await unifiedApi.setVatInfo("", "")).data;
    if (vatInfoResponse.success) {
      if (vatInfo.value) vatInfo.value.vatNumber = null;
      refreshVatInfoSection();

      //reload vat info data
      await getConsumerVatInfo();
    } else {
      logEvent("Failure in removing Vat Number", vatInfoResponse.responseMessage);
    }
  } catch (err) {
    logException(err as Error);
  }
  isRemovingVat.value = false;
}

function refreshVatInfoSection() {
  vatInfoChanged.value = false;
  setTimeout(() => {
    vatInfoChanged.value = true;
  }, 10);
}

function manageAutoRenewUrl() {
  //cSpell:ignore autorenew
  // for url /subscriptions/computerId=$computerId$&action=autorenew will open autorenew model for the matching computer/subscription
  if (router.currentRoute.value.query.computerId) {
    const computerId: number = parseInt(router.currentRoute.value.query.computerId.toString());
    if (computerId > 0 && router.currentRoute.value.query.action?.toString().toLowerCase() === "autorenew") {
      computerIdToAutoRenew.value = computerId;
    }
  }
}

function manageOutstandingInvoiceUrl() {
  const queryString = router.currentRoute.value.query;
  //cSpell:ignore outstandinginvoice
  //if user has outstanding invoice then url /subscriptions?action=outstandinginvoice&paymentMethod=add will navigate directly to invoice details section
  if (hasOutstandingInvoice.value && queryString.action?.toString().toLowerCase() === "outstandinginvoice") {
    //Check if this is the return from PayPal because we need to set the current subscription
    const request = sessionStorage.getItem("PayPal_OutstandingInvoice_Request");
    if (request) {
      const parsedRequest = JSON.parse(request) as IPayOutstandingInvoicesRequest;
      //Try to find a sub that matches an invoice in the pay off request
      currentSubscription.value = subscriptions.value.find(l =>
        parsedRequest?.invoiceData?.find(i => i.invoiceNumber === l?.outstandingInvoice?.invoiceNumber)
      );
    }
    setPageState(subscriptionPageState.invoiceDetails);
  }
}

function setCurrentSubscription(sub: ISubscription) {
  currentSubscription.value = sub;
}

const isCurrentSubscriptionWebroot = computed(() => {
  return FLAGS.ENABLE_BUYFLOW_PAYPAL && currentSubscription.value && isWebrootProduct(currentSubscription.value);
});

function sortSubscriptions(field: string) {
  //turn off sorting indicator on old column
  if (sortColumn.value !== field) sortDirection.value[sortColumn.value] = "";

  //pick a direction for the sort
  if (sortDirection.value[field] === "ascend") sortDirection.value[field] = "descend";
  else if (sortDirection.value[field] === "descend") sortDirection.value[field] = "ascend";
  else sortDirection.value[field] = "ascend";

  const order = sortDirection.value[field] === "ascend" ? -1 : 1;

  if (subscriptions.value)
    subscriptions.value = subscriptions.value.sort((a: ISubscription, b: ISubscription) => {
      if (a[field] > b[field]) return order;
      if (a[field] < b[field]) return -order;
      return 0;
    });

  //remember the column being sorted
  sortColumn.value = field;
}

const language = computed(() => userStore.selectedLanguage);
watch(language, async () => {
  await getSubscriptionsForDisplay();
});

function setPaymentType(paymentType: paymentTypes) {
  currentPaymentType.value = paymentType;
}

function shouldAutoRenew() {
  return !(currentSubscription.value?.isAutoRenewEnabled ?? false);
}
function isAutoRenewEnabled() {
  return currentSubscription.value?.isAutoRenewEnabled ?? false;
}
</script>
