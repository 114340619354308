import { URLS } from "@/define";
import { AxiosRequestConfig } from "axios";
import { Buffer } from "buffer";
import { IEndpointConfig } from "../interfaces";
import {
  ISecurityQuestions,
  IUserSecurityPhoneRequest,
  IUpdateSubscriberRequest,
  IInstallRequest,
  IClientException,
  IUpdateWebrootDeviceRequest,
  IRemoveWebrootDeviceRequest,
  IAllstateRequest,
  ICourierRecoveryRequest,
  IManageCustomerByResellerRequest,
  IPayOutstandingInvoicesRequest,
  IAutoRenewUpdateRequest,
  IShoppingCart,
  ISetLanguagePreferenceRequest,
  ICheckEmailRequest,
  IPayPalAgreementTokenRequest,
  IActivationCodePurchaseRequest,
  IAllstateUpdateRequest,
  IAutoRenewRequest,
  IAutoRenewDiscountRequest,
} from "./interfaces";
import {
  ICssbDetailsRequest,
  ICssbDownloadInfoRequest,
  ICssbDownloadUrlRequest,
} from "@/components/FileAccess/interfaces";
import { generateInterstitialReturnPortalDestination } from "@/common/subscriptionHelper";

export function hostUrl(endpoint: IEndpointConfig) {
  return !endpoint
    ? "" // null value for endpoint means requests will be relative
    : `${endpoint.protocol || "http"}://${endpoint.hostname}${endpoint.port ? ":" + endpoint.port : ""}`;
}

export function postLoginRequest(
  endpoint: IEndpointConfig,
  email: string,
  password: string,
  recaptchaToken?: string
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/auth/login`,
    data: { email, password, recaptchaResponse: recaptchaToken },
  };
}

export function getUser(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me`,
  };
}

export function getUserDetails(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/account`,
  };
}

export function getUserPaymentMethods(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/payment/paymentMethods`,
  };
}

export function getUserPaymentMethodGuest(
  endpoint: IEndpointConfig,
  userGuid: string,
  paymentMethodId: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/payment/guestPaymentMethod/${userGuid}/${paymentMethodId}`,
  };
}

export function getSubscriberDownloadsInfo(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/download`,
  };
}

//eslint does not like unused parameters, but we're saving language for later
export function getSecurityQuestionsList(endpoint: IEndpointConfig, language: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/getSecurityQuestionGroups/${language}`,
  };
}

export function getCarboniteVersionNumber(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/carboniteVersion`,
  };
}

export function getShoppingCartByOrderCode(endpoint: IEndpointConfig, orderCode: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/cart/${orderCode}`,
  };
}

export function getShoppingCartByEnrollmentGuid(
  endpoint: IEndpointConfig,
  enrollmentGuid: string,
  requestToken: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/planetot/cart/${enrollmentGuid}?token=${requestToken}`,
  };
}

/* eslint-disable */
//eslint does not like unused parameters, but we're saving language for later
export function setSecurityQuestions(
  endpoint: IEndpointConfig,
  securityQuestions: ISecurityQuestions,
  language: string
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/Me/securityQuestions`,
    data: {
      SecurityQuestionOne: securityQuestions.securityQuestionOne,
      SecurityQuestionTwo: securityQuestions.securityQuestionTwo,
      SecurityQuestionThree: securityQuestions.securityQuestionThree,
      SecurityAnswerOne: securityQuestions.securityAnswerOne,
      SecurityAnswerTwo: securityQuestions.securityAnswerTwo,
      SecurityAnswerThree: securityQuestions.securityAnswerThree,
      // Language: language" // Re-enable this when enabling multi-language
      Language: "EN",
    },
  };
}
/* eslint-enable */

export function setDefaultCreditCard(endpoint: IEndpointConfig, creditCardId: string): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/paymentMethods/default/set/${creditCardId}`,
  };
}

export function removeCreditCard(endpoint: IEndpointConfig, creditCardId: string): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/paymentMethods/${creditCardId}/delete`,
  };
}

export function removeAndReplaceCreditCard(
  endpoint: IEndpointConfig,
  creditCardId: string,
  newDefaultCreditCardId: string
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/paymentMethods/${creditCardId}/delete`,
    data: { ReplacementPaymentMethodId: newDefaultCreditCardId, SetAsDefault: true },
  };
}

export function checkExistingEmail(endpoint: IEndpointConfig, emailRequest: ICheckEmailRequest): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/users`,
    data: emailRequest,
  };
}

export function getUserPaymentHistory(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/accounts/personal/paymenthistory`,
  };
}

// export function getUserSecurityPhoneAnonymous(endpoint: IEndpointConfig, personId: number): AxiosRequestConfig {
//     return {
//         method: "get",
//         url: `${hostUrl(endpoint)}/api/cp/accounts/personal/GetSecurityPhone/${personId}`
//     }
// }

export function getUserSecurityPhone(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/SecurityPhone`,
  };
}

export function getSecurityQuestions(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/SecurityQuestions`,
  };
}

export function validateAndUpdatePassword(
  endpoint: IEndpointConfig,
  currentPassword: string,
  newPassword: string
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/personal/UpdatePassword`,
    data: { CurrentPassword: currentPassword, NewPassword: newPassword },
  };
}

export function updateUserPersonalInfo(endpoint: IEndpointConfig, user: IUpdateSubscriberRequest): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/Me/subscriberInfo`,
    data: {
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.email,
      AlternateEmail: user.alternateEmail,
    },
  };
}

export function updateComputerDescription(
  endpoint: IEndpointConfig,
  computerId: number,
  computerDescription: string
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/computers/${computerId}`,
    data: { ComputerDescription: computerDescription },
  };
}

export function requestVerificationCode(
  endpoint: IEndpointConfig,
  personId: number,
  method: string
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/accounts/personal/SendVerificationCode`,
    data: { PersonId: personId, SecurityPhoneVerificationMethod: method },
  };
}

export function sendVerificationCode(
  endpoint: IEndpointConfig,
  personId: number,
  verificationCode: string
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/accounts/personal/ValidateVerificationCode`,
    data: { PersonId: personId, VerificationCode: verificationCode },
  };
}

export function sendVerificationCodeAndTrustClient(
  endpoint: IEndpointConfig,
  verificationCode: string,
  isTokenLongTrusted: boolean,
  source: string
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/accounts/personal/ValidateCodeAndTrustClient`,
    data: { VerificationCode: verificationCode, IsTokenLongTrusted: isTokenLongTrusted, Source: source },
  };
}

export function setSecurityPhone(endpoint: IEndpointConfig, phone: IUserSecurityPhoneRequest): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/Me/securityPhone`,
    data: phone,
  };
}

export function deleteSecurityPhone(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "delete",
    url: `${hostUrl(endpoint)}/api/cp/Me/SecurityPhone`,
  };
}

export function GetNewAccountStatus(endpoint: IEndpointConfig, guid: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/AccountStatus/${guid}`,
  };
}

export function setNewAccountPassword(
  endpoint: IEndpointConfig,
  guid: string,
  newPassword: string
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/Me/SetNewAccountPassword/${guid}`,
    data: { newPassword: newPassword },
  };
}

export function getZuoraCreditCardFormParams(
  endpoint: IEndpointConfig,
  userGuid: string,
  formId: string,
  requestToken: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/form/${userGuid}/${formId}?token=${requestToken}`,
  };
}

export function getZuoraCreditCardFormEmailParams(
  endpoint: IEndpointConfig,
  email: string,
  formId: string,
  requestToken: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/guestForm/${formId}?email=${encodeURIComponent(
      email
    )}&token=${requestToken}`,
  };
}

export function getZuoraCreditCardFormComputerIdParams(
  endpoint: IEndpointConfig,
  computerId: number,
  formId: string,
  requestToken: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/guestForm/${formId}?computerId=${computerId}&token=${requestToken}`,
  };
}

export function getZuoraCreditCardFormLicenseKeyParams(
  endpoint: IEndpointConfig,
  licenseKey: string,
  formId: string,
  requestToken: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/guestForm/${formId}?licenseKey=${licenseKey}&token=${requestToken}`,
  };
}

export function getPhoneCountryCodes(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/geography/phoneCountryCodes`,
  };
}

export function resetPassword(endpoint: IEndpointConfig, personId: number, newPassword: string): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/personal/ResetPassword`,
    data: { PersonId: personId, Password: newPassword },
  };
}

export function getWebrootUserInfo(endpoint: IEndpointConfig, webrootKey: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/salesHeader/${webrootKey}`,
  };
}

export function createAccount(
  endpoint: IEndpointConfig,
  email: string,
  password: string,
  keycode: string,
  ecommKey: string,
  recaptchaToken: string,
  languageCode: string
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/accounts`,
    data: {
      Email: email,
      Password: password,
      KeyCode: keycode,
      OrderHeaderToken: ecommKey,
      RecaptchaResponse: recaptchaToken,
      preferredLanguage: languageCode,
    },
  };
}

export function getInvoice(endpoint: IEndpointConfig, invoiceId: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/invoices/${invoiceId}`,
  };
}

export function getCountries(endpoint: IEndpointConfig) {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/countries`,
  };
}

export function addKeycode(endpoint: IEndpointConfig, keycode: string, orderHeaderToken?: string): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/Me/webroot/Licenses`,
    data: { Keycode: keycode, OrderHeaderToken: orderHeaderToken },
  };
}

export function getSubscriberInfo(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/subscriberInfo`,
  };
}

export function refreshSubscriber(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/Me/subscriberRefresh`,
  };
}

export function updateAutoRenewOption(
  endpoint: IEndpointConfig,
  autoRenewUpdateRequest: IAutoRenewUpdateRequest
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/subscription/autoRenewal`,
    data: autoRenewUpdateRequest,
  };
}

export function setRenewalDiscount(
  endpoint: IEndpointConfig,
  zuoraSubscriptionNumber: string,
  enableAutoRenewDiscount: IAutoRenewDiscountRequest
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/subscriptions/${zuoraSubscriptionNumber}/autorenewaldiscount`,
    data: enableAutoRenewDiscount,
  };
}

export function cancelSubscription(endpoint: IEndpointConfig, subscriptionNumber: string): AxiosRequestConfig {
  return {
    method: "delete",
    url: `${hostUrl(endpoint)}/api/cp/subscription/${subscriptionNumber}`,
  };
}

export function getSafeClientURL(request: IInstallRequest, endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/safe/installer`,
    data: {
      ActivationCode: request.activationCode,
      OperatingSystem: request.operatingSystem,
      DeviceId: request.deviceId,
      OfferCode: request.offerCode,
      InstallType: request.installType,
      KeyCodeForSafeLite: request.keyCodeForSafeLite,
    },
  };
}

export function getSubscriptions(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/personal/subscriptions`,
  };
}

export function getLatestSubscriptions(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/subscriptions`,
  };
}

export function logout(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/auth/logout?noRedirect=true`,
  };
}

export function releaseSubscription(endpoint: IEndpointConfig, deviceId: number): AxiosRequestConfig {
  return {
    method: "delete",
    url: `${hostUrl(endpoint)}/api/cp/safe/computer/${deviceId}`,
  };
}

export function provisionLastPass(endpoint: IEndpointConfig, keyCode: string): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/lastPass`,
    data: { keyCode },
  };
}

export function getLastPassInfo(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/lastPass`,
  };
}

export function getWebrootKeycode(
  endpoint: IEndpointConfig,
  deviceId: number,
  zuoraSubscriptionNumber: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(
      endpoint
    )}/api/accounts/keycode?deviceId=${deviceId}&zuoraSubscriptionNumber=${zuoraSubscriptionNumber}`,
  };
}

export function getOutstandingInvoices(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/accounts/GetOutstandingInvoices`,
  };
}

export function payOutstandingInvoices(
  endpoint: IEndpointConfig,
  payOutstandingInvoicesRequest: IPayOutstandingInvoicesRequest
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/orders/payOutstandingInvoices`,
    data: payOutstandingInvoicesRequest,
  };
}

export function verifyActivationCode(endpoint: IEndpointConfig, activationCode: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/activationCodes/${activationCode}/validate`,
  };
}

export function applyActivationCode(
  endpoint: IEndpointConfig,
  activationCode: string,
  computerId: number
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/activationCodes/${activationCode}/apply?computerId=${computerId}`,
  };
}

export function getWebrootRenewUrl(endpoint: IEndpointConfig, keycode: string): AxiosRequestConfig {
  const portalDestParam = generateInterstitialReturnPortalDestination("?");
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/webroot/Licenses/${keycode}/renewalUrl${portalDestParam}`,
  };
}

export function getAvailableActivationCodes(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/me/activationCodes`,
  };
}

export function optInToAutomaticVideoBackup(endpoint: IEndpointConfig, deviceId: number): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/safe/computer/${deviceId}/avb`,
  };
}

export function getConsumerVatInfoAsync(endpoint: IEndpointConfig, paymentMethodId: string | null): AxiosRequestConfig {
  const paymentMethodIdParameter = paymentMethodId ? `?paymentMethodId=${paymentMethodId}` : "";
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/personal/vatInfo${paymentMethodIdParameter}`,
  };
}

export function setVatInfo(endpoint: IEndpointConfig, vatId: string, countryCode: string): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/accounts/setVatInfo`,
    data: { VatId: vatId, CountryCode: countryCode },
  };
}

export function getPendingVerificationEmail(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/email`,
  };
}

export function cancelPendingEmailValidation(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "delete",
    url: `${hostUrl(endpoint)}/api/cp/Me/email`,
  };
}

export function putClientException(endpoint: IEndpointConfig, ce: IClientException): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/clientExceptions`,
    data: ce,
  };
}

export function addEncryptionKey(
  endpoint: IEndpointConfig,
  computerId: number,
  encryptionKey: string,
  encryptionKeyPassword: string | null
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/computers/${computerId}/encryptionKey`,
    data: { EncryptionKey: encryptionKey, EncryptionPassword: encryptionKeyPassword },
  };
}

export function getEncryptionKeyPasswordHint(endpoint: IEndpointConfig, computerId: number): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/computers/${computerId}/passwordHint`,
  };
}

export function verifyEmail(endpoint: IEndpointConfig, verificationCode: string): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/emailVerification/${verificationCode}`,
  };
}

export function sendNewVerificationEmail(endpoint: IEndpointConfig, verificationCode: string): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/emailVerification/${verificationCode}/sendNew`,
  };
}

export function updateEmail(endpoint: IEndpointConfig, email: string, password: string): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/Me/email`,
    data: { Email: email, Password: password },
  };
}

export function resendVerificationEmail(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/Me/emailverification`,
  };
}

export function getBackupSummary(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/me/backupSummary`,
  };
}

export function getRfaSession(endpoint: IEndpointConfig, computerId: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/computers/${computerId}/rfasession`,
  };
}

export function getSkyWebrootDevices(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/webroot/Licenses/devices`,
  };
}

export function updateWebrootDevice(
  endpoint: IEndpointConfig,
  request: IUpdateWebrootDeviceRequest
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/webroot/device/${request.deviceId}/${request.instanceId}`,
    data: { Nickname: request.deviceNickname, IsVisible: request.isVisible, Keycode: request.keycode },
  };
}

export function removeWebrootDevice(
  endpoint: IEndpointConfig,
  request: IRemoveWebrootDeviceRequest,
  keycode: string
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/me/webroot/license/${keycode}/device/deactivate`,
    data: {
      ...request,
    },
  };
}

export function getAllstateSubscriptions(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/allstate`,
  };
}

export function addAllstateSubscription(endpoint: IEndpointConfig, request: IAllstateRequest): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/allstate`,
    data: {
      ...request,
    },
  };
}

export function updateAllstateSubscriptions(
  endpoint: IEndpointConfig,
  request: IAllstateUpdateRequest
): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/allstate`,
    data: {
      ...request,
    },
  };
}

export function getCssbBackupSets(
  endpoint: IEndpointConfig,
  computerName: string,
  sort: string,
  orderBy: string
): AxiosRequestConfig {
  const optionsParams = `?sort=${sort}&orderBy=${orderBy}`;

  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/computers/${computerName}/BackupSets/${optionsParams}`,
  };
}

export function getCssbBackupRuns(
  endpoint: IEndpointConfig,
  computerName: string,
  backupSetName: string,
  sort: string,
  orderBy: string
): AxiosRequestConfig {
  const optionsParams = `?sort=${sort}&orderBy=${orderBy}`;

  return {
    method: "get",
    url: `${hostUrl(
      endpoint
    )}/api/cp/cssbRfa/computers/${computerName}/backupSets/${backupSetName}/BackupRuns/${optionsParams}`,
  };
}

export function getCssbBackupRunDetails(endpoint: IEndpointConfig, request: ICssbDetailsRequest): AxiosRequestConfig {
  let optionsParams = `?start=${request.pageStart}&numberOfFiles=${request.numberOfFilesPerPage}&sort=${request.sort}&orderBy=${request.orderBy}`;
  const browse = `browse=${!request.isSearch}`;

  if (request.timeStamp) {
    optionsParams = `${optionsParams}&backupRunTime=${request.timeStamp}`;
  }

  if (request.path) {
    const encodedPath = Buffer.from(request.path).toString("base64");
    optionsParams = `${optionsParams}&filePathBase64=${encodedPath}`;
  }

  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/computers/${request.computerName}/backupSets/${
      request.backupSetName
    }/BackupDetails${optionsParams}&${browse}`,
  };
}

export function addCssbDownloadInfo(endpoint: IEndpointConfig, request: ICssbDownloadInfoRequest): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/BackupDownloadInfo`,
    data: request,
  };
}

export function getCssbDownloadUrl(endpoint: IEndpointConfig, request: ICssbDownloadUrlRequest): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/computers/${request.computerName}/backupSets/${
      request.backupSetName
    }/RequestId/${request.requestId}/DownloadUrl`,
    data: request,
  };
}

export function cancelCssbDownload(endpoint: IEndpointConfig, request: ICssbDownloadUrlRequest): AxiosRequestConfig {
  return {
    method: "delete",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/computers/${request.computerName}/backupSets/${
      request.backupSetName
    }/RequestId/${request.requestId}/DownloadUrl`,
    data: request,
  };
}

export function getInProgressCssbDownloadRequests(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/downloadUrls`,
  };
}

export function getWebrootPIIInformation(endpoint: IEndpointConfig, keycode: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/me/webroot/licenses/${keycode}/userInformation`,
  };
}

export function getCssbServers(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/cssbRfa/computers`,
  };
}

export function setLangCookie(endpoint: IEndpointConfig, language: string): AxiosRequestConfig {
  let cookieValue: string;
  switch (language.toLocaleLowerCase()) {
    case "ja":
      cookieValue = "ja-JP";
      break;
    default:
      cookieValue = "en";
      break;
  }
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/auth/lang?lang=${cookieValue}`,
  };
}

export function getLangCookie(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/auth/lang`,
  };
}

export function getBuyFlowSelectPlans(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/buyflowSelectPlans`,
  };
}

export function submitCourierRecovery(endpoint: IEndpointConfig, request: ICourierRecoveryRequest): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/courierrecovery`,
    data: request,
  };
}

export function getPendingRequestToManageAsync(endpoint: IEndpointConfig): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/Me/reseller/manage`,
  };
}

export function manageCustomerByResellerAsync(
  endpoint: IEndpointConfig,
  request: IManageCustomerByResellerRequest
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/Me/reseller/manage`,
    data: request,
  };
}

export function purchaseShoppingCart(endpoint: IEndpointConfig, request: IShoppingCart): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/purchase/${request.brand}`,
    data: request,
  };
}

export function getPurchaseKeycodes(
  endpoint: IEndpointConfig,
  invoiceNumber: string,
  orderCode: string
): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/invoices/${invoiceNumber}/keycodes?orderCode=${orderCode}`,
  };
}

export function getShoppingCart(endpoint: IEndpointConfig, brand: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/${brand}/cart`,
  };
}

export function getShoppingCartForUser(endpoint: IEndpointConfig, userGuid: string, brand: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/users/${userGuid}/${brand}/cart`,
  };
}

export function saveShoppingCart(endpoint: IEndpointConfig, request: IShoppingCart): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/purchase/${request.brand}/preview`,
    data: request,
  };
}

export function getProductCatalog(endpoint: IEndpointConfig, type: string, campaignId?: number) {
  const campaignIdUrlComponent = typeof campaignId != "undefined" ? `${campaignId}` : "";
  const urlComponent = type + "/" + campaignIdUrlComponent;
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/products/${urlComponent}`,
  };
}

export function setLanguagePreference(endpoint: IEndpointConfig, request: ISetLanguagePreferenceRequest) {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/Me/language`,
    data: request,
  };
}

export function getSubscriptionForUnauthorizedFlow(endpoint: IEndpointConfig, deviceId: number) {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/subscription/${deviceId}`,
  };
}

export function silentLogout() {
  return {
    method: "get",
    url: `${URLS.UP_ENDPOINT.protocol}://${URLS.UP_ENDPOINT.hostname}/api/cp/auth/logout`,
  };
}

export function checkHasRecentPurchase(endpoint: IEndpointConfig, computerId: number): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/recent/${computerId}`,
  };
}

export function getProspectId(endpoint: IEndpointConfig, prospectGuid: string): AxiosRequestConfig {
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/accounts/${prospectGuid}/ProspectId`,
  };
}

export function payPalApprove(endpoint: IEndpointConfig, tokenId: string): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/paypal/billingAgreement?tokenId=${tokenId}`,
  };
}

export function createPayPalAgreementToken(
  endpoint: IEndpointConfig,
  request: IPayPalAgreementTokenRequest
): AxiosRequestConfig {
  return {
    method: "post",
    url: `${hostUrl(endpoint)}/api/cp/paypal/AgreementToken`,
    data: request,
  };
}

export function getActivationCode(
  endpoint: IEndpointConfig,
  request: IActivationCodePurchaseRequest
): AxiosRequestConfig {
  const subscriptionNumber =
    request.subscriptionNumber && typeof request.subscriptionNumber != "undefined"
      ? `subscriptionNumber=${request.subscriptionNumber}`
      : "";
  const userGuid = request.userGuid && typeof request.userGuid != "undefined" ? `&userGuid=${request.userGuid}` : "";
  const email = request.email && typeof request.email != "undefined" ? `&email=${request.email}` : "";
  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/purchase/activationCode?${subscriptionNumber}${userGuid}${email}`,
    data: request,
  };
}

export function setAutoRenew(endpoint: IEndpointConfig, request: IAutoRenewRequest): AxiosRequestConfig {
  return {
    method: "put",
    url: `${hostUrl(endpoint)}/api/cp/subscription/autorenewal`,
    data: request,
  };
}

export function getLicenseKeyInfo(endpoint: IEndpointConfig, licenseKey: string, upgradeSku = ""): AxiosRequestConfig {
  let upgradeParam = "";
  if (upgradeSku) {
    upgradeParam = `?sku=${upgradeSku}`;
  }

  return {
    method: "get",
    url: `${hostUrl(endpoint)}/api/cp/subscription/licenseKeys/${licenseKey}/purchaseInfo${upgradeParam}`,
  };
}
