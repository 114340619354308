<template>
  <ModalWrapperComponent dialog-name="RemoveDevice" :show-dialog="showDialog" @close-modal="closeModal">
    <template #header>
      {{ tn("removeDevice") }}
    </template>
    <template v-if="showInitialState" #content>
      <i18n-t keypath="DeviceComponent.removeDeviceLine1" tag="p" class="text-lg" scope="global">
        <template #nickname>
          <strong>{{ props.device?.name }}</strong>
        </template>
      </i18n-t>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('removeDeviceLine2')"></p>
    </template>
    <template v-else #content>
      <i18n-t keypath="DeviceComponent.removeDeviceReceivedLine1" tag="p" scope="global">
        <template #nickname>
          {{ props.device?.name }}
        </template>
      </i18n-t>
      <p>{{ tn("removeDeviceReceivedLine2") }}</p>
    </template>
    <template v-if="showInitialState" #footer>
      <button id="btnCancelRemoveDevice" type="button" class="btn-link mr" @click="closeModal()">
        {{ t("Common.cancel") }}
      </button>
      <button v-if="showInitialState" id="removeDeviceBtn" class="btn-danger" @click="removeDevice()">
        {{ tn("removeDevice") }}
      </button>
    </template>
    <template v-else #footer>
      <button id="btnCloseRemoveDevice" type="button" class="btn-primary" @click="closeModal()">
        {{ t("Common.close") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { unifiedApi } from "@/common";
import { ISkyDevice } from "@/components/Home";
import { useSecurityStore } from "@/stores/security";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";

const componentName = "RemoveDevice";
const baseComponentName = "DeviceComponent";
const showInitialState = ref(true);

const props = defineProps({
  device: {
    type: Object as PropType<ISkyDevice>,
    required: true,
  },
  sub: {
    type: Object as PropType<ISubscription>,
    default: null,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal", "device-deactivated"]);
const securityStore = useSecurityStore();

logEvent("created", componentName, props.device);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function closeModal() {
  logEvent("Close remove device", componentName);
  emits("close-modal");
  showInitialState.value = true;
}

async function removeDevice() {
  try {
    const request = {
      deviceId: props.device?.id,
      instanceId: props.device?.instanceId,
      hostName: props.device?.name,
      keycode: props.device?.keycode,
    };
    await unifiedApi.removeWebrootDevice(request, props.device?.keycode);
    securityStore.forceRefresh();
    showInitialState.value = false;
    emits("device-deactivated");
  } catch (err) {
    logException(err as Error);
  }
}
</script>
