<template>
  <section>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p v-html="tn('nextStepsWebrootAllStepsMyAccount')"></p>
    <ol class="mb">
      <li class="mb">
        {{ tn("nextStepsWebrootProvisionalStep1") }}
        <div class="mt-half">
          <button id="btnCreateAccountFromProvisional" type="button" class="btn-primary" @click="goToSetPassword()">
            {{ t("OrderConfirmation.btnFinishMyAccountSetup") }}
          </button>
        </div>
      </li>
      <li>
        {{ tn("nextStepsWebrootProvisionalStep2") }}
      </li>
    </ol>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";

const componentName = "WebrootNextStepsProvisional";
const baseComponentName = "NextSteps";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToSetPassword() {
  router.push("/setNewPassword");
}
</script>
